import React from "react";
import {
  Container,
  Navbar,
  NavbarBrand,
  Input,
  Card,
  Row,
  Col,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import Slider from "react-slick";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import { FaMoneyBillWaveAlt, FaSearch, FaRegComment } from "react-icons/fa";
import { FiArrowUpRight } from "react-icons/fi";
import { GrFormClose } from "react-icons/gr";
import { TbMailFast, TbClipboardCheck, TbFilePlus } from "react-icons/tb";
import { BiCommentError, BiCopyAlt } from "react-icons/bi";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosClose,
  IoMdHeartEmpty,
} from "react-icons/io";
import { SlArrowRight } from "react-icons/sl";
import ProfileImg from "../img/profile.png";
import Fridayisimg from "../img/fridayis-img.jpg";
import FridayisLogoMobile from "../img/logo_new.png";

import ReactHtmlParser from "react-html-parser";
import { MdTrain, MdKeyboardArrowUp } from "react-icons/md";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Logo from "../img/logo_new.png";
import NoContent from "../img/no-content.png";
import EarningMockup from "../img/earning-mockup.png";
import ComingSoon from "../img/Comingsoon.png";
import Modal from "react-modal";

// import Amsterdam from "../img/shortcut/Amsterdam.png";
// import Athens from "../img/shortcut/Athens.png";
// import Auckland from "../img/shortcut/Auckland.png";
// import Bangkok from "../img/shortcut/Bangkok.png";
// import Berlin from "../img/shortcut/Berlin.png";
// import BuenosAires from "../img/shortcut/BuenosAires.png";
// import Cairo from "../img/shortcut/Cairo.png";
// import CapeTown from "../img/shortcut/CapeTown.png";
// import Dubai from "../img/shortcut/Dubai.png";
// import Hanoi from "../img/shortcut/Hanoi.png";
// import Havana from "../img/shortcut/Havana.png";
// import Istanbul from "../img/shortcut/Istanbul.png";
// import Lisbon from "../img/shortcut/Lisbon.png";
// import London from "../img/shortcut/London.png";
// import Marrakech from "../img/shortcut/Marrakech.png";
// import Mumbai from "../img/shortcut/Mumbai.png";
// import NewYork from "../img/shortcut/NewYork.png";
// import Paris from "../img/shortcut/Paris.png";
// import Prague from "../img/shortcut/Prague.png";
// import RioDeJaneiro from "../img/shortcut/RioDeJaneiro.png";
// import Rome from "../img/shortcut/Rome.png";
// import SanFrancisco from "../img/shortcut/SanFrancisco.png";
// import Seoul from "../img/shortcut/Seoul.png";
// import StPetersburg from "../img/shortcut/St.Petersburg.png";
// import Stockholm from "../img/shortcut/Stockholm.png";
// import Sydney from "../img/shortcut/Sydney.png";
// import Tokyo from "../img/shortcut/Tokyo.png";
// import Vancouver from "../img/shortcut/Vancouver.png";
// import Vienna from "../img/shortcut/Vienna.png";

import IconSearch from "../img/icon/icon-search.png";

import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import Iframe from "react-iframe";

import CreatePage from "./Create";
import { RiTwitterXFill } from "react-icons/ri";
import { AiOutlineWhatsApp, AiFillFacebook } from "react-icons/ai";
import { Helmet } from "react-helmet";
import HeadTags from "../components/HeadTags";
import AdSenseComponent from "../components/AdSenseComponent";
import FullDate from "../components/FullDate";

import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

import Header from "../components/Header";

import IconPaper from "../img/icon/Paper.png";
import IconPaperPlus from "../img/icon/Paper-Plus.png";

class Post extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      searchShortcut: "",
      data: [],
      dayList: [],
      dayListAll: [],
      dataImages: [],
      dataImagesList: [],
      dataImagesDay: [],
      day: 0,
      c: 0,
      // printTimeline: []
      open: false,
      sourceImg: [],
      friendInvited: "",
      userId: localStorage.getItem("userId"),
      startDate: "",
      dataClone: [],
      dataCloneList: [],
      visible: false,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      passportExpire: "",

      showModal: false,
      showModalShare: false,
      shareUrl: "https://www.fridayis.com",
      titleNav: "",
      ctrip: "",
      // apiUrl: "http://localhost:3001",
      apiUrl: "https://fridayis-api-ab9e07954977.herokuapp.com",
      ss: false,
      urlHelmet: "",
      fullDate: "",
    };
    this.logout = this.logout.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleMobile = this.toggleMobile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.clickSlideOut = this.clickSlideOut.bind(this);
    this.toggleTimeline = this.toggleTimeline.bind(this);
    this.toggleTimelineUp = this.toggleTimelineUp.bind(this);
    this.clickLinkFlight = this.clickLinkFlight.bind(this);
    this.clickLinkHotel = this.clickLinkHotel.bind(this);
    this.clickLinkTrain = this.clickLinkTrain.bind(this);
    this.clickLinkCar = this.clickLinkCar.bind(this);
    this.clickLinkMotor = this.clickLinkMotor.bind(this);
    this.searchShortcut = this.searchShortcut.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.toggleInvite = this.toggleInvite.bind(this);
    this.handleChangeFriendInvited = this.handleChangeFriendInvited.bind(this);
    this.toggleProfile = this.toggleProfile.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.toggleVisible = this.toggleVisible.bind(this);
    this.toggleTripdescriptionHide = this.toggleTripdescriptionHide.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openModalShare = this.openModalShare.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeModalShare = this.closeModalShare.bind(this);
    this.isValidEmail = this.isValidEmail.bind(this);
    this.getUrlTimeline = this.getUrlTimeline.bind(this);
    this.joinTrip = this.joinTrip.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.closeMobile = this.closeMobile.bind(this);
    this.showIframe = this.showIframe.bind(this);
    this.getNextFriday = this.getNextFriday.bind(this);
    this.openProfile = this.openProfile.bind(this);
    this.getNextFridayFormatted = this.getNextFridayFormatted.bind(this);
    this.openTimeline = this.openTimeline.bind(this);
    this.openPostPage = this.openPostPage.bind(this);
  }

  openPostPage() {
    window.location.href = "/post?profileId=" + localStorage.getItem("userId");
  }

  showIframe() {
    this.setState({ ss: true });
  }

  componentDidMount() {
    if (!localStorage.getItem("userId")) {
      window.location.href = "/";
      //     /login
    } else {
      const searchHelmet = window.location.search;
      const paramsHelmet = new URLSearchParams(searchHelmet);
      let idHelmet = paramsHelmet.get("id");
      if (idHelmet) {
        this.setState({ urlHelmet: idHelmet });
      }

      let dataCheck = {
        userId: this.state.userId,
      };

      fetch(this.state.apiUrl + "/api/user/check", {
        body: JSON.stringify(dataCheck),
        method: "POST",
        headers: { "Content-type": "application/json;charset=UTF-8" },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message === "Invalid username or password.") {
            this.logout();
            // window.location.href = '/login';
          } else {
            this.getAllData();

            this.setState({ passportExpire: "" }, () => {
              if (this.state.userInfo) {
                if (this.state.userInfo.passportExpire) {
                  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                  const firstDate = new Date();
                  const secondDate = new Date(
                    this.state.userInfo.passportExpire
                  );
                  const diffDays = Math.round(
                    Math.abs((firstDate - secondDate) / oneDay)
                  );
                  this.setState({ passportExpire: diffDays });
                }
              }
            });
          }
        });
    }

    // window.setTimeout(function(){
    //     var element =  document.getElementById("makeTheFirstMove");
    //     if(element){
    //         if(element.classList.contains("fade-out") === false){
    //             element.classList.add("fade-out");
    //         }
    //     }
    // },8000);

    // window.setTimeout(function(){
    //     var element =  document.getElementById("makeTheFirstMove");
    //     if(element){
    //         if(element.classList.contains("d-none") === false){
    //             element.classList.add("d-none");
    //         }
    //     }
    // },9500);

    window.addEventListener("scroll", this.toggleVisible);
    // this.getAllData();
  }

  getAllData() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let id = params.get("id");
    let ctrip = params.get("ctrip");
    let profileId = params.get("profileId");
    this.setState({ ctrip: ctrip });

    if (sessionStorage.getItem("id")) {
      id = sessionStorage.getItem("id");
    }

    if (ctrip === "all") {
      this.setState({ titleNav: "Itinerary" });
      let url =
        this.state.apiUrl +
        "/api/trip/all/invited" +
        "?userId=" +
        this.state.userId +
        "&joined=1";
      // let url = 'http://localhost:3001/api/trip/all';
      if (id) {
        url =
          this.state.apiUrl +
          "/api/trip/all/invited?id=" +
          id +
          "&userId=" +
          this.state.userId +
          "&joined=1";
      }
      if (this.state.search) {
        url =
          this.state.apiUrl +
          "/api/trip/all/invited?destination=" +
          this.state.search +
          "&userId=" +
          this.state.userId +
          "&joined=1";
      }
      if (this.state.searchShortcut) {
        url =
          this.state.apiUrl +
          "/api/trip/all/invited?destination=" +
          this.state.searchShortcut +
          "&userId=" +
          this.state.userId +
          "&joined=1";
      }
      fetch(url)
        .then((response) => response.json())
        .then((data) =>
          this.setState({ data: data.all }, () => {
            // let j = 0;
            let flightFlag = 0;
            let carFlag = 0;
            let trainFlag = 0;
            let hotelFlag = 0;
            let motorFlag = 0;
            let newData = [];
            let arrayLists = [];
            for (let i = 0; i < this.state.data.length; i++) {
              if (i > 0) {
                // console.log(':r other');
                if (
                  this.state.data[i].TripID.toString() !==
                  this.state.data[i - 1].TripID.toString()
                ) {
                  // not same id
                  // console.log('::r other not same id');

                  // console.log("Important:::>"+newData);
                  // console.log("Important:::>"+this.state.data[i - 1].TripID);
                  // console.log("Important:::>"+this.state.data[i].TripID);
                  // console.log("Important:::>"+this.state.data[i].DayNumber);

                  if (newData.length > 0) {
                    if (flightFlag === 0) {
                      newData[parseInt(newData.length) - 1][2] = "";
                      newData[parseInt(newData.length) - 1][10] = "";
                    }
                    if (hotelFlag === 0) {
                      newData[parseInt(newData.length) - 1][3] = "";
                      newData[parseInt(newData.length) - 1][11] = "";
                    }
                    if (carFlag === 0) {
                      newData[parseInt(newData.length) - 1][4] = "";
                      newData[parseInt(newData.length) - 1][12] = "";
                    }
                    if (trainFlag === 0) {
                      newData[parseInt(newData.length) - 1][5] = "";
                      newData[parseInt(newData.length) - 1][13] = "";
                    }
                    if (motorFlag === 0) {
                      newData[parseInt(newData.length) - 1][6] = "";
                      newData[parseInt(newData.length) - 1][14] = "";
                    }
                  }

                  let arrayList = [];
                  arrayList.id = this.state.data[i - 1].TripID;
                  // arrayList.data = this.state.dayList;
                  arrayList.data = newData;

                  // let arrayLists = [...this.state.dayListAll];
                  arrayLists.push(arrayList);
                  this.setState({ dayListAll: arrayLists }, () => {
                    this.setState({ dayList: [] }, () => {
                      // console.log(this.state.dayListAll);
                    });
                  });

                  // if(!this.state.data[i].DayNumber){
                  //     // console.log('Nulll');
                  //     let arrayList = [];
                  //     arrayList.id = this.state.data[i].TripID;
                  //     // arrayList.data = this.state.dayList;
                  //     arrayList.data = [];
                  //
                  //     // let arrayLists = [...this.state.dayListAll];
                  //     arrayLists.push(arrayList);
                  //     this.setState({dayListAll: arrayLists}, () => {
                  //         this.setState({dayList: []}, () => {
                  //             console.log(this.state.dayListAll);
                  //             i++
                  //         });
                  //
                  //     });
                  //
                  // }

                  // reset round
                  flightFlag = 0;
                  carFlag = 0;
                  trainFlag = 0;
                  hotelFlag = 0;
                  motorFlag = 0;
                  // newData = [];
                  newData = [...this.state.dayList];
                  newData[parseInt(this.state.data[i].DayNumber) - 1] = [];
                  newData[parseInt(this.state.data[i].DayNumber) - 1][0] = ""; //contentTitle
                  newData[parseInt(this.state.data[i].DayNumber) - 1][1] =
                    this.state.data[i].Description;
                  if (this.state.data[i].PlanLinkTypeName === "flight") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                      this.state.data[i].Url;
                    newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                      this.state.data[i].Price;
                    flightFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                      this.state.data[i].Url;
                    newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                      this.state.data[i].Price;
                    hotelFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "car") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                      this.state.data[i].Url;
                    newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                      this.state.data[i].Price;
                    carFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "train") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                      this.state.data[i].Url;
                    newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                      this.state.data[i].Price;
                    trainFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                      this.state.data[i].Url;
                    newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                      this.state.data[i].Price;
                    motorFlag = 1;
                  }
                  newData[parseInt(this.state.data[i].DayNumber) - 1][7] = [];
                  newData[parseInt(this.state.data[i].DayNumber) - 1][8] = [];
                  newData[parseInt(this.state.data[i].DayNumber) - 1][9] =
                    this.state.data[i].ContentDestination; //contentDestination
                  // newData[parseInt(this.state.data[i].DayNumber) - 1][7] = this.state.data[i].ImageURL;
                  // newData[parseInt(this.state.data[i].DayNumber) - 1][8] = this.state.data[i].ImageURL2;

                  if (i + 1 === parseInt(this.state.data.length)) {
                    // console.log(':::r other not same id last r');
                    if (flightFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                        "";
                    }
                    if (hotelFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                        "";
                    }
                    if (carFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                        "";
                    }
                    if (trainFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                        "";
                    }
                    if (motorFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                        "";
                    }

                    let arrayList = [];
                    arrayList.id = this.state.data[i].TripID;
                    // arrayList.data = this.state.dayList;
                    arrayList.data = newData;

                    // let arrayLists = [...this.state.dayListAll];
                    arrayLists.push(arrayList);
                    this.setState({ dayListAll: arrayLists }, () => {
                      this.setState({ dayList: [] }, () => {
                        // console.log(this.state.dayListAll);
                      });
                    });
                  }
                } else {
                  //same id
                  // console.log('::r other same id');

                  if (
                    this.state.data[i].DayNumber !==
                    this.state.data[i - 1].DayNumber
                  ) {
                    // console.log(':::r other same id not same day');
                    // not same day
                    // j++;
                    if (flightFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 2][2] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 2][10] =
                        "";
                    }
                    if (hotelFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 2][3] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 2][11] =
                        "";
                    }
                    if (carFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 2][4] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 2][12] =
                        "";
                    }
                    if (trainFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 2][5] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 2][13] =
                        "";
                    }
                    if (motorFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 2][6] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 2][14] =
                        "";
                    }

                    newData[parseInt(this.state.data[i].DayNumber) - 1] = [];
                    newData[parseInt(this.state.data[i].DayNumber) - 1][0] = ""; //contentTitle
                    newData[parseInt(this.state.data[i].DayNumber) - 1][1] =
                      this.state.data[i].Description;
                    if (this.state.data[i].PlanLinkTypeName === "flight") {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                        this.state.data[i].Price;
                      flightFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "hotel"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                        this.state.data[i].Price;
                      hotelFlag = 1;
                    } else if (this.state.data[i].PlanLinkTypeName === "car") {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                        this.state.data[i].Price;
                      carFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "train"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                        this.state.data[i].Price;
                      trainFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "motor"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                        this.state.data[i].Price;
                      motorFlag = 1;
                    }
                    newData[parseInt(this.state.data[i].DayNumber) - 1][7] = [];
                    newData[parseInt(this.state.data[i].DayNumber) - 1][8] = [];
                    newData[parseInt(this.state.data[i].DayNumber) - 1][9] =
                      this.state.data[i].ContentDestination; //contentDestination
                    // newData[parseInt(this.state.data[i].DayNumber) - 1][7] = this.state.data[i].ImageURL;
                    // newData[parseInt(this.state.data[i].DayNumber) - 1][8] = this.state.data[i].ImageURL2;

                    // console.log("r other/id/not day:" + this.state.dayList);

                    if (i + 1 === parseInt(this.state.data.length)) {
                      // console.log('::::r other same id not same day last r');
                      if (flightFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][10] = "";
                      }
                      if (hotelFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][11] = "";
                      }
                      if (carFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][12] = "";
                      }
                      if (trainFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][13] = "";
                      }
                      if (motorFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][14] = "";
                      }

                      let arrayList = [];
                      arrayList.id = this.state.data[i].TripID;
                      // arrayList.data = this.state.dayList;
                      arrayList.data = newData;

                      // let arrayLists = [...this.state.dayListAll];
                      arrayLists.push(arrayList);
                      this.setState({ dayListAll: arrayLists }, () => {
                        this.setState({ dayList: [] }, () => {
                          // console.log(this.state.dayListAll);
                        });
                      });

                      // this.setState({ dayList: newData },()=>{
                      //     let arrayList = [];
                      //     arrayList.id = this.state.data[i].TripID;
                      //     arrayList.data = this.state.dayList;
                      //
                      //     // let arrayLists = [...this.state.dayListAll];
                      //     let arrayLists = [];
                      //     arrayLists.push(arrayList);
                      //     this.setState({ dayListAll: arrayLists },()=>{
                      //         console.log(this.state.dayListAll);
                      //     });
                      // });
                    }
                  } else {
                    //same day
                    // console.log(':::r other same id same day');
                    if (this.state.data[i].PlanLinkTypeName === "flight") {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                        this.state.data[i].Price;
                      flightFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "hotel"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                        this.state.data[i].Price;
                      hotelFlag = 1;
                    } else if (this.state.data[i].PlanLinkTypeName === "car") {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                        this.state.data[i].Price;
                      carFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "train"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                        this.state.data[i].Price;
                      trainFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "motor"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                        this.state.data[i].Price;
                      motorFlag = 1;
                    }

                    if (i + 1 === parseInt(this.state.data.length)) {
                      // console.log('::::r other not same id same day last r');
                      if (flightFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][10] = "";
                      }
                      if (hotelFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][11] = "";
                      }
                      if (carFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][12] = "";
                      }
                      if (trainFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][13] = "";
                      }
                      if (motorFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][14] = "";
                      }

                      let arrayList = [];
                      arrayList.id = this.state.data[i].TripID;
                      // arrayList.data = this.state.dayList;
                      arrayList.data = newData;

                      // let arrayLists = [...this.state.dayListAll];
                      arrayLists.push(arrayList);
                      this.setState({ dayListAll: arrayLists }, () => {
                        this.setState({ dayList: [] }, () => {
                          // console.log(this.state.dayListAll);
                        });
                      });
                    }
                  }
                }
              } else {
                // console.log(':r1');
                // if(this.state.data[i].DayNumber){
                newData = [...newData];
                newData[parseInt(this.state.data[i].DayNumber) - 1] = [];
                newData[parseInt(this.state.data[i].DayNumber) - 1][0] = ""; //contentTitle
                newData[parseInt(this.state.data[i].DayNumber) - 1][1] =
                  this.state.data[i].Description;
                if (this.state.data[i].PlanLinkTypeName === "flight") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                    this.state.data[i].Url;
                  newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                    this.state.data[i].Price;
                  flightFlag = 1;
                } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                    this.state.data[i].Url;
                  newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                    this.state.data[i].Price;
                  hotelFlag = 1;
                } else if (this.state.data[i].PlanLinkTypeName === "car") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                    this.state.data[i].Url;
                  newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                    this.state.data[i].Price;
                  carFlag = 1;
                } else if (this.state.data[i].PlanLinkTypeName === "train") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                    this.state.data[i].Url;
                  newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                    this.state.data[i].Price;
                  trainFlag = 1;
                } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                    this.state.data[i].Url;
                  newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                    this.state.data[i].Price;
                  motorFlag = 1;
                }
                // if (this.state.data[i].PlanLinkTypeName === "flight") {
                //     newData[parseInt(this.state.data[i].DayNumber) - 1][2] = this.state.data[i].Url;
                //     flightFlag = 1;
                // } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                //     newData[parseInt(this.state.data[i].DayNumber) - 1][3] = this.state.data[i].Url;
                //     hotelFlag = 1;
                // } else if (this.state.data[i].PlanLinkTypeName === "car") {
                //     newData[parseInt(this.state.data[i].DayNumber) - 1][4] = this.state.data[i].Url;
                //     carFlag = 1;
                // } else if (this.state.data[i].PlanLinkTypeName === "train") {
                //     newData[parseInt(this.state.data[i].DayNumber) - 1][5] = this.state.data[i].Url;
                //     trainFlag = 1;
                // } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                //     newData[parseInt(this.state.data[i].DayNumber) - 1][6] = this.state.data[i].Url;
                //     motorFlag = 1;
                // }

                newData[parseInt(this.state.data[i].DayNumber) - 1][7] = [];
                newData[parseInt(this.state.data[i].DayNumber) - 1][8] = [];
                newData[parseInt(this.state.data[i].DayNumber) - 1][9] =
                  this.state.data[i].ContentDestination; //contentDestination
                // newData[parseInt(this.state.data[i].DayNumber) - 1][7] = this.state.data[i].ImageURL;
                // newData[parseInt(this.state.data[i].DayNumber) - 1][8] = this.state.data[i].ImageURL2;

                // console.log("r1:" + this.state.dayList);

                if (i + 1 === parseInt(this.state.data.length)) {
                  // console.log('::r1 last round');
                  if (flightFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][2] = "";
                    newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                      "";
                  }
                  if (hotelFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][3] = "";
                    newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                      "";
                  }
                  if (carFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][4] = "";
                    newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                      "";
                  }
                  if (trainFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][5] = "";
                    newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                      "";
                  }
                  if (motorFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][6] = "";
                    newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                      "";
                  }

                  let arrayList = [];
                  arrayList.id = this.state.data[i].TripID;
                  // arrayList.data = this.state.dayList;
                  arrayList.data = newData;

                  // let arrayLists = [...this.state.dayListAll];
                  arrayLists.push(arrayList);
                  this.setState({ dayListAll: arrayLists }, () => {
                    this.setState({ dayList: [] }, () => {
                      // console.log(this.state.dayListAll);
                    });
                  });
                }
              }
            }

            this.setState({ dataImages: data.tripImage }, () => {
              let image = [];
              let imageSrc = [];
              let imageList = [];
              let imageLists = [];
              // let k = 0
              for (let j = 0; j < this.state.dataImages.length; j++) {
                if (j > 0) {
                  if (
                    this.state.dataImages[j].TripID.toString() !==
                    this.state.dataImages[j - 1].TripID.toString()
                  ) {
                    imageList.id = this.state.dataImages[j - 1].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                      imageList.startDate = "";
                      imageList.startDateDate = "";
                      // this.state.data[k].TripImageURL = image;
                    } else {
                      imageList.url = null;
                      imageList.src = null;
                      imageList.startDate = "";
                      imageList.startDateDate = "";
                      // this.state.data[k].TripImageURL = null;
                    }
                    // k++;

                    imageLists.push(imageList);

                    image = [];
                    imageSrc = [];
                    imageList = [];

                    image.push(this.state.dataImages[j].ImageURL);
                    imageSrc.push({ src: this.state.dataImages[j].ImageURL });

                    if (j + 1 === parseInt(this.state.dataImages.length)) {
                      imageList.id = this.state.dataImages[j].TripID;
                      if (image[0]) {
                        imageList.url = image;
                        imageList.src = imageSrc;
                        imageList.startDate = "";
                        imageList.startDateDate = "";
                        // this.state.data[k].TripImageURL = image;
                      } else {
                        imageList.url = null;
                        imageList.src = null;
                        imageList.startDate = "";
                        imageList.startDateDate = "";
                        // this.state.data[k].TripImageURL = null;
                      }
                      // k++;

                      imageLists.push(imageList);
                    }
                  } else {
                    image = [...image];
                    imageSrc = [...imageSrc];
                    image.push(this.state.dataImages[j].ImageURL);
                    imageSrc.push({ src: this.state.dataImages[j].ImageURL });

                    if (j + 1 === parseInt(this.state.dataImages.length)) {
                      imageList.id = this.state.dataImages[j].TripID;
                      if (image[0]) {
                        imageList.url = image;
                        imageList.src = imageSrc;
                        imageList.startDate = "";
                        imageList.startDateDate = "";
                        // this.state.data[k].TripImageURL = image;
                      } else {
                        imageList.url = null;
                        imageList.src = null;
                        imageList.startDate = "";
                        imageList.startDateDate = "";
                        // this.state.data[k].TripImageURL = null;
                      }
                      // k++;

                      imageLists.push(imageList);
                    }
                  }
                } else {
                  image.push(this.state.dataImages[j].ImageURL);
                  imageSrc.push({ src: this.state.dataImages[j].ImageURL });

                  if (j + 1 === parseInt(this.state.dataImages.length)) {
                    imageList.id = this.state.dataImages[j].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                      imageList.startDate = "";
                      imageList.startDateDate = "";
                      // this.state.data[k].TripImageURL = image;
                    } else {
                      imageList.url = null;
                      imageList.src = null;
                      imageList.startDate = "";
                      imageList.startDateDate = "";
                      // this.state.data[k].TripImageURL = null;
                    }
                    // k++;

                    imageLists.push(imageList);
                  }
                }
              }

              this.setState({ dataImagesList: imageLists }, () => {
                image = [];
                imageSrc = [];

                let url =
                  this.state.apiUrl +
                  "/api/clone/all/invited" +
                  "?userId=" +
                  this.state.userId;
                // let url = 'http://localhost:3001/api/clone/all?userId='+this.state.userId;
                if (id) {
                  url =
                    this.state.apiUrl +
                    "/api/clone/all/invited?id=" +
                    id +
                    "&userId=" +
                    this.state.userId;
                }
                if (this.state.search) {
                  url =
                    this.state.apiUrl +
                    "/api/clone/all/invited?destination=" +
                    this.state.search +
                    "&userId=" +
                    this.state.userId;
                  // url = 'http://localhost:3001/api/clone/all?destination='+this.state.search+'&userId='+this.state.userId;
                }
                if (this.state.searchShortcut) {
                  url =
                    this.state.apiUrl +
                    "/api/clone/all/invited?destination=" +
                    this.state.searchShortcut +
                    "&userId=" +
                    this.state.userId;
                  // url = 'http://localhost:3001/api/clone/all?destination='+this.state.searchShortcut+'&userId='+this.state.userId;
                }
                fetch(url)
                  .then((response) => response.json())
                  .then((dataClone) =>
                    this.setState({ dataClone: dataClone }, () => {
                      for (let m = 0; m < this.state.dataClone.length; m++) {
                        let ind = this.state.dataImagesList.findIndex(
                          (obj) => obj.id === this.state.dataClone[m].TripID
                        );
                        let indDay = this.state.dayListAll.findIndex(
                          (obj) => obj.id === this.state.dataClone[m].TripID
                        );

                        let dt = this.state.dataClone[m].StartDate;
                        let d = new Date(dt);
                        let mo = d.getMonth() + 1;
                        let month = "";
                        if (mo < 10) {
                          month = "0" + mo.toString();
                        } else {
                          month = mo.toString();
                        }

                        let dd = d.getDate();
                        let ddF = "";
                        if (dd < 10) {
                          ddF = "0" + dd.toString();
                        } else {
                          ddF = dd.toString();
                        }

                        let dateSave =
                          d.getFullYear() + "-" + month + "-" + ddF;
                        // this.state.dataImagesList[ind].startDate = date;

                        let items = [...this.state.dataImagesList];
                        // console.log(items[ind].startDate);
                        // console.log(items[ind]);

                        if (items[ind]) {
                          items[ind].startDate = dateSave;
                          items[ind].joinUser =
                            this.state.dataClone[m].joinUser;
                          // console.log(items[ind].startDate);
                          // console.log("-----------");

                          let dateArr = [];

                          for (
                            let n = 0;
                            n < this.state.dayListAll[indDay].data.length;
                            n++
                          ) {
                            let calDate = new Date(
                              new Date(d).setDate(d.getDate() + n)
                            );
                            dateArr.push({
                              date: calDate.getDate(),
                              month: calDate.getMonth(),
                              year: calDate.getFullYear(),
                            });
                          }

                          // this.state.dataImagesList[ind].startDateDate = dateArr;

                          items[ind].startDateDate = dateArr;
                        } else {
                          items[ind] = [];
                          items[ind].startDate = "";
                          items[ind].joinUser = "";
                          items[ind].startDateDate = [];
                        }

                        this.setState({ dataImagesList: items });
                      }

                      this.setState({
                        dataCloneList: this.state.dataImagesList,
                      });
                    })
                  );
              });
            });

            this.setState({ dataImagesDay: data.contentImage }, () => {
              let image = [];
              let imageSrc = [];
              let imageList = [];
              // let imageLists = []
              // let k = 0
              let items = [...this.state.dayListAll];
              for (let p = 0; p < this.state.dataImagesDay.length; p++) {
                let indDay = this.state.dayListAll.findIndex(
                  (obj) => obj.id === this.state.dataImagesDay[p].TripID
                );

                if (p > 0) {
                  if (
                    this.state.dataImagesDay[p].TripID.toString() !==
                    this.state.dataImagesDay[p - 1].TripID.toString()
                  ) {
                    // imageList.id = this.state.dataImagesDay[p-1].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                      // this.state.data[k].TripImageURL = image;
                    } else {
                      imageList.url = [];
                      imageList.src = [];
                    }

                    // imageLists.push(imageList);
                    indDay = this.state.dayListAll.findIndex(
                      (obj) => obj.id === this.state.dataImagesDay[p - 1].TripID
                    );
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p - 1].DayNumber) - 1
                    ][7] = imageList.url;
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p - 1].DayNumber) - 1
                    ][8] = imageList.src;

                    image = [];
                    imageSrc = [];
                    imageList = [];

                    image.push(this.state.dataImagesDay[p].ImageURL);
                    imageSrc.push({
                      src: this.state.dataImagesDay[p].ImageURL,
                    });

                    if (p + 1 === parseInt(this.state.dataImagesDay.length)) {
                      // imageList.id = this.state.dataImagesDay[p].TripID;
                      if (image[0]) {
                        imageList.url = image;
                        imageList.src = imageSrc;
                        // this.state.data[k].TripImageURL = image;
                      } else {
                        imageList.url = [];
                        imageList.src = [];
                      }

                      // imageLists.push(imageList);
                      indDay = this.state.dayListAll.findIndex(
                        (obj) => obj.id === this.state.dataImagesDay[p].TripID
                      );
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][7] = imageList.url;
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][8] = imageList.src;
                    }
                  } else if (
                    this.state.dataImagesDay[p].TripID.toString() ===
                      this.state.dataImagesDay[p - 1].TripID.toString() &&
                    this.state.dataImagesDay[p].DayNumber.toString() !==
                      this.state.dataImagesDay[p - 1].DayNumber.toString()
                  ) {
                    // imageList.id = this.state.dataImagesDay[p-1].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                      // this.state.data[k].TripImageURL = image;
                    } else {
                      imageList.url = [];
                      imageList.src = [];
                    }

                    // imageLists.push(imageList);
                    indDay = this.state.dayListAll.findIndex(
                      (obj) => obj.id === this.state.dataImagesDay[p - 1].TripID
                    );
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p - 1].DayNumber) - 1
                    ][7] = imageList.url;
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p - 1].DayNumber) - 1
                    ][8] = imageList.src;

                    image = [];
                    imageSrc = [];
                    imageList = [];

                    image.push(this.state.dataImagesDay[p].ImageURL);
                    imageSrc.push({
                      src: this.state.dataImagesDay[p].ImageURL,
                    });

                    if (p + 1 === parseInt(this.state.dataImagesDay.length)) {
                      // imageList.id = this.state.dataImagesDay[p].TripID;
                      if (image[0]) {
                        imageList.url = image;
                        imageList.src = imageSrc;
                      } else {
                        imageList.url = [];
                        imageList.src = [];
                      }

                      // imageLists.push(imageList);
                      indDay = this.state.dayListAll.findIndex(
                        (obj) => obj.id === this.state.dataImagesDay[p].TripID
                      );
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][7] = imageList.url;
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][8] = imageList.src;
                    }
                  } else {
                    image = [...image];
                    imageSrc = [...imageSrc];
                    image.push(this.state.dataImagesDay[p].ImageURL);
                    imageSrc.push({
                      src: this.state.dataImagesDay[p].ImageURL,
                    });

                    if (p + 1 === parseInt(this.state.dataImagesDay.length)) {
                      // imageList.id = this.state.dataImagesDay[p].TripID;
                      if (image[0]) {
                        imageList.url = image;
                        imageList.src = imageSrc;
                      } else {
                        imageList.url = [];
                        imageList.src = [];
                      }

                      // imageLists.push(imageList);
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][7] = imageList.url;
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][8] = imageList.src;
                    }
                  }
                } else {
                  image.push(this.state.dataImagesDay[p].ImageURL);
                  imageSrc.push({ src: this.state.dataImagesDay[p].ImageURL });

                  if (p + 1 === parseInt(this.state.dataImagesDay.length)) {
                    // imageList.id = this.state.dataImagesDay[p].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                    } else {
                      imageList.url = [];
                      imageList.src = [];
                    }

                    // imageLists.push(imageList);
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                    ][7] = imageList.url;
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                    ][8] = imageList.src;
                  }
                }
                // console.log(imageLists.url);
              }

              // console.log(imageLists);
              // console.log(items);

              this.setState({ dayListAll: items }, () => {
                image = [];
                imageSrc = [];
                imageList = [];

                // console.log(this.state.dayListAll);
              });
            });

            sessionStorage.removeItem("id");
          })
        );
    } else if (ctrip) {
      this.setState({ titleNav: "Itinerary" });
      let url =
        this.state.apiUrl +
        "/api/trip/all/invited" +
        "?userId=" +
        this.state.userId +
        "&joined=0";
      // let url = 'http://localhost:3001/api/trip/all';
      if (id) {
        url =
          this.state.apiUrl +
          "/api/trip/all/invited?id=" +
          id +
          "&userId=" +
          this.state.userId +
          "&joined=0";
      }
      if (this.state.search) {
        url =
          this.state.apiUrl +
          "/api/trip/all/invited?destination=" +
          this.state.search +
          "&userId=" +
          this.state.userId +
          "&joined=0";
      }
      if (this.state.searchShortcut) {
        url =
          this.state.apiUrl +
          "/api/trip/all/invited?destination=" +
          this.state.searchShortcut +
          "&userId=" +
          this.state.userId +
          "&joined=0";
      }
      fetch(url)
        .then((response) => response.json())
        .then((data) =>
          this.setState({ data: data.all }, () => {
            // let j = 0;
            let flightFlag = 0;
            let carFlag = 0;
            let trainFlag = 0;
            let hotelFlag = 0;
            let motorFlag = 0;
            let newData = [];
            let arrayLists = [];
            for (let i = 0; i < this.state.data.length; i++) {
              if (i > 0) {
                // console.log(':r other');
                if (
                  this.state.data[i].TripID.toString() !==
                  this.state.data[i - 1].TripID.toString()
                ) {
                  // not same id
                  // console.log('::r other not same id');

                  // console.log("Important:::>"+newData);
                  // console.log("Important:::>"+this.state.data[i - 1].TripID);
                  // console.log("Important:::>"+this.state.data[i].TripID);
                  // console.log("Important:::>"+this.state.data[i].DayNumber);

                  if (newData.length > 0) {
                    if (flightFlag === 0) {
                      newData[parseInt(newData.length) - 1][2] = "";
                      newData[parseInt(newData.length) - 1][10] = "";
                    }
                    if (hotelFlag === 0) {
                      newData[parseInt(newData.length) - 1][3] = "";
                      newData[parseInt(newData.length) - 1][11] = "";
                    }
                    if (carFlag === 0) {
                      newData[parseInt(newData.length) - 1][4] = "";
                      newData[parseInt(newData.length) - 1][12] = "";
                    }
                    if (trainFlag === 0) {
                      newData[parseInt(newData.length) - 1][5] = "";
                      newData[parseInt(newData.length) - 1][13] = "";
                    }
                    if (motorFlag === 0) {
                      newData[parseInt(newData.length) - 1][6] = "";
                      newData[parseInt(newData.length) - 1][14] = "";
                    }
                  }

                  let arrayList = [];
                  arrayList.id = this.state.data[i - 1].TripID;
                  // arrayList.data = this.state.dayList;
                  arrayList.data = newData;

                  // let arrayLists = [...this.state.dayListAll];
                  arrayLists.push(arrayList);
                  this.setState({ dayListAll: arrayLists }, () => {
                    this.setState({ dayList: [] }, () => {
                      // console.log(this.state.dayListAll);
                    });
                  });

                  // if(!this.state.data[i].DayNumber){
                  //     // console.log('Nulll');
                  //     let arrayList = [];
                  //     arrayList.id = this.state.data[i].TripID;
                  //     // arrayList.data = this.state.dayList;
                  //     arrayList.data = [];
                  //
                  //     // let arrayLists = [...this.state.dayListAll];
                  //     arrayLists.push(arrayList);
                  //     this.setState({dayListAll: arrayLists}, () => {
                  //         this.setState({dayList: []}, () => {
                  //             console.log(this.state.dayListAll);
                  //             i++
                  //         });
                  //
                  //     });
                  //
                  // }

                  // reset round
                  flightFlag = 0;
                  carFlag = 0;
                  trainFlag = 0;
                  hotelFlag = 0;
                  motorFlag = 0;
                  // newData = [];
                  newData = [...this.state.dayList];
                  newData[parseInt(this.state.data[i].DayNumber) - 1] = [];
                  newData[parseInt(this.state.data[i].DayNumber) - 1][0] = ""; //contentTitle
                  newData[parseInt(this.state.data[i].DayNumber) - 1][1] =
                    this.state.data[i].Description;
                  if (this.state.data[i].PlanLinkTypeName === "flight") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                      this.state.data[i].Url;
                    newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                      this.state.data[i].Price;
                    flightFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                      this.state.data[i].Url;
                    newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                      this.state.data[i].Price;
                    hotelFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "car") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                      this.state.data[i].Url;
                    newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                      this.state.data[i].Price;
                    carFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "train") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                      this.state.data[i].Url;
                    newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                      this.state.data[i].Price;
                    trainFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                      this.state.data[i].Url;
                    newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                      this.state.data[i].Price;
                    motorFlag = 1;
                  }
                  // if (this.state.data[i].PlanLinkTypeName === "flight") {
                  //     newData[parseInt(this.state.data[i].DayNumber) - 1][2] = this.state.data[i].Url;
                  //     flightFlag = 1;
                  // } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                  //     newData[parseInt(this.state.data[i].DayNumber) - 1][3] = this.state.data[i].Url;
                  //     hotelFlag = 1;
                  // } else if (this.state.data[i].PlanLinkTypeName === "car") {
                  //     newData[parseInt(this.state.data[i].DayNumber) - 1][4] = this.state.data[i].Url;
                  //     carFlag = 1;
                  // } else if (this.state.data[i].PlanLinkTypeName === "train") {
                  //     newData[parseInt(this.state.data[i].DayNumber) - 1][5] = this.state.data[i].Url;
                  //     trainFlag = 1;
                  // } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                  //     newData[parseInt(this.state.data[i].DayNumber) - 1][6] = this.state.data[i].Url;
                  //     motorFlag = 1;
                  // }
                  newData[parseInt(this.state.data[i].DayNumber) - 1][7] = [];
                  newData[parseInt(this.state.data[i].DayNumber) - 1][8] = [];
                  newData[parseInt(this.state.data[i].DayNumber) - 1][9] =
                    this.state.data[i].ContentDestination; //contentDestination
                  // newData[parseInt(this.state.data[i].DayNumber) - 1][7] = this.state.data[i].ImageURL;
                  // newData[parseInt(this.state.data[i].DayNumber) - 1][8] = this.state.data[i].ImageURL2;

                  if (i + 1 === parseInt(this.state.data.length)) {
                    // console.log(':::r other not same id last r');
                    if (flightFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                        "";
                    }
                    if (hotelFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                        "";
                    }
                    if (carFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                        "";
                    }
                    if (trainFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                        "";
                    }
                    if (motorFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                        "";
                    }

                    let arrayList = [];
                    arrayList.id = this.state.data[i].TripID;
                    // arrayList.data = this.state.dayList;
                    arrayList.data = newData;

                    // let arrayLists = [...this.state.dayListAll];
                    arrayLists.push(arrayList);
                    this.setState({ dayListAll: arrayLists }, () => {
                      this.setState({ dayList: [] }, () => {
                        // console.log(this.state.dayListAll);
                      });
                    });
                  }
                } else {
                  //same id
                  // console.log('::r other same id');

                  if (
                    this.state.data[i].DayNumber !==
                    this.state.data[i - 1].DayNumber
                  ) {
                    // console.log(':::r other same id not same day');
                    // not same day
                    // j++;
                    if (flightFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 2][2] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 2][10] =
                        "";
                    }
                    if (hotelFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 2][3] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 2][11] =
                        "";
                    }
                    if (carFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 2][4] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 2][12] =
                        "";
                    }
                    if (trainFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 2][5] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 2][13] =
                        "";
                    }
                    if (motorFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 2][6] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 2][14] =
                        "";
                    }

                    newData[parseInt(this.state.data[i].DayNumber) - 1] = [];
                    newData[parseInt(this.state.data[i].DayNumber) - 1][0] = ""; //contentTitle
                    newData[parseInt(this.state.data[i].DayNumber) - 1][1] =
                      this.state.data[i].Description;
                    if (this.state.data[i].PlanLinkTypeName === "flight") {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                        this.state.data[i].Price;
                      flightFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "hotel"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                        this.state.data[i].Price;
                      hotelFlag = 1;
                    } else if (this.state.data[i].PlanLinkTypeName === "car") {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                        this.state.data[i].Price;
                      carFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "train"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                        this.state.data[i].Price;
                      trainFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "motor"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                        this.state.data[i].Price;
                      motorFlag = 1;
                    }
                    // if (this.state.data[i].PlanLinkTypeName === "flight") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][2] = this.state.data[i].Url;
                    //     flightFlag = 1;
                    // } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][3] = this.state.data[i].Url;
                    //     hotelFlag = 1;
                    // } else if (this.state.data[i].PlanLinkTypeName === "car") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][4] = this.state.data[i].Url;
                    //     carFlag = 1;
                    // } else if (this.state.data[i].PlanLinkTypeName === "train") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][5] = this.state.data[i].Url;
                    //     trainFlag = 1;
                    // } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][6] = this.state.data[i].Url;
                    //     motorFlag = 1;
                    // }
                    newData[parseInt(this.state.data[i].DayNumber) - 1][7] = [];
                    newData[parseInt(this.state.data[i].DayNumber) - 1][8] = [];
                    newData[parseInt(this.state.data[i].DayNumber) - 1][9] =
                      this.state.data[i].ContentDestination; //contentDestination
                    // newData[parseInt(this.state.data[i].DayNumber) - 1][7] = this.state.data[i].ImageURL;
                    // newData[parseInt(this.state.data[i].DayNumber) - 1][8] = this.state.data[i].ImageURL2;

                    // console.log("r other/id/not day:" + this.state.dayList);

                    if (i + 1 === parseInt(this.state.data.length)) {
                      // console.log('::::r other same id not same day last r');
                      if (flightFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][10] = "";
                      }
                      if (hotelFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][11] = "";
                      }
                      if (carFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][12] = "";
                      }
                      if (trainFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][13] = "";
                      }
                      if (motorFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][14] = "";
                      }

                      let arrayList = [];
                      arrayList.id = this.state.data[i].TripID;
                      // arrayList.data = this.state.dayList;
                      arrayList.data = newData;

                      // let arrayLists = [...this.state.dayListAll];
                      arrayLists.push(arrayList);
                      this.setState({ dayListAll: arrayLists }, () => {
                        this.setState({ dayList: [] }, () => {
                          // console.log(this.state.dayListAll);
                        });
                      });

                      // this.setState({ dayList: newData },()=>{
                      //     let arrayList = [];
                      //     arrayList.id = this.state.data[i].TripID;
                      //     arrayList.data = this.state.dayList;
                      //
                      //     // let arrayLists = [...this.state.dayListAll];
                      //     let arrayLists = [];
                      //     arrayLists.push(arrayList);
                      //     this.setState({ dayListAll: arrayLists },()=>{
                      //         console.log(this.state.dayListAll);
                      //     });
                      // });
                    }
                  } else {
                    //same day
                    // console.log(':::r other same id same day');
                    if (this.state.data[i].PlanLinkTypeName === "flight") {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                        this.state.data[i].Price;
                      flightFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "hotel"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                        this.state.data[i].Price;
                      hotelFlag = 1;
                    } else if (this.state.data[i].PlanLinkTypeName === "car") {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                        this.state.data[i].Price;
                      carFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "train"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                        this.state.data[i].Price;
                      trainFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "motor"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                        this.state.data[i].Price;
                      motorFlag = 1;
                    }
                    // if (this.state.data[i].PlanLinkTypeName === "flight") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][2] = this.state.data[i].Url;
                    //     flightFlag = 1;
                    // } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][3] = this.state.data[i].Url;
                    //     hotelFlag = 1;
                    // } else if (this.state.data[i].PlanLinkTypeName === "car") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][4] = this.state.data[i].Url;
                    //     carFlag = 1;
                    // } else if (this.state.data[i].PlanLinkTypeName === "train") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][5] = this.state.data[i].Url;
                    //     trainFlag = 1;
                    // } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][6] = this.state.data[i].Url;
                    //     motorFlag = 1;
                    // }

                    if (i + 1 === parseInt(this.state.data.length)) {
                      // console.log('::::r other not same id same day last r');
                      if (flightFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][10] = "";
                      }
                      if (hotelFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][11] = "";
                      }
                      if (carFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][12] = "";
                      }
                      if (trainFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][13] = "";
                      }
                      if (motorFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][14] = "";
                      }

                      let arrayList = [];
                      arrayList.id = this.state.data[i].TripID;
                      // arrayList.data = this.state.dayList;
                      arrayList.data = newData;

                      // let arrayLists = [...this.state.dayListAll];
                      arrayLists.push(arrayList);
                      this.setState({ dayListAll: arrayLists }, () => {
                        this.setState({ dayList: [] }, () => {
                          // console.log(this.state.dayListAll);
                        });
                      });
                    }
                  }
                }
              } else {
                // console.log(':r1');
                // if(this.state.data[i].DayNumber){
                newData = [...newData];
                newData[parseInt(this.state.data[i].DayNumber) - 1] = [];
                newData[parseInt(this.state.data[i].DayNumber) - 1][0] = ""; //contentTitle
                newData[parseInt(this.state.data[i].DayNumber) - 1][1] =
                  this.state.data[i].Description;
                if (this.state.data[i].PlanLinkTypeName === "flight") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                    this.state.data[i].Url;
                  newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                    this.state.data[i].Price;
                  flightFlag = 1;
                } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                    this.state.data[i].Url;
                  newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                    this.state.data[i].Price;
                  hotelFlag = 1;
                } else if (this.state.data[i].PlanLinkTypeName === "car") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                    this.state.data[i].Url;
                  newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                    this.state.data[i].Price;
                  carFlag = 1;
                } else if (this.state.data[i].PlanLinkTypeName === "train") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                    this.state.data[i].Url;
                  newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                    this.state.data[i].Price;
                  trainFlag = 1;
                } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                    this.state.data[i].Url;
                  newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                    this.state.data[i].Price;
                  motorFlag = 1;
                }
                // if (this.state.data[i].PlanLinkTypeName === "flight") {
                //     newData[parseInt(this.state.data[i].DayNumber) - 1][2] = this.state.data[i].Url;
                //     flightFlag = 1;
                // } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                //     newData[parseInt(this.state.data[i].DayNumber) - 1][3] = this.state.data[i].Url;
                //     hotelFlag = 1;
                // } else if (this.state.data[i].PlanLinkTypeName === "car") {
                //     newData[parseInt(this.state.data[i].DayNumber) - 1][4] = this.state.data[i].Url;
                //     carFlag = 1;
                // } else if (this.state.data[i].PlanLinkTypeName === "train") {
                //     newData[parseInt(this.state.data[i].DayNumber) - 1][5] = this.state.data[i].Url;
                //     trainFlag = 1;
                // } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                //     newData[parseInt(this.state.data[i].DayNumber) - 1][6] = this.state.data[i].Url;
                //     motorFlag = 1;
                // }

                newData[parseInt(this.state.data[i].DayNumber) - 1][7] = [];
                newData[parseInt(this.state.data[i].DayNumber) - 1][8] = [];
                newData[parseInt(this.state.data[i].DayNumber) - 1][9] =
                  this.state.data[i].ContentDestination; //contentDestination
                // newData[parseInt(this.state.data[i].DayNumber) - 1][7] = this.state.data[i].ImageURL;
                // newData[parseInt(this.state.data[i].DayNumber) - 1][8] = this.state.data[i].ImageURL2;

                // console.log("r1:" + this.state.dayList);

                if (i + 1 === parseInt(this.state.data.length)) {
                  // console.log('::r1 last round');
                  if (flightFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][2] = "";
                    newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                      "";
                  }
                  if (hotelFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][3] = "";
                    newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                      "";
                  }
                  if (carFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][4] = "";
                    newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                      "";
                  }
                  if (trainFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][5] = "";
                    newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                      "";
                  }
                  if (motorFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][6] = "";
                    newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                      "";
                  }

                  let arrayList = [];
                  arrayList.id = this.state.data[i].TripID;
                  // arrayList.data = this.state.dayList;
                  arrayList.data = newData;

                  // let arrayLists = [...this.state.dayListAll];
                  arrayLists.push(arrayList);
                  this.setState({ dayListAll: arrayLists }, () => {
                    this.setState({ dayList: [] }, () => {
                      // console.log(this.state.dayListAll);
                    });
                  });
                }
              }
            }

            this.setState({ dataImages: data.tripImage }, () => {
              let image = [];
              let imageSrc = [];
              let imageList = [];
              let imageLists = [];
              // let k = 0
              for (let j = 0; j < this.state.dataImages.length; j++) {
                if (j > 0) {
                  if (
                    this.state.dataImages[j].TripID.toString() !==
                    this.state.dataImages[j - 1].TripID.toString()
                  ) {
                    imageList.id = this.state.dataImages[j - 1].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                      imageList.startDate = "";
                      imageList.startDateDate = "";
                      // this.state.data[k].TripImageURL = image;
                    } else {
                      imageList.url = null;
                      imageList.src = null;
                      imageList.startDate = "";
                      imageList.startDateDate = "";
                      // this.state.data[k].TripImageURL = null;
                    }
                    // k++;

                    imageLists.push(imageList);

                    image = [];
                    imageSrc = [];
                    imageList = [];

                    image.push(this.state.dataImages[j].ImageURL);
                    imageSrc.push({ src: this.state.dataImages[j].ImageURL });

                    if (j + 1 === parseInt(this.state.dataImages.length)) {
                      imageList.id = this.state.dataImages[j].TripID;
                      if (image[0]) {
                        imageList.url = image;
                        imageList.src = imageSrc;
                        imageList.startDate = "";
                        imageList.startDateDate = "";
                        // this.state.data[k].TripImageURL = image;
                      } else {
                        imageList.url = null;
                        imageList.src = null;
                        imageList.startDate = "";
                        imageList.startDateDate = "";
                        // this.state.data[k].TripImageURL = null;
                      }
                      // k++;

                      imageLists.push(imageList);
                    }
                  } else {
                    image = [...image];
                    imageSrc = [...imageSrc];
                    image.push(this.state.dataImages[j].ImageURL);
                    imageSrc.push({ src: this.state.dataImages[j].ImageURL });

                    if (j + 1 === parseInt(this.state.dataImages.length)) {
                      imageList.id = this.state.dataImages[j].TripID;
                      if (image[0]) {
                        imageList.url = image;
                        imageList.src = imageSrc;
                        imageList.startDate = "";
                        imageList.startDateDate = "";
                        // this.state.data[k].TripImageURL = image;
                      } else {
                        imageList.url = null;
                        imageList.src = null;
                        imageList.startDate = "";
                        imageList.startDateDate = "";
                        // this.state.data[k].TripImageURL = null;
                      }
                      // k++;

                      imageLists.push(imageList);
                    }
                  }
                } else {
                  image.push(this.state.dataImages[j].ImageURL);
                  imageSrc.push({ src: this.state.dataImages[j].ImageURL });

                  if (j + 1 === parseInt(this.state.dataImages.length)) {
                    imageList.id = this.state.dataImages[j].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                      imageList.startDate = "";
                      imageList.startDateDate = "";
                      // this.state.data[k].TripImageURL = image;
                    } else {
                      imageList.url = null;
                      imageList.src = null;
                      imageList.startDate = "";
                      imageList.startDateDate = "";
                      // this.state.data[k].TripImageURL = null;
                    }
                    // k++;

                    imageLists.push(imageList);
                  }
                }
              }

              this.setState({ dataImagesList: imageLists }, () => {
                image = [];
                imageSrc = [];

                let url =
                  this.state.apiUrl +
                  "/api/clone/all/invited" +
                  "?userId=" +
                  this.state.userId;
                // let url = 'http://localhost:3001/api/clone/all?userId='+this.state.userId;
                if (id) {
                  url =
                    this.state.apiUrl +
                    "/api/clone/all/invited?id=" +
                    id +
                    "&userId=" +
                    this.state.userId;
                }
                if (this.state.search) {
                  url =
                    this.state.apiUrl +
                    "/api/clone/all/invited?destination=" +
                    this.state.search +
                    "&userId=" +
                    this.state.userId;
                  // url = 'http://localhost:3001/api/clone/all?destination='+this.state.search+'&userId='+this.state.userId;
                }
                if (this.state.searchShortcut) {
                  url =
                    this.state.apiUrl +
                    "/api/clone/all/invited?destination=" +
                    this.state.searchShortcut +
                    "&userId=" +
                    this.state.userId;
                  // url = 'http://localhost:3001/api/clone/all?destination='+this.state.searchShortcut+'&userId='+this.state.userId;
                }
                fetch(url)
                  .then((response) => response.json())
                  .then((dataClone) =>
                    this.setState({ dataClone: dataClone }, () => {
                      for (let m = 0; m < this.state.dataClone.length; m++) {
                        let ind = this.state.dataImagesList.findIndex(
                          (obj) => obj.id === this.state.dataClone[m].TripID
                        );
                        let indDay = this.state.dayListAll.findIndex(
                          (obj) => obj.id === this.state.dataClone[m].TripID
                        );

                        let dt = this.state.dataClone[m].StartDate;
                        let d = new Date(dt);
                        let mo = d.getMonth() + 1;
                        let month = "";
                        if (mo < 10) {
                          month = "0" + mo.toString();
                        } else {
                          month = mo.toString();
                        }

                        let dd = d.getDate();
                        let ddF = "";
                        if (dd < 10) {
                          ddF = "0" + dd.toString();
                        } else {
                          ddF = dd.toString();
                        }

                        let dateSave =
                          d.getFullYear() + "-" + month + "-" + ddF;
                        // this.state.dataImagesList[ind].startDate = date;

                        let items = [...this.state.dataImagesList];
                        // console.log(items[ind].startDate);
                        // console.log(items[ind]);

                        if (items[ind]) {
                          items[ind].startDate = dateSave;
                          items[ind].joinUser =
                            this.state.dataClone[m].joinUser;
                          // console.log(items[ind].startDate);
                          // console.log("-----------");

                          let dateArr = [];

                          for (
                            let n = 0;
                            n < this.state.dayListAll[indDay].data.length;
                            n++
                          ) {
                            let calDate = new Date(
                              new Date(d).setDate(d.getDate() + n)
                            );
                            dateArr.push({
                              date: calDate.getDate(),
                              month: calDate.getMonth(),
                              year: calDate.getFullYear(),
                            });
                          }

                          // this.state.dataImagesList[ind].startDateDate = dateArr;

                          items[ind].startDateDate = dateArr;
                        } else {
                          items[ind] = [];
                          items[ind].startDate = "";
                          items[ind].joinUser = "";
                          items[ind].startDateDate = [];
                        }

                        this.setState({ dataImagesList: items });
                      }

                      this.setState({
                        dataCloneList: this.state.dataImagesList,
                      });
                    })
                  );
              });
            });

            this.setState({ dataImagesDay: data.contentImage }, () => {
              let image = [];
              let imageSrc = [];
              let imageList = [];
              // let imageLists = []
              // let k = 0
              let items = [...this.state.dayListAll];
              for (let p = 0; p < this.state.dataImagesDay.length; p++) {
                let indDay = this.state.dayListAll.findIndex(
                  (obj) => obj.id === this.state.dataImagesDay[p].TripID
                );

                if (p > 0) {
                  if (
                    this.state.dataImagesDay[p].TripID.toString() !==
                    this.state.dataImagesDay[p - 1].TripID.toString()
                  ) {
                    // imageList.id = this.state.dataImagesDay[p-1].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                      // this.state.data[k].TripImageURL = image;
                    } else {
                      imageList.url = [];
                      imageList.src = [];
                    }

                    // imageLists.push(imageList);
                    indDay = this.state.dayListAll.findIndex(
                      (obj) => obj.id === this.state.dataImagesDay[p - 1].TripID
                    );
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p - 1].DayNumber) - 1
                    ][7] = imageList.url;
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p - 1].DayNumber) - 1
                    ][8] = imageList.src;

                    image = [];
                    imageSrc = [];
                    imageList = [];

                    image.push(this.state.dataImagesDay[p].ImageURL);
                    imageSrc.push({
                      src: this.state.dataImagesDay[p].ImageURL,
                    });

                    if (p + 1 === parseInt(this.state.dataImagesDay.length)) {
                      // imageList.id = this.state.dataImagesDay[p].TripID;
                      if (image[0]) {
                        imageList.url = image;
                        imageList.src = imageSrc;
                        // this.state.data[k].TripImageURL = image;
                      } else {
                        imageList.url = [];
                        imageList.src = [];
                      }

                      // imageLists.push(imageList);
                      indDay = this.state.dayListAll.findIndex(
                        (obj) => obj.id === this.state.dataImagesDay[p].TripID
                      );
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][7] = imageList.url;
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][8] = imageList.src;
                    }
                  } else if (
                    this.state.dataImagesDay[p].TripID.toString() ===
                      this.state.dataImagesDay[p - 1].TripID.toString() &&
                    this.state.dataImagesDay[p].DayNumber.toString() !==
                      this.state.dataImagesDay[p - 1].DayNumber.toString()
                  ) {
                    // imageList.id = this.state.dataImagesDay[p-1].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                      // this.state.data[k].TripImageURL = image;
                    } else {
                      imageList.url = [];
                      imageList.src = [];
                    }

                    // imageLists.push(imageList);
                    indDay = this.state.dayListAll.findIndex(
                      (obj) => obj.id === this.state.dataImagesDay[p - 1].TripID
                    );
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p - 1].DayNumber) - 1
                    ][7] = imageList.url;
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p - 1].DayNumber) - 1
                    ][8] = imageList.src;

                    image = [];
                    imageSrc = [];
                    imageList = [];

                    image.push(this.state.dataImagesDay[p].ImageURL);
                    imageSrc.push({
                      src: this.state.dataImagesDay[p].ImageURL,
                    });

                    if (p + 1 === parseInt(this.state.dataImagesDay.length)) {
                      // imageList.id = this.state.dataImagesDay[p].TripID;
                      if (image[0]) {
                        imageList.url = image;
                        imageList.src = imageSrc;
                      } else {
                        imageList.url = [];
                        imageList.src = [];
                      }

                      // imageLists.push(imageList);
                      indDay = this.state.dayListAll.findIndex(
                        (obj) => obj.id === this.state.dataImagesDay[p].TripID
                      );
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][7] = imageList.url;
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][8] = imageList.src;
                    }
                  } else {
                    image = [...image];
                    imageSrc = [...imageSrc];
                    image.push(this.state.dataImagesDay[p].ImageURL);
                    imageSrc.push({
                      src: this.state.dataImagesDay[p].ImageURL,
                    });

                    if (p + 1 === parseInt(this.state.dataImagesDay.length)) {
                      // imageList.id = this.state.dataImagesDay[p].TripID;
                      if (image[0]) {
                        imageList.url = image;
                        imageList.src = imageSrc;
                      } else {
                        imageList.url = [];
                        imageList.src = [];
                      }

                      // imageLists.push(imageList);
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][7] = imageList.url;
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][8] = imageList.src;
                    }
                  }
                } else {
                  image.push(this.state.dataImagesDay[p].ImageURL);
                  imageSrc.push({ src: this.state.dataImagesDay[p].ImageURL });

                  if (p + 1 === parseInt(this.state.dataImagesDay.length)) {
                    // imageList.id = this.state.dataImagesDay[p].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                    } else {
                      imageList.url = [];
                      imageList.src = [];
                    }

                    // imageLists.push(imageList);
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                    ][7] = imageList.url;
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                    ][8] = imageList.src;
                  }
                }
                // console.log(imageLists.url);
              }

              // console.log(imageLists);
              // console.log(items);

              this.setState({ dayListAll: items }, () => {
                image = [];
                imageSrc = [];
                imageList = [];

                // console.log(this.state.dayListAll);
              });
            });

            sessionStorage.removeItem("id");
          })
        );
    } else {
      this.setState({ titleNav: "" });
      let url = this.state.apiUrl + "/api/trip/all";
      // let url = 'http://localhost:3001/api/trip/all';
      if (id) {
        url = this.state.apiUrl + "/api/trip/all?id=" + id;
      }
      if (this.state.search) {
        url =
          this.state.apiUrl + "/api/trip/all?destination=" + this.state.search;
      }
      if (this.state.searchShortcut) {
        url =
          this.state.apiUrl +
          "/api/trip/all?destination=" +
          this.state.searchShortcut;
      }
      if (profileId) {
        url = this.state.apiUrl + "/api/trip/all?profileId=" + profileId;
      }
      fetch(url)
        .then((response) => response.json())
        .then((data) =>
          this.setState({ data: data.all }, () => {
            // let j = 0;
            let flightFlag = 0;
            let carFlag = 0;
            let trainFlag = 0;
            let hotelFlag = 0;
            let motorFlag = 0;
            let newData = [];
            let arrayLists = [];
            for (let i = 0; i < this.state.data.length; i++) {
              if (i > 0) {
                // console.log(':r other');
                if (
                  this.state.data[i].TripID.toString() !==
                  this.state.data[i - 1].TripID.toString()
                ) {
                  // not same id
                  // console.log('::r other not same id');

                  // console.log("Important:::>"+newData);
                  // console.log("Important:::>"+this.state.data[i - 1].TripID);
                  // console.log("Important:::>"+this.state.data[i].TripID);
                  // console.log("Important:::>"+this.state.data[i].DayNumber);

                  if (newData.length > 0) {
                    if (flightFlag === 0) {
                      newData[parseInt(newData.length) - 1][2] = "";
                      newData[parseInt(newData.length) - 1][10] = "";
                    }
                    if (hotelFlag === 0) {
                      newData[parseInt(newData.length) - 1][3] = "";
                      newData[parseInt(newData.length) - 1][11] = "";
                    }
                    if (carFlag === 0) {
                      newData[parseInt(newData.length) - 1][4] = "";
                      newData[parseInt(newData.length) - 1][12] = "";
                    }
                    if (trainFlag === 0) {
                      newData[parseInt(newData.length) - 1][5] = "";
                      newData[parseInt(newData.length) - 1][13] = "";
                    }
                    if (motorFlag === 0) {
                      newData[parseInt(newData.length) - 1][6] = "";
                      newData[parseInt(newData.length) - 1][14] = "";
                    }
                  }

                  let arrayList = [];
                  arrayList.id = this.state.data[i - 1].TripID;
                  // arrayList.data = this.state.dayList;
                  arrayList.data = newData;

                  // let arrayLists = [...this.state.dayListAll];
                  arrayLists.push(arrayList);
                  this.setState({ dayListAll: arrayLists }, () => {
                    this.setState({ dayList: [] }, () => {
                      // console.log(this.state.dayListAll);
                    });
                  });

                  // if(!this.state.data[i].DayNumber){
                  //     // console.log('Nulll');
                  //     let arrayList = [];
                  //     arrayList.id = this.state.data[i].TripID;
                  //     // arrayList.data = this.state.dayList;
                  //     arrayList.data = [];
                  //
                  //     // let arrayLists = [...this.state.dayListAll];
                  //     arrayLists.push(arrayList);
                  //     this.setState({dayListAll: arrayLists}, () => {
                  //         this.setState({dayList: []}, () => {
                  //             console.log(this.state.dayListAll);
                  //             i++
                  //         });
                  //
                  //     });
                  //
                  // }

                  // reset round
                  flightFlag = 0;
                  carFlag = 0;
                  trainFlag = 0;
                  hotelFlag = 0;
                  motorFlag = 0;
                  // newData = [];
                  newData = [...this.state.dayList];
                  newData[parseInt(this.state.data[i].DayNumber) - 1] = [];
                  newData[parseInt(this.state.data[i].DayNumber) - 1][0] = ""; //contentTitle
                  newData[parseInt(this.state.data[i].DayNumber) - 1][1] =
                    this.state.data[i].Description;
                  if (this.state.data[i].PlanLinkTypeName === "flight") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                      this.state.data[i].Url;
                    newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                      this.state.data[i].Price;
                    flightFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                      this.state.data[i].Url;
                    newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                      this.state.data[i].Price;
                    hotelFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "car") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                      this.state.data[i].Url;
                    newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                      this.state.data[i].Price;
                    carFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "train") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                      this.state.data[i].Url;
                    newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                      this.state.data[i].Price;
                    trainFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                      this.state.data[i].Url;
                    newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                      this.state.data[i].Price;
                    motorFlag = 1;
                  }
                  // if (this.state.data[i].PlanLinkTypeName === "flight") {
                  //     newData[parseInt(this.state.data[i].DayNumber) - 1][2] = this.state.data[i].Url;
                  //     flightFlag = 1;
                  // } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                  //     newData[parseInt(this.state.data[i].DayNumber) - 1][3] = this.state.data[i].Url;
                  //     hotelFlag = 1;
                  // } else if (this.state.data[i].PlanLinkTypeName === "car") {
                  //     newData[parseInt(this.state.data[i].DayNumber) - 1][4] = this.state.data[i].Url;
                  //     carFlag = 1;
                  // } else if (this.state.data[i].PlanLinkTypeName === "train") {
                  //     newData[parseInt(this.state.data[i].DayNumber) - 1][5] = this.state.data[i].Url;
                  //     trainFlag = 1;
                  // } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                  //     newData[parseInt(this.state.data[i].DayNumber) - 1][6] = this.state.data[i].Url;
                  //     motorFlag = 1;
                  // }
                  newData[parseInt(this.state.data[i].DayNumber) - 1][7] = [];
                  newData[parseInt(this.state.data[i].DayNumber) - 1][8] = [];
                  newData[parseInt(this.state.data[i].DayNumber) - 1][9] =
                    this.state.data[i].ContentDestination; //contentDestination
                  // newData[parseInt(this.state.data[i].DayNumber) - 1][7] = this.state.data[i].ImageURL;
                  // newData[parseInt(this.state.data[i].DayNumber) - 1][8] = this.state.data[i].ImageURL2;

                  if (i + 1 === parseInt(this.state.data.length)) {
                    // console.log(':::r other not same id last r');
                    if (flightFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                        "";
                    }
                    if (hotelFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                        "";
                    }
                    if (carFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                        "";
                    }
                    if (trainFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                        "";
                    }
                    if (motorFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                        "";
                    }

                    let arrayList = [];
                    arrayList.id = this.state.data[i].TripID;
                    // arrayList.data = this.state.dayList;
                    arrayList.data = newData;

                    // let arrayLists = [...this.state.dayListAll];
                    arrayLists.push(arrayList);
                    this.setState({ dayListAll: arrayLists }, () => {
                      this.setState({ dayList: [] }, () => {
                        // console.log(this.state.dayListAll);
                      });
                    });
                  }
                } else {
                  //same id
                  // console.log('::r other same id');

                  if (
                    this.state.data[i].DayNumber !==
                    this.state.data[i - 1].DayNumber
                  ) {
                    // console.log(':::r other same id not same day');
                    // not same day
                    // j++;
                    if (flightFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 2][2] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 2][10] =
                        "";
                    }
                    if (hotelFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 2][3] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 2][11] =
                        "";
                    }
                    if (carFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 2][4] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 2][12] =
                        "";
                    }
                    if (trainFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 2][5] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 2][13] =
                        "";
                    }
                    if (motorFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 2][6] =
                        "";
                      newData[parseInt(this.state.data[i].DayNumber) - 2][14] =
                        "";
                    }

                    newData[parseInt(this.state.data[i].DayNumber) - 1] = [];
                    newData[parseInt(this.state.data[i].DayNumber) - 1][0] = ""; //contentTitle
                    newData[parseInt(this.state.data[i].DayNumber) - 1][1] =
                      this.state.data[i].Description;
                    if (this.state.data[i].PlanLinkTypeName === "flight") {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                        this.state.data[i].Price;
                      flightFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "hotel"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                        this.state.data[i].Price;
                      hotelFlag = 1;
                    } else if (this.state.data[i].PlanLinkTypeName === "car") {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                        this.state.data[i].Price;
                      carFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "train"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                        this.state.data[i].Price;
                      trainFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "motor"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                        this.state.data[i].Price;
                      motorFlag = 1;
                    }
                    // if (this.state.data[i].PlanLinkTypeName === "flight") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][2] = this.state.data[i].Url;
                    //     flightFlag = 1;
                    // } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][3] = this.state.data[i].Url;
                    //     hotelFlag = 1;
                    // } else if (this.state.data[i].PlanLinkTypeName === "car") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][4] = this.state.data[i].Url;
                    //     carFlag = 1;
                    // } else if (this.state.data[i].PlanLinkTypeName === "train") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][5] = this.state.data[i].Url;
                    //     trainFlag = 1;
                    // } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][6] = this.state.data[i].Url;
                    //     motorFlag = 1;
                    // }
                    newData[parseInt(this.state.data[i].DayNumber) - 1][7] = [];
                    newData[parseInt(this.state.data[i].DayNumber) - 1][8] = [];
                    newData[parseInt(this.state.data[i].DayNumber) - 1][9] =
                      this.state.data[i].ContentDestination; //contentDestination
                    // newData[parseInt(this.state.data[i].DayNumber) - 1][7] = this.state.data[i].ImageURL;
                    // newData[parseInt(this.state.data[i].DayNumber) - 1][8] = this.state.data[i].ImageURL2;

                    // console.log("r other/id/not day:" + this.state.dayList);

                    if (i + 1 === parseInt(this.state.data.length)) {
                      // console.log('::::r other same id not same day last r');
                      if (flightFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][10] = "";
                      }
                      if (hotelFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][11] = "";
                      }
                      if (carFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][12] = "";
                      }
                      if (trainFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][13] = "";
                      }
                      if (motorFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][14] = "";
                      }

                      let arrayList = [];
                      arrayList.id = this.state.data[i].TripID;
                      // arrayList.data = this.state.dayList;
                      arrayList.data = newData;

                      // let arrayLists = [...this.state.dayListAll];
                      arrayLists.push(arrayList);
                      this.setState({ dayListAll: arrayLists }, () => {
                        this.setState({ dayList: [] }, () => {
                          // console.log(this.state.dayListAll);
                        });
                      });

                      // this.setState({ dayList: newData },()=>{
                      //     let arrayList = [];
                      //     arrayList.id = this.state.data[i].TripID;
                      //     arrayList.data = this.state.dayList;
                      //
                      //     // let arrayLists = [...this.state.dayListAll];
                      //     let arrayLists = [];
                      //     arrayLists.push(arrayList);
                      //     this.setState({ dayListAll: arrayLists },()=>{
                      //         console.log(this.state.dayListAll);
                      //     });
                      // });
                    }
                  } else {
                    //same day
                    // console.log(':::r other same id same day');
                    if (this.state.data[i].PlanLinkTypeName === "flight") {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                        this.state.data[i].Price;
                      flightFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "hotel"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                        this.state.data[i].Price;
                      hotelFlag = 1;
                    } else if (this.state.data[i].PlanLinkTypeName === "car") {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                        this.state.data[i].Price;
                      carFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "train"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                        this.state.data[i].Price;
                      trainFlag = 1;
                    } else if (
                      this.state.data[i].PlanLinkTypeName === "motor"
                    ) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                        this.state.data[i].Url;
                      newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                        this.state.data[i].Price;
                      motorFlag = 1;
                    }
                    // if (this.state.data[i].PlanLinkTypeName === "flight") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][2] = this.state.data[i].Url;
                    //     flightFlag = 1;
                    // } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][3] = this.state.data[i].Url;
                    //     hotelFlag = 1;
                    // } else if (this.state.data[i].PlanLinkTypeName === "car") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][4] = this.state.data[i].Url;
                    //     carFlag = 1;
                    // } else if (this.state.data[i].PlanLinkTypeName === "train") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][5] = this.state.data[i].Url;
                    //     trainFlag = 1;
                    // } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                    //     newData[parseInt(this.state.data[i].DayNumber) - 1][6] = this.state.data[i].Url;
                    //     motorFlag = 1;
                    // }

                    if (i + 1 === parseInt(this.state.data.length)) {
                      // console.log('::::r other not same id same day last r');
                      if (flightFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][10] = "";
                      }
                      if (hotelFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][11] = "";
                      }
                      if (carFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][12] = "";
                      }
                      if (trainFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][13] = "";
                      }
                      if (motorFlag === 0) {
                        newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                          "";
                        newData[
                          parseInt(this.state.data[i].DayNumber) - 1
                        ][14] = "";
                      }

                      let arrayList = [];
                      arrayList.id = this.state.data[i].TripID;
                      // arrayList.data = this.state.dayList;
                      arrayList.data = newData;

                      // let arrayLists = [...this.state.dayListAll];
                      arrayLists.push(arrayList);
                      this.setState({ dayListAll: arrayLists }, () => {
                        this.setState({ dayList: [] }, () => {
                          // console.log(this.state.dayListAll);
                        });
                      });
                    }
                  }
                }
              } else {
                // console.log(':r1');
                // if(this.state.data[i].DayNumber){
                newData = [...newData];
                newData[parseInt(this.state.data[i].DayNumber) - 1] = [];
                newData[parseInt(this.state.data[i].DayNumber) - 1][0] = ""; //contentTitle
                newData[parseInt(this.state.data[i].DayNumber) - 1][1] =
                  this.state.data[i].Description;
                if (this.state.data[i].PlanLinkTypeName === "flight") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                    this.state.data[i].Url;
                  newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                    this.state.data[i].Price;
                  flightFlag = 1;
                } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                    this.state.data[i].Url;
                  newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                    this.state.data[i].Price;
                  hotelFlag = 1;
                } else if (this.state.data[i].PlanLinkTypeName === "car") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                    this.state.data[i].Url;
                  newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                    this.state.data[i].Price;
                  carFlag = 1;
                } else if (this.state.data[i].PlanLinkTypeName === "train") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                    this.state.data[i].Url;
                  newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                    this.state.data[i].Price;
                  trainFlag = 1;
                } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                    this.state.data[i].Url;
                  newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                    this.state.data[i].Price;
                  motorFlag = 1;
                }
                // if (this.state.data[i].PlanLinkTypeName === "flight") {
                //     newData[parseInt(this.state.data[i].DayNumber) - 1][2] = this.state.data[i].Url;
                //     flightFlag = 1;
                // } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                //     newData[parseInt(this.state.data[i].DayNumber) - 1][3] = this.state.data[i].Url;
                //     hotelFlag = 1;
                // } else if (this.state.data[i].PlanLinkTypeName === "car") {
                //     newData[parseInt(this.state.data[i].DayNumber) - 1][4] = this.state.data[i].Url;
                //     carFlag = 1;
                // } else if (this.state.data[i].PlanLinkTypeName === "train") {
                //     newData[parseInt(this.state.data[i].DayNumber) - 1][5] = this.state.data[i].Url;
                //     trainFlag = 1;
                // } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                //     newData[parseInt(this.state.data[i].DayNumber) - 1][6] = this.state.data[i].Url;
                //     motorFlag = 1;
                // }

                newData[parseInt(this.state.data[i].DayNumber) - 1][7] = [];
                newData[parseInt(this.state.data[i].DayNumber) - 1][8] = [];
                newData[parseInt(this.state.data[i].DayNumber) - 1][9] =
                  this.state.data[i].ContentDestination; //contentDestination
                // newData[parseInt(this.state.data[i].DayNumber) - 1][7] = this.state.data[i].ImageURL;
                // newData[parseInt(this.state.data[i].DayNumber) - 1][8] = this.state.data[i].ImageURL2;

                // console.log("r1:" + this.state.dayList);

                if (i + 1 === parseInt(this.state.data.length)) {
                  // console.log('::r1 last round');
                  if (flightFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][2] = "";
                    newData[parseInt(this.state.data[i].DayNumber) - 1][10] =
                      "";
                  }
                  if (hotelFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][3] = "";
                    newData[parseInt(this.state.data[i].DayNumber) - 1][11] =
                      "";
                  }
                  if (carFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][4] = "";
                    newData[parseInt(this.state.data[i].DayNumber) - 1][12] =
                      "";
                  }
                  if (trainFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][5] = "";
                    newData[parseInt(this.state.data[i].DayNumber) - 1][13] =
                      "";
                  }
                  if (motorFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][6] = "";
                    newData[parseInt(this.state.data[i].DayNumber) - 1][14] =
                      "";
                  }

                  let arrayList = [];
                  arrayList.id = this.state.data[i].TripID;
                  // arrayList.data = this.state.dayList;
                  arrayList.data = newData;

                  // let arrayLists = [...this.state.dayListAll];
                  arrayLists.push(arrayList);
                  this.setState({ dayListAll: arrayLists }, () => {
                    this.setState({ dayList: [] }, () => {
                      // console.log(this.state.dayListAll);
                    });
                  });
                }
              }
            }

            this.setState({ dataImages: data.tripImage }, () => {
              let image = [];
              let imageSrc = [];
              let imageList = [];
              let imageLists = [];
              // let k = 0
              for (let j = 0; j < this.state.dataImages.length; j++) {
                if (j > 0) {
                  if (
                    this.state.dataImages[j].TripID.toString() !==
                    this.state.dataImages[j - 1].TripID.toString()
                  ) {
                    imageList.id = this.state.dataImages[j - 1].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                      imageList.startDate = "";
                      imageList.startDateDate = "";
                      // this.state.data[k].TripImageURL = image;
                    } else {
                      imageList.url = null;
                      imageList.src = null;
                      imageList.startDate = "";
                      imageList.startDateDate = "";
                      // this.state.data[k].TripImageURL = null;
                    }
                    // k++;

                    imageLists.push(imageList);

                    image = [];
                    imageSrc = [];
                    imageList = [];

                    image.push(this.state.dataImages[j].ImageURL);
                    imageSrc.push({ src: this.state.dataImages[j].ImageURL });

                    if (j + 1 === parseInt(this.state.dataImages.length)) {
                      imageList.id = this.state.dataImages[j].TripID;
                      if (image[0]) {
                        imageList.url = image;
                        imageList.src = imageSrc;
                        imageList.startDate = "";
                        imageList.startDateDate = "";
                        // this.state.data[k].TripImageURL = image;
                      } else {
                        imageList.url = null;
                        imageList.src = null;
                        imageList.startDate = "";
                        imageList.startDateDate = "";
                        // this.state.data[k].TripImageURL = null;
                      }
                      // k++;

                      imageLists.push(imageList);
                    }
                  } else {
                    image = [...image];
                    imageSrc = [...imageSrc];
                    image.push(this.state.dataImages[j].ImageURL);
                    imageSrc.push({ src: this.state.dataImages[j].ImageURL });

                    if (j + 1 === parseInt(this.state.dataImages.length)) {
                      imageList.id = this.state.dataImages[j].TripID;
                      if (image[0]) {
                        imageList.url = image;
                        imageList.src = imageSrc;
                        imageList.startDate = "";
                        imageList.startDateDate = "";
                        // this.state.data[k].TripImageURL = image;
                      } else {
                        imageList.url = null;
                        imageList.src = null;
                        imageList.startDate = "";
                        imageList.startDateDate = "";
                        // this.state.data[k].TripImageURL = null;
                      }
                      // k++;

                      imageLists.push(imageList);
                    }
                  }
                } else {
                  image.push(this.state.dataImages[j].ImageURL);
                  imageSrc.push({ src: this.state.dataImages[j].ImageURL });

                  if (j + 1 === parseInt(this.state.dataImages.length)) {
                    imageList.id = this.state.dataImages[j].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                      imageList.startDate = "";
                      imageList.startDateDate = "";
                      // this.state.data[k].TripImageURL = image;
                    } else {
                      imageList.url = null;
                      imageList.src = null;
                      imageList.startDate = "";
                      imageList.startDateDate = "";
                      // this.state.data[k].TripImageURL = null;
                    }
                    // k++;

                    imageLists.push(imageList);
                  }
                }
              }

              this.setState({ dataImagesList: imageLists }, () => {
                image = [];
                imageSrc = [];

                if (ctrip) {
                  let url =
                    this.state.apiUrl +
                    "/api/clone/all/invited" +
                    "?userId=" +
                    this.state.userId +
                    "&ctrip=" +
                    ctrip;
                  // let url = 'http://localhost:3001/api/clone/all?userId='+this.state.userId;
                  if (id) {
                    url =
                      this.state.apiUrl +
                      "/api/clone/all/invited?id=" +
                      id +
                      "&userId=" +
                      this.state.userId +
                      "&ctrip=" +
                      ctrip;
                  }
                  if (this.state.search) {
                    url =
                      this.state.apiUrl +
                      "/api/clone/all/invited?destination=" +
                      this.state.search +
                      "&userId=" +
                      this.state.userId +
                      "&ctrip=" +
                      ctrip;
                    // url = 'http://localhost:3001/api/clone/all?destination='+this.state.search+'&userId='+this.state.userId;
                  }
                  if (this.state.searchShortcut) {
                    url =
                      this.state.apiUrl +
                      "/api/clone/all/invited?destination=" +
                      this.state.searchShortcut +
                      "&userId=" +
                      this.state.userId +
                      "&ctrip=" +
                      ctrip;
                    // url = 'http://localhost:3001/api/clone/all?destination='+this.state.searchShortcut+'&userId='+this.state.userId;
                  }
                  fetch(url)
                    .then((response) => response.json())
                    .then((dataClone) =>
                      this.setState({ dataClone: dataClone }, () => {
                        for (let m = 0; m < this.state.dataClone.length; m++) {
                          let ind = this.state.dataImagesList.findIndex(
                            (obj) => obj.id === this.state.dataClone[m].TripID
                          );
                          let indDay = this.state.dayListAll.findIndex(
                            (obj) => obj.id === this.state.dataClone[m].TripID
                          );

                          let dt = this.state.dataClone[m].StartDate;
                          let d = new Date(dt);
                          let mo = d.getMonth() + 1;
                          let month = "";
                          if (mo < 10) {
                            month = "0" + mo.toString();
                          } else {
                            month = mo.toString();
                          }

                          let dd = d.getDate();
                          let ddF = "";
                          if (dd < 10) {
                            ddF = "0" + dd.toString();
                          } else {
                            ddF = dd.toString();
                          }

                          let dateSave =
                            d.getFullYear() + "-" + month + "-" + ddF;
                          // this.state.dataImagesList[ind].startDate = date;

                          let items = [...this.state.dataImagesList];
                          // console.log(items[ind].startDate);
                          // console.log(items[ind]);

                          if (items[ind]) {
                            items[ind].startDate = dateSave;
                            items[ind].joinUser =
                              this.state.dataClone[m].joinUser;
                            // console.log(items[ind].startDate);
                            // console.log("-----------");

                            let dateArr = [];

                            for (
                              let n = 0;
                              n < this.state.dayListAll[indDay].data.length;
                              n++
                            ) {
                              let calDate = new Date(
                                new Date(d).setDate(d.getDate() + n)
                              );
                              dateArr.push({
                                date: calDate.getDate(),
                                month: calDate.getMonth(),
                                year: calDate.getFullYear(),
                              });
                            }

                            // this.state.dataImagesList[ind].startDateDate = dateArr;

                            items[ind].startDateDate = dateArr;
                          } else {
                            items[ind] = [];
                            items[ind].startDate = "";
                            items[ind].joinUser = "";
                            items[ind].startDateDate = [];
                          }

                          this.setState({ dataImagesList: items });
                        }

                        this.setState({
                          dataCloneList: this.state.dataImagesList,
                        });
                      })
                    );
                } else {
                  let url =
                    this.state.apiUrl +
                    "/api/clone/all" +
                    "?userId=" +
                    this.state.userId;
                  // let url = 'http://localhost:3001/api/clone/all?userId='+this.state.userId;
                  if (id) {
                    url =
                      this.state.apiUrl +
                      "/api/clone/all?id=" +
                      id +
                      "&userId=" +
                      this.state.userId;
                  }
                  if (this.state.search) {
                    url =
                      this.state.apiUrl +
                      "/api/clone/all?destination=" +
                      this.state.search +
                      "&userId=" +
                      this.state.userId;
                    // url = 'http://localhost:3001/api/clone/all?destination='+this.state.search+'&userId='+this.state.userId;
                  }
                  if (this.state.searchShortcut) {
                    url =
                      this.state.apiUrl +
                      "/api/clone/all?destination=" +
                      this.state.searchShortcut +
                      "&userId=" +
                      this.state.userId;
                    // url = 'http://localhost:3001/api/clone/all?destination='+this.state.searchShortcut+'&userId='+this.state.userId;
                  }
                  fetch(url)
                    .then((response) => response.json())
                    .then((dataClone) =>
                      this.setState({ dataClone: dataClone }, () => {
                        for (let m = 0; m < this.state.dataClone.length; m++) {
                          let ind = this.state.dataImagesList.findIndex(
                            (obj) => obj.id === this.state.dataClone[m].TripID
                          );
                          let indDay = this.state.dayListAll.findIndex(
                            (obj) => obj.id === this.state.dataClone[m].TripID
                          );

                          let dt = this.state.dataClone[m].StartDate;
                          let d = new Date(dt);
                          let mo = d.getMonth() + 1;
                          let month = "";
                          if (mo < 10) {
                            month = "0" + mo.toString();
                          } else {
                            month = mo.toString();
                          }

                          let dd = d.getDate();
                          let ddF = "";
                          if (dd < 10) {
                            ddF = "0" + dd.toString();
                          } else {
                            ddF = dd.toString();
                          }

                          let dateSave =
                            d.getFullYear() + "-" + month + "-" + ddF;
                          // this.state.dataImagesList[ind].startDate = date;

                          let items = [...this.state.dataImagesList];
                          // console.log(items[ind].startDate);
                          // console.log(items[ind]);

                          if (items[ind]) {
                            items[ind].startDate = dateSave;
                            items[ind].joinUser =
                              this.state.dataClone[m].joinUser;
                            // console.log(items[ind].startDate);
                            // console.log("-----------");

                            let dateArr = [];

                            for (
                              let n = 0;
                              n < this.state.dayListAll[indDay].data.length;
                              n++
                            ) {
                              let calDate = new Date(
                                new Date(d).setDate(d.getDate() + n)
                              );
                              dateArr.push({
                                date: calDate.getDate(),
                                month: calDate.getMonth(),
                                year: calDate.getFullYear(),
                              });
                            }

                            // this.state.dataImagesList[ind].startDateDate = dateArr;

                            items[ind].startDateDate = dateArr;
                          } else {
                            items[ind] = [];
                            items[ind].startDate = "";
                            items[ind].joinUser = "";
                            items[ind].startDateDate = [];
                          }

                          this.setState({ dataImagesList: items });
                        }

                        this.setState({
                          dataCloneList: this.state.dataImagesList,
                        });
                      })
                    );
                }
              });
            });

            this.setState({ dataImagesDay: data.contentImage }, () => {
              let image = [];
              let imageSrc = [];
              let imageList = [];
              // let imageLists = []
              // let k = 0
              let items = [...this.state.dayListAll];
              for (let p = 0; p < this.state.dataImagesDay.length; p++) {
                let indDay = this.state.dayListAll.findIndex(
                  (obj) => obj.id === this.state.dataImagesDay[p].TripID
                );

                if (p > 0) {
                  if (
                    this.state.dataImagesDay[p].TripID.toString() !==
                    this.state.dataImagesDay[p - 1].TripID.toString()
                  ) {
                    // imageList.id = this.state.dataImagesDay[p-1].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                      // this.state.data[k].TripImageURL = image;
                    } else {
                      imageList.url = [];
                      imageList.src = [];
                    }

                    // imageLists.push(imageList);
                    indDay = this.state.dayListAll.findIndex(
                      (obj) => obj.id === this.state.dataImagesDay[p - 1].TripID
                    );
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p - 1].DayNumber) - 1
                    ][7] = imageList.url;
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p - 1].DayNumber) - 1
                    ][8] = imageList.src;

                    image = [];
                    imageSrc = [];
                    imageList = [];

                    image.push(this.state.dataImagesDay[p].ImageURL);
                    imageSrc.push({
                      src: this.state.dataImagesDay[p].ImageURL,
                    });

                    if (p + 1 === parseInt(this.state.dataImagesDay.length)) {
                      // imageList.id = this.state.dataImagesDay[p].TripID;
                      if (image[0]) {
                        imageList.url = image;
                        imageList.src = imageSrc;
                        // this.state.data[k].TripImageURL = image;
                      } else {
                        imageList.url = [];
                        imageList.src = [];
                      }

                      // imageLists.push(imageList);
                      indDay = this.state.dayListAll.findIndex(
                        (obj) => obj.id === this.state.dataImagesDay[p].TripID
                      );
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][7] = imageList.url;
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][8] = imageList.src;
                    }
                  } else if (
                    this.state.dataImagesDay[p].TripID.toString() ===
                      this.state.dataImagesDay[p - 1].TripID.toString() &&
                    this.state.dataImagesDay[p].DayNumber.toString() !==
                      this.state.dataImagesDay[p - 1].DayNumber.toString()
                  ) {
                    // imageList.id = this.state.dataImagesDay[p-1].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                      // this.state.data[k].TripImageURL = image;
                    } else {
                      imageList.url = [];
                      imageList.src = [];
                    }

                    // imageLists.push(imageList);
                    indDay = this.state.dayListAll.findIndex(
                      (obj) => obj.id === this.state.dataImagesDay[p - 1].TripID
                    );
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p - 1].DayNumber) - 1
                    ][7] = imageList.url;
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p - 1].DayNumber) - 1
                    ][8] = imageList.src;

                    image = [];
                    imageSrc = [];
                    imageList = [];

                    image.push(this.state.dataImagesDay[p].ImageURL);
                    imageSrc.push({
                      src: this.state.dataImagesDay[p].ImageURL,
                    });

                    if (p + 1 === parseInt(this.state.dataImagesDay.length)) {
                      // imageList.id = this.state.dataImagesDay[p].TripID;
                      if (image[0]) {
                        imageList.url = image;
                        imageList.src = imageSrc;
                      } else {
                        imageList.url = [];
                        imageList.src = [];
                      }

                      // imageLists.push(imageList);
                      indDay = this.state.dayListAll.findIndex(
                        (obj) => obj.id === this.state.dataImagesDay[p].TripID
                      );
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][7] = imageList.url;
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][8] = imageList.src;
                    }
                  } else {
                    image = [...image];
                    imageSrc = [...imageSrc];
                    image.push(this.state.dataImagesDay[p].ImageURL);
                    imageSrc.push({
                      src: this.state.dataImagesDay[p].ImageURL,
                    });

                    if (p + 1 === parseInt(this.state.dataImagesDay.length)) {
                      // imageList.id = this.state.dataImagesDay[p].TripID;
                      if (image[0]) {
                        imageList.url = image;
                        imageList.src = imageSrc;
                      } else {
                        imageList.url = [];
                        imageList.src = [];
                      }

                      // imageLists.push(imageList);
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][7] = imageList.url;
                      items[indDay].data[
                        parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                      ][8] = imageList.src;
                    }
                  }
                } else {
                  image.push(this.state.dataImagesDay[p].ImageURL);
                  imageSrc.push({ src: this.state.dataImagesDay[p].ImageURL });

                  if (p + 1 === parseInt(this.state.dataImagesDay.length)) {
                    // imageList.id = this.state.dataImagesDay[p].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                    } else {
                      imageList.url = [];
                      imageList.src = [];
                    }

                    // imageLists.push(imageList);
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                    ][7] = imageList.url;
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                    ][8] = imageList.src;
                  }
                }
                // console.log(imageLists.url);
              }

              // console.log(imageLists);
              // console.log(items);

              this.setState({ dayListAll: items }, () => {
                image = [];
                imageSrc = [];
                imageList = [];

                // console.log(this.state.dayListAll);
              });
            });

            sessionStorage.removeItem("id");
          })
        );
    }
  }

  clickLinkFlight(num) {
    let element = document.getElementById("flightInputBox" + num);
    let element2 = document.getElementById("flightIcon" + num);

    if (element.classList.contains("d-none") === false) {
      element.classList.add("d-none");
      element2.classList.remove("d-none");
    } else {
      element.classList.remove("d-none");
      element2.classList.add("d-none");
    }
  }

  clickLinkCar(num) {
    let elementCar = document.getElementById("carInputBox" + num);
    let element2 = document.getElementById("carIcon" + num);
    if (elementCar.classList.contains("d-none") === false) {
      elementCar.classList.add("d-none");
      element2.classList.remove("d-none");
    } else {
      elementCar.classList.remove("d-none");
      element2.classList.add("d-none");
    }
  }

  clickLinkTrain(num) {
    let elementTrain = document.getElementById("trainInputBox" + num);
    let element2 = document.getElementById("trainIcon" + num);
    if (elementTrain.classList.contains("d-none") === false) {
      elementTrain.classList.add("d-none");
      element2.classList.remove("d-none");
    } else {
      elementTrain.classList.remove("d-none");
      element2.classList.add("d-none");
    }
  }

  clickLinkMotor(num) {
    let elementMotor = document.getElementById("motorInputBox" + num);
    let element2 = document.getElementById("motorIcon" + num);
    if (elementMotor.classList.contains("d-none") === false) {
      elementMotor.classList.add("d-none");
      element2.classList.remove("d-none");
    } else {
      elementMotor.classList.remove("d-none");
      element2.classList.add("d-none");
    }
  }

  clickLinkHotel(num) {
    let elementHotel = document.getElementById("hotelInputBox" + num);
    let element2 = document.getElementById("hotelIcon" + num);
    if (elementHotel.classList.contains("d-none") === false) {
      elementHotel.classList.add("d-none");
      element2.classList.remove("d-none");
    } else {
      elementHotel.classList.remove("d-none");
      element2.classList.add("d-none");
    }
  }

  toggleTimeline(id) {
    var element = document.getElementById("arrow-down" + id);
    var element2 = document.getElementById("arrow-up" + id);
    if (element2.classList.contains("d-none") === false) {
      element2.classList.add("d-none");
    } else {
      element2.classList.remove("d-none");
    }
    if (element.classList.contains("d-none") === false) {
      element.classList.add("d-none");
    } else {
      element.classList.remove("d-none");
    }

    let elementDescriptionHide = document.getElementById(
      "tripDescriptionHide" + id
    );
    let elementDescription = document.getElementById("tripDescription" + id);

    let elementTimeline = document.getElementById("timeline" + id);
    if (elementTimeline.classList.contains("d-none") === false) {
      elementTimeline.classList.add("d-none");

      if (elementDescription.classList.contains("d-none") === true) {
        elementDescription.classList.remove("d-none");
      }

      if (elementDescriptionHide.classList.contains("d-none") === false) {
        elementDescriptionHide.classList.add("d-none");
      }
    } else {
      elementTimeline.classList.remove("d-none");

      if (elementDescription.classList.contains("d-none") === false) {
        elementDescription.classList.add("d-none");
      }

      if (elementDescriptionHide.classList.contains("d-none") === true) {
        elementDescriptionHide.classList.remove("d-none");
      }
    }
  }

  toggleTimelineUp(id) {
    var element = document.getElementById("arrow-down" + id);
    var element2 = document.getElementById("arrow-up" + id);
    if (element2.classList.contains("d-none") === false) {
      element2.classList.add("d-none");
    } else {
      element2.classList.remove("d-none");
    }
    if (element.classList.contains("d-none") === false) {
      element.classList.add("d-none");
    } else {
      element.classList.remove("d-none");
    }

    let elementDescriptionHide = document.getElementById(
      "tripDescriptionHide" + id
    );
    let elementDescription = document.getElementById("tripDescription" + id);

    let elementTimeline = document.getElementById("timeline" + id);
    // if(elementTimeline.classList.contains("d-none") === false){
    //     elementTimeline.classList.add("d-none");
    // }
    // else{
    //     elementTimeline.classList.remove("d-none");
    // }

    if (elementTimeline.classList.contains("d-none") === false) {
      elementTimeline.classList.add("d-none");

      if (elementDescription.classList.contains("d-none") === true) {
        elementDescription.classList.remove("d-none");
      }

      if (elementDescriptionHide.classList.contains("d-none") === false) {
        elementDescriptionHide.classList.add("d-none");
      }
    } else {
      elementTimeline.classList.remove("d-none");

      if (elementDescription.classList.contains("d-none") === false) {
        elementDescription.classList.add("d-none");
      }

      if (elementDescriptionHide.classList.contains("d-none") === true) {
        elementDescriptionHide.classList.remove("d-none");
      }
    }

    window.location.href = "#content-timeline" + id;
  }

  toggleProfile(id) {
    var element = document.getElementById("arrow-down-profile");
    var element2 = document.getElementById("arrow-up-profile");
    if (element2.classList.contains("d-none") === false) {
      element2.classList.add("d-none");
    } else {
      element2.classList.remove("d-none");
    }
    if (element.classList.contains("d-none") === false) {
      element.classList.add("d-none");
    } else {
      element.classList.remove("d-none");
    }

    let elementProfile = document.getElementById(id);
    if (elementProfile.classList.contains("d-none") === false) {
      elementProfile.classList.add("d-none");
    } else {
      elementProfile.classList.remove("d-none");
    }
  }

  handleChange(e) {
    switch (e.target.name) {
      case "search":
        this.setState({ search: e.target.value }, () => {
          if (e.target.value === "") {
            this.setState({ searchShortcut: "" }, () => {
              // window.setTimeout(function(){
              //     var element = document.getElementById("search-icon");
              //     var element2 = document.getElementById("search-bar");
              //     if(element2.classList.contains("d-none") === false){
              //         element2.classList.add("d-none");
              //     }
              //     else{
              //         element2.classList.remove("d-none");
              //     }
              //     if(element.classList.contains("d-none") === false){
              //         element.classList.add("d-none");
              //     }
              //     else{
              //         element.classList.remove("d-none");
              //     }
              // }, 10000);

              this.getAllData();
            });
          }
        });
        break;
      case "friendInvited":
        this.setState({ friendInvited: e.target.value });
        break;
      default:
      // code block
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleMobile() {
    document.getElementById("sidenavMobile").style.width = "100%";
  }

  closeMobile() {
    document.getElementById("sidenavMobile").style.width = "0";
  }

  logout() {
    // this.setState({memberId: ""});
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
  }

  clickSlideOut() {
    var element = document.getElementById("slideout_inner");
    if (element.classList.contains("show") === false) {
      element.classList.add("show");
    } else {
      element.classList.remove("show");
    }
  }

  checkEnter(e) {
    if (e.keyCode === 13) {
      // console.log("Enter key is pressed:: "+ this.state.search);
      this.setState({ searchShortcut: "" }, () => {
        let searchBar = document.getElementById("search-bar");
        if (searchBar.classList.contains("d-none") === false) {
          searchBar.classList.add("d-none");
        }
        this.getAllData();
      });
    }
  }

  searchShortcut(search) {
    this.setState({ searchShortcut: search }, () => {
      this.setState({ search: "" }, () => {
        this.getAllData();
      });
    });
  }

  openLightbox(sourceImg) {
    this.setState({ sourceImg: sourceImg }, () => {
      this.setState({ open: true });
    });
  }

  handleChangeDate(date, tripID) {
    const monthsName = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      // "January",
      // "February",
      // "March",
      // "April",
      // "May",
      // "June",
      // "July",
      // "August",
      // "September",
      // "October",
      // "November",
      // "December",
    ];

    let dt = date;
    let d = new Date(dt);
    let mo = d.getMonth() + 1;
    let month = "";
    if (mo < 10) {
      month = "0" + mo.toString();
    } else {
      month = mo.toString();
    }

    let dd = d.getDate();
    let ddF = "";
    if (dd < 10) {
      ddF = "0" + dd.toString();
    } else {
      ddF = dd.toString();
    }

    let dateSave = d.getFullYear() + "-" + month + "-" + ddF;

    this.setState(
      { fullDate: dd + " " + monthsName[mo] + " " + d.getFullYear() },
      () => {
        this.setState({ startDate: dateSave }, () => {
          // console.log(this.state.startDate);
          let dataInvite = {
            email: "",
            userId: this.state.userId,
            startDate: this.state.startDate,
            tripId: tripID,
          };

          fetch(
            this.state.apiUrl + "/api/clone/invite",
            // fetch('http://localhost:3001/api/clone/invite',
            {
              body: JSON.stringify(dataInvite),
              method: "POST",
              headers: { "Content-type": "application/json;charset=UTF-8" },
            }
          )
            .then((res) => res.json())
            .then((data) => {
              //return data for show
              // console.log(data)
              if (data.message === "Success") {
                this.setState({ startDate: "" }, () => {
                  //fetch show
                  this.getAllData();
                });
              } else {
                //error
              }
            });
        });
      }
    );
  }

  getNextFriday() {
    const today = new Date();
    const currentDay = today.getDay();
    const daysUntilFriday =
      currentDay <= 5 ? 5 - currentDay : 5 + (7 - currentDay);
    const nextFriday = new Date(today);
    nextFriday.setDate(today.getDate() + daysUntilFriday + 7);
    return nextFriday;
  }

  isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  handleChangeFriendInvited(e, tripID) {
    document.getElementById(
      "checkFriendInvitedNotFound" + tripID
    ).style.display = "none";
    document.getElementById("checkFriendInvited" + tripID).style.display =
      "none";
    // document.getElementById("checkFriendInvitedNotFound"+tripID+"m").style.display = "none";
    // document.getElementById("checkFriendInvited"+tripID+"m").style.display = "none";
    if (e.keyCode === 13) {
      if (this.state.friendInvited === "") {
        document.getElementById("checkFriendInvited" + tripID).style.display =
          "block";
        // document.getElementById("checkFriendInvited"+tripID+"m").style.display = "block";
        window.setTimeout(function () {
          document.getElementById("checkFriendInvited" + tripID).style.display =
            "none";
          // document.getElementById("checkFriendInvited"+tripID+"m").style.display = "none";
        }, 5000);
      } else {
        if (this.isValidEmail(this.state.friendInvited)) {
          let dataInvite = {
            email: this.state.friendInvited,
            userId: this.state.userId,
            startDate: "",
            tripId: tripID,
          };
          fetch(
            this.state.apiUrl + "/api/clone/invite",
            // fetch('http://localhost:3001/api/clone/invite',
            {
              body: JSON.stringify(dataInvite),
              method: "POST",
              headers: { "Content-type": "application/json;charset=UTF-8" },
            }
          )
            .then((res) => res.json())
            .then((data) => {
              //return data for show
              // console.log(data)
              if (data.message === "Success") {
                this.setState({ friendInvited: "" }, () => {
                  this.toggleInvite(tripID);
                  //fetch show
                  document.getElementById(
                    "checkFriendInvitedSuccess" + tripID
                  ).style.display = "block";
                  // document.getElementById("checkFriendInvitedSuccess"+tripID+"m").style.display = "block";
                  window.setTimeout(function () {
                    document.getElementById(
                      "checkFriendInvitedSuccess" + tripID
                    ).style.display = "none";
                    // document.getElementById("checkFriendInvitedSuccess"+tripID+"m").style.display = "none";
                  }, 5000);

                  this.getAllData();
                });
              } else if (data.message === "Duplicate request with same email") {
                //error + Duplicate email request
                document.getElementById(
                  "checkFriendInvitedSameEmail" + tripID
                ).style.display = "block";
                // document.getElementById("checkFriendInvitedSameEmail"+tripID+"m").style.display = "block";
                window.setTimeout(function () {
                  document.getElementById(
                    "checkFriendInvitedSameEmail" + tripID
                  ).style.display = "none";
                  // document.getElementById("checkFriendInvitedSameEmail"+tripID+"m").style.display = "none";
                }, 5000);
              }
              // else{
              //     //check template show this error**
              //     document.getElementById("checkFriendInvitedNotFound"+tripID).style.display = "block";
              //     document.getElementById("checkFriendInvitedNotFound"+tripID+"m").style.display = "block";
              //     window.setTimeout(function(){
              //         document.getElementById("checkFriendInvitedNotFound"+tripID).style.display = "none";
              //         document.getElementById("checkFriendInvitedNotFound"+tripID+"m").style.display = "none";
              //     }, 5000);
              //
              //
              // }
            });
          // .catch(error => {
          // this.setState({visible : true});
          // });
        } else {
          document.getElementById(
            "checkFriendInvitedNotFound" + tripID
          ).style.display = "block";
          // document.getElementById("checkFriendInvitedNotFound"+tripID+"m").style.display = "block";
          window.setTimeout(function () {
            document.getElementById(
              "checkFriendInvitedNotFound" + tripID
            ).style.display = "none";
            // document.getElementById("checkFriendInvitedNotFound"+tripID+"m").style.display = "none";
          }, 5000);
        }
      }
    }
  }

  toggleInvite(tripId) {
    let elementMotor = document.getElementById("invite-friend-box" + tripId);
    if (elementMotor.classList.contains("d-none") === false) {
      elementMotor.classList.add("d-none");
    } else {
      elementMotor.classList.remove("d-none");
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  toggleVisible() {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      this.setState({ visible: true });
    } else if (scrolled <= 300) {
      this.setState({ visible: false });
    }

    // if (scrolled > 50){
    //     document.getElementById("navFridayis").classList.add('bg-transparent-white');
    //     document.getElementById("navFridayis").classList.remove('bg-white');
    // }
    // else if (scrolled <= 50){
    //     document.getElementById("navFridayis").classList.add('bg-white');
    //     document.getElementById("navFridayis").classList.remove('bg-transparent-white');
    // }
  }

  toggleTripdescriptionHide(id) {
    var element = document.getElementById("tripDescriptionHide" + id);
    var element2 = document.getElementById("tripDescription" + id);
    if (element2.classList.contains("d-none") === false) {
      element2.classList.add("d-none");
    } else {
      element2.classList.remove("d-none");
    }
    if (element.classList.contains("d-none") === false) {
      element.classList.add("d-none");
    } else {
      element.classList.remove("d-none");
    }
  }

  openSearch() {
    // var element = document.getElementById("search-icon");
    var element2 = document.getElementById("search-bar");
    if (element2.classList.contains("d-none") === false) {
      element2.classList.add("d-none");
    } else {
      element2.classList.remove("d-none");
    }
    // if(element.classList.contains("d-none") === false){
    //     element.classList.add("d-none");
    // }
    // else{
    //     element.classList.remove("d-none");
    // }
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  closeModalShare() {
    this.setState({ showModalShare: false });
  }

  openProfile(id) {
    window.location.href = "/home?profileId=" + id;
  }
  openTimeline() {
    window.location.href = "/home";
  }

  getUrlTimeline(id) {
    if (id === 2) {
      this.setState({ titleNav: "Itinerary" }, () => {
        sessionStorage.setItem("defaultIndex", 1);
        window.location.href = "/itinerary?ctrip=all";
      });
    } else {
      this.setState({ titleNav: "" }, () => {
        sessionStorage.setItem("defaultIndex", 0);
        window.location.href =
          "/post?profileId=" + localStorage.getItem("userId");
      });
    }
  }

  joinTrip() {
    // console.log(this.state.ctrip);
    // console.log(this.state.userId);

    // api/clone/update/joined

    let dataJoined = {
      cloneTripId: this.state.ctrip,
      userId: this.state.userId,
    };

    fetch(
      this.state.apiUrl + "/api/clone/update/joined",
      // fetch('http://localhost:3001/api/clone/update/joined',
      {
        body: JSON.stringify(dataJoined),
        method: "POST",
        headers: { "Content-type": "application/json;charset=UTF-8" },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Success") {
          this.setState({ titleNav: "Itinerary" }, () => {
            sessionStorage.setItem("defaultIndex", 1);
            window.location.href = "/itinerary?ctrip=all";
          });
        } else {
          //error
        }
      });
  }

  openModalShare(link, tripID) {
    this.setState({ shareUrl: link }, () => {
      this.setState({ showModalShare: true });
    });
  }

  copyToClipboard(link) {
    navigator.clipboard.writeText(link);

    this.setState({ showModalShare: false }, () => {
      document.getElementById("checkCopySuccess").style.display = "block";
    });
    window.setTimeout(function () {
      document.getElementById("checkCopySuccess").style.display = "none";
    }, 5000);
  }

  getNextFridayFormatted(index, format) {
    const today = new Date();
    const nextFriday = new Date(today);

    // Find days until the next Friday (Friday is day 5)
    nextFriday.setDate(
      today.getDate() +
        ((5 - today.getDay() + 7) % 7 || 7) +
        7 +
        parseInt(index)
    );

    // Optional: Increment by 1 week for looping
    // nextFriday.setDate(nextFriday.getDate() + 7 * loopIndex); // Increment weeks if needed

    // Formatting date
    if (format === 1) {
      const options = { day: "numeric", month: "short" };
      return nextFriday.toLocaleDateString("en-GB", options);
    } else {
      const options = { day: "numeric", month: "short", year: "numeric" };
      return nextFriday.toLocaleDateString("en-GB", options);
    }
  }

  render() {
    const { data, error, isLoading } = this.props;

    // if (isLoading) return (  <div>
    //     <Skeleton animation="wave" height={120} width="90%" sx={{display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' }}}/>
    //     <Skeleton animation="wave" height={120} width="35%" sx={{ marginBottom: 6,display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }} />

    //     <Skeleton animation="wave" height={80} width="50%" sx={{display: { xs: 'none', sm: 'block', md: 'none', lg: 'none' }}}/>
    //     <Skeleton animation="wave" height={80} width="20%" sx={{ marginBottom: 6,display: { xs: 'none', sm: 'block', md: 'none', lg: 'none' } }} />

    //     <Skeleton animation="wave" height={60} width="90%" sx={{display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' }}}/>
    //     <Skeleton animation="wave" height={60} width="40%" sx={{marginBottom: 4,display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' }}}/>

    //   <div className="post">
    //     <div className="container">
    //     <div className="user">
    //       <div className="userInfo">
    //       <Skeleton animation="wave" variant="circular" width={40} height={40} />
    //       <div className="details">
    //         <span className="name"><Skeleton animation="wave" height={10} width={60} style={{ marginBottom: 4, marginTop: 4 }} /></span>
    //         <span className="date"><Skeleton animation="wave" height={10} width={40} style={{ marginBottom: 6 }} /></span>
    //     </div>
    //   </div>

    //     </div>
    //     <div className="content">
    //     <Skeleton sx={{ height: 300, mb: 2 }} animation="wave" variant="rectangular" />
    //     <Grid container justifyContent="flex-end"><Skeleton sx={{mb: 1}} animation="wave" height={10} width="30%" /></Grid>
    //     <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
    //     <Skeleton animation="wave" height={10} width="80%" />

    //     </div>
    //     </div>
    //   </div>
    //   </div>

    //   );
    // if (error) return <p>Error: {error.message}</p>;

    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 4,
      swipeToSlide: true,
      // nextArrow: <SampleNextArrow />,
      // prevArrow: <SamplePrevArrow />,
      // dots: false,
      // infinite: true,
      speed: 300,
      // slidesToShow: 5,
      // slidesToScroll: 5,
      responsive: [
        {
          breakpoint: 1181,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 3,
            // slidesToShow: 3,
            // slidesToScroll: 3,
            // infinite: true,
            // dots: false
          },
        },
        {
          breakpoint: 1040,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 2,
            // slidesToShow: 3,
            // slidesToScroll: 3,
            // infinite: true,
            // dots: false
          },
        },
        {
          breakpoint: 820,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "140px",
            slidesToShow: 2,
            // slidesToShow: 2,
            // slidesToScroll: 2
          },
        },
        {
          breakpoint: 799,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "120px",
            slidesToShow: 2,
            // slidesToShow: 2,
            // slidesToScroll: 2
          },
        },
        {
          breakpoint: 670,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "100px",
            slidesToShow: 2,
            // slidesToShow: 2,
            // slidesToScroll: 2
          },
        },
        {
          breakpoint: 530,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "80px",
            slidesToShow: 2,
            // slidesToShow: 2,
            // slidesToScroll: 2
          },
        },
        {
          breakpoint: 500,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "130px",
            slidesToShow: 1,
            // slidesToShow: 2,
            // slidesToScroll: 2
          },
        },
        {
          breakpoint: 480,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "120px",
            slidesToShow: 1,
            // slidesToShow: 2,
            // slidesToScroll: 2
          },
        },
        {
          breakpoint: 430,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "100px",
            slidesToShow: 1,
            // slidesToShow: 2,
            // slidesToScroll: 2
          },
        },
      ],
    };
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const nextFriday = this.getNextFriday();
    return (
      <div className="bg">
        {/*{*/}
        {/*    (this.state.urlHelmet) ? ( <div>*/}
        {/*        <HeadTags title={this.state.data[0]?.TripName} metaDescription={this.state.data[0]?.TripDescription} metaImage={this.state.dataImagesList[0]?.url[0]} metaUrl={"https://www.fridayis.com?id="+this.state.data[0]?.TripID} />*/}
        {/*        </div>*/}
        {/*    )*/}
        {/*        : (<div><HeadTags /></div>)*/}
        {/*}*/}
        {this.state.urlHelmet && (
          <HeadTags
            title={this.state.data[0]?.TripName}
            metaDescription={this.state.data[0]?.TripDescription}
            metaImage={this.state.dataImagesList[0]?.url[0]}
            metaUrl={
              "https://www.fridayis.com?id=" + this.state.data[0]?.TripID
            }
          />
        )}

        <Lightbox
          open={this.state.open}
          close={() => this.setState({ open: false })}
          slides={this.state.sourceImg}
        />

        <Modal
          isOpen={this.state.ss}
          className="iframe-contain"
          id="iframe-content"
        >
          <div className="row">
            <div className="col-8 offset-2 col-md-10 offset-md-1 text-center fw-bold">
              Create Post
            </div>
            <div className="col-2 col-md-1">
              <div className="text-center">
                <IoIosClose
                  id="close-modal-icon"
                  onClick={() => this.setState({ ss: false })}
                  style={{ cursor: "pointer", fontSize: "18px" }}
                />
              </div>
            </div>
          </div>
          <CreatePage
            id="iframeContent"
            className=""
            display="block"
            position="relative"
          />
        </Modal>

        <Modal
          isOpen={this.state.showModal}
          contentLabel="ComingSoon"
          className="modelComingSoon"
        >
          <div className="text-right">
            <IoIosClose
              onClick={this.closeModal}
              style={{ "font-size": "50px", cursor: "pointer" }}
            />
          </div>
          <img src={ComingSoon} width="100%" />
        </Modal>

        <Modal
          isOpen={this.state.showModalShare}
          contentLabel="Share"
          className="share-modal"
        >
          <div className="text-right">
            <IoIosClose
              onClick={this.closeModalShare}
              style={{ "font-size": "50px", cursor: "pointer" }}
            />
          </div>
          <div clssName="row">
            <div clssName="col-12" style={{ marginLeft: "1.5rem" }}>
              <button
                className="btn btn-tranparent border-0 mx-4 my-2"
                onClick={this.copyToClipboard.bind(this, this.state.shareUrl)}
              >
                <BiCopyAlt /> Copy link
              </button>
            </div>
            <div clssName="col-12">
              <FacebookShareButton className="" url={this.state.shareUrl}>
                <button className="form-control btn btn-tranparent border-0 mx-4 my-2">
                  <AiFillFacebook /> Share on Facebook
                </button>
              </FacebookShareButton>
            </div>
            <div clssName="col-12">
              <TwitterShareButton className="" url={this.state.shareUrl}>
                <button className="form-control btn btn-tranparent border-0 mx-4 my-2">
                  <RiTwitterXFill /> Share on X (Twitter)
                </button>
              </TwitterShareButton>
            </div>
            <div clssName="col-12">
              <WhatsappShareButton className="" url={this.state.shareUrl}>
                <button className="form-control btn btn-tranparent border-0 mx-4 my-2">
                  <AiOutlineWhatsApp /> Share on WhatsApp
                </button>
              </WhatsappShareButton>
            </div>
          </div>
          {/*<button className="share-button btn btn-outline-dark mx-4 my-2" onClick={this.copyToClipboard.bind(this, this.state.shareUrl)}>*/}
          {/*    Copy link*/}
          {/*</button>*/}
          {/*<div className="share-buttons">*/}
          {/*    <FacebookShareButton className="share-button" url={this.state.shareUrl}>*/}
          {/*        <button className="btn btn-outline-primary mx-4 my-2">Share on Facebook</button>*/}
          {/*    </FacebookShareButton>*/}

          {/*    <TwitterShareButton className="share-button" url={this.state.shareUrl}>*/}
          {/*        <button className="form-control btn btn-outline-info mx-4 my-2">Share on Twitter</button>*/}
          {/*    </TwitterShareButton>*/}

          {/*    <WhatsappShareButton className="share-button" url={this.state.shareUrl}>*/}
          {/*        <button className="form-control btn btn-outline-success mx-4 my-2">Share on WhatsApp</button>*/}
          {/*    </WhatsappShareButton>*/}
          {/*</div>*/}
        </Modal>

        {/*Navbar*/}
        <Header />
        {/* <Navbar id="navFridayis" className="container bg-transparent fixed-top">
                        <div className="w-50 ">
                                <NavbarBrand role="button" href="/"><img src={Logo} className="logo-img" alt="Profile" /><p className="d-none d-md-inline-flex fw-bold mx-3" style={{"fontSize": "16px","paddingTop":"1rem"}}>Fridayis</p></NavbarBrand>
                                <p className="fw-bold title-nav pt-point75">{this.state.titleNav}</p>
                            </div>
                        <div className="d-flex mx-4">
                        <button className="sarch-btn" onClick={this.openSearch}><img src={IconSearch} className="nav-icon" alt="icon search"/></button>
                        <div className="mt-1">
                            {(localStorage.getItem("imageUrl")) ? <NavbarBrand role="button"  onClick={this.toggleMobile}><img src={localStorage.getItem("imageUrl")} className="profile-img" alt="Profile" /></NavbarBrand> : <NavbarBrand role="button" onClick={this.toggleMobile}><img src={ProfileImg} className="profile-img" alt="Profile" /></NavbarBrand>}
                        </div>
                        </div>
                    </Navbar> */}

        <div id="sidenavMobile" className="sidenav">
          <a
            href="javascript:void(0)"
            className="closebtn"
            onClick={this.closeMobile}
          >
            <GrFormClose />
          </a>
          <Container className="pb-5">
            <div className="d-flex justify-content-center">
              {localStorage.getItem("imageUrl") ? (
                <img
                  src={localStorage.getItem("imageUrl")}
                  className="profile-img-big cursor-pointer"
                  alt="Profile"
                  onClick={this.openProfile.bind(
                    this,
                    localStorage.getItem("userId")
                  )}
                />
              ) : (
                <img
                  src={ProfileImg}
                  className="profile-img-big cursor-pointer"
                  alt="Profile"
                  onClick={this.openProfile.bind(
                    this,
                    localStorage.getItem("userId")
                  )}
                />
              )}
            </div>
            <div className="text-center mt-4">
              {localStorage.getItem("username") && (
                <div
                  className="fw-bold cursor-pointer"
                  onClick={this.openProfile.bind(
                    this,
                    localStorage.getItem("userId")
                  )}
                >
                  {localStorage.getItem("username")}
                </div>
              )}
            </div>
            <br />
            <button
              className="btn btn-dark circle-border"
              onClickCapture={this.logout}
            >
              Logout
            </button>

            <br />
            <div>
              <div id="profile-toggle" className="">
                {/*<Tabs className="Tabs" defaultIndex={sessionStorage.getItem("defaultIndex")} onSelect={(index) => console.log(index)}>*/}
                <Tabs
                  className="Tabs"
                  defaultIndex={0}
                  onSelect={(index) => console.log(index)}
                >
                  <TabList id="left-tab">
                    <Tab onClick={this.getUrlTimeline.bind(this, 1)}>Post</Tab>
                    {/*<Tab><a href="/home?ctrip=all" className="link-default">Invited</a></Tab>*/}
                    <Tab onClick={this.getUrlTimeline.bind(this, 2)}>
                      Itinerary
                    </Tab>
                  </TabList>

                  <TabPanel className="text-left">
                    <h2 className="new-text">What is going sideways</h2>
                    <br />
                    <p>
                      Going forward, our dedication is to implement essential
                      and high-quality functions that enhance the user
                      experience, making engagement with our platform smooth and
                      impressive.
                    </p>
                    <p>
                      <span className="fw-bold">Who we are:</span>
                      <br />
                      We are startups in Sydney, Australia, and Tokyo, Japan.
                      Our mission is to create a platform that enables the
                      sharing of rich travel experiences and information,
                      representing the richness and diversity of the world. We
                      believe that travel has the power to break boundaries and
                      foster shared learning between individuals.
                    </p>
                    <p>
                      <span className="fw-bold">
                        {" "}
                        What we have accomplished:
                      </span>
                      <br />
                      Users can share their travel stories and itineraries,
                      bringing together unique perspectives from around the
                      world. We have developed a system that allows your
                      followers to visit the platform and invite their friends
                      to join their journey. During this time, users will have
                      the opportunity to learn how to use this platform through
                      its initial functions. This learning process will
                      familiarize your followers with the platform, reducing
                      mistakes in the future.
                    </p>
                    <p>
                      <span className="fw-bold">
                        Here's what we're currently working on:
                      </span>
                      <br />
                      Once your followers have a clear understanding of the core
                      functions of the platform, they will be able to book
                      tickets and accommodations directly through it. We are
                      developing a feature that will allow bloggers like you to
                      track the revenue generated from your referral bookings
                      and other necessary functions that benefit both you and
                      your followers.
                    </p>
                    <p>
                      Note: While waiting for the booking process, you will have
                      the opportunity to earn money through Google Ads.
                    </p>
                    <p>
                      We are excited about future developments and It would be a
                      great honor for us to join you on the same ship.
                    </p>
                  </TabPanel>
                  <TabPanel className="text-left">
                    <h2 className="new-text">What is going sideways</h2>
                    {/*<br/>*/}
                    {/*<div className="row">*/}
                    {/*    <div className="col-4"><span className="fw-bold">Who</span><br />we are</div>*/}
                    {/*    <div className="col-4"><span className="fw-bold">What</span><br />we have done</div>*/}
                    {/*    <div className="col-4"><span className="fw-bold">Which</span><br />is going on</div>*/}
                    {/*</div>*/}
                    <br />
                    <p>
                      Going forward, our dedication is to implement essential
                      and high-quality functions that enhance the user
                      experience, making engagement with our platform smooth and
                      impressive.
                    </p>
                    <p>
                      <span className="fw-bold">Who we are:</span>
                      <br />
                      We are startups in Sydney, Australia, and Tokyo, Japan.
                      Our mission is to create a platform that enables the
                      sharing of rich travel experiences and information,
                      representing the richness and diversity of the world. We
                      believe that travel has the power to break boundaries and
                      foster shared learning between individuals.
                    </p>
                    <p>
                      <span className="fw-bold">
                        {" "}
                        What we have accomplished:
                      </span>
                      <br />
                      Users can share their travel stories and itineraries,
                      bringing together unique perspectives from around the
                      world. We have developed a system that allows your
                      followers to visit the platform and invite their friends
                      to join their journey. During this time, users will have
                      the opportunity to learn how to use this platform through
                      its initial functions. This learning process will
                      familiarize your followers with the platform, reducing
                      mistakes in the future.
                    </p>
                    <p>
                      <span className="fw-bold">
                        Here's what we're currently working on:
                      </span>
                      <br />
                      Once your followers have a clear understanding of the core
                      functions of the platform, they will be able to book
                      tickets and accommodations directly through it. We are
                      developing a feature that will allow bloggers like you to
                      track the revenue generated from your referral bookings
                      and other necessary functions that benefit both you and
                      your followers.
                    </p>
                    <p>
                      Note: While waiting for the booking process, you will have
                      the opportunity to earn money through Google Ads.
                    </p>
                    <p>
                      We are excited about future developments and It would be a
                      great honor for us to join you on the same ship.
                    </p>
                  </TabPanel>
                </Tabs>
              </div>
              {/* <div className="copy-right-mobile position-fixed bottom-0">
                                    <p>Fridayis 2023 ©</p>
                                </div> */}
            </div>
          </Container>
        </div>

        <div
          className="container-fluid row position-absolute link-copied"
          id={"checkCopySuccess"}
          style={{ display: "none", zIndex: "10" }}
        >
          <div className="col-12 col-md-4 offset-md-8 col-lg-3 offset-lg-9">
            <div className="alert alert-success" role="alert">
              <TbClipboardCheck /> Link Copied!
            </div>
          </div>
        </div>

        {/* Search bar */}
        <Container>
          <div
            id="search-bar"
            className="search-bar-absolute input-group mx-3 d-none"
          >
            <Input
              className="right-padding-input circle-border-search-bar form-control-sm"
              type="text"
              name="search"
              id="search"
              value={this.state.search}
              onChange={this.handleChange}
              onKeyDown={(e) => this.checkEnter(e)}
            />
            <span className="position-absolute right-margin pr-1 pt-half1 bg-transparent border-0 border">
              <FaSearch />
            </span>
          </div>
        </Container>

        {/*post desktop*/}
        <div className="d-none d-lg-flex d-md-flex">
          <div className="post-bar">
            <button
              type="button"
              className="btn btn-light post-button-blue-mobile"
              id="post-page"
              onClick={this.showIframe}
            >
              <img
                src={IconPaperPlus}
                alt="paper plus icon"
                className="icon-post-mobile"
              />
            </button>
          </div>
        </div>

        {/*bar mobile*/}
        <div className="d-flex justify-content-center d-block d-lg-none">
          <Card body className="border-0 circle-none-border-small-post-mobile">
            {/* <div className="w-100"> */}
            <div className="d-flex justify-content-between">
              <button className="btn circle-button" onClick={this.openTimeline}>
                <img
                  src={IconPaper}
                  alt="paper icon"
                  className="icon-circle-button"
                />
              </button>
              <button
                type="button"
                className="btn btn-light post-button-blue-mobile"
                id="post-page"
                onClick={this.showIframe}
              >
                <img
                  src={IconPaperPlus}
                  alt="paper plus icon"
                  className="icon-post-mobile"
                />
              </button>
              <div className="mt-1">
                {localStorage.getItem("imageUrl") ? (
                  <NavbarBrand role="button" onClick={this.openPostPage}>
                    <img
                      src={localStorage.getItem("imageUrl")}
                      className="profile-img"
                      alt="Profile"
                    />
                  </NavbarBrand>
                ) : (
                  <NavbarBrand role="button" onClick={this.openPostPage}>
                    <img
                      src={ProfileImg}
                      className="profile-img"
                      alt="Profile"
                    />
                  </NavbarBrand>
                )}
              </div>
            </div>
            {/* </div> */}
          </Card>
        </div>

        {/* Profile */}
        <div className="d-flex justify-content-center mt-5 pt-5">
          {localStorage.getItem("imageUrl") ? (
            <img
              src={localStorage.getItem("imageUrl")}
              className="profile-img-big cursor-pointer"
              alt="Profile"
              onClick={this.openProfile.bind(
                this,
                localStorage.getItem("userId")
              )}
            />
          ) : (
            <img
              src={ProfileImg}
              className="profile-img-big cursor-pointer"
              alt="Profile"
              onClick={this.openProfile.bind(
                this,
                localStorage.getItem("userId")
              )}
            />
          )}
        </div>
        <div className="text-center mt-4">
          {localStorage.getItem("username") && (
            <div
              className="fw-bold cursor-pointer"
              onClick={this.openProfile.bind(
                this,
                localStorage.getItem("userId")
              )}
            >
              {localStorage.getItem("username")}
            </div>
          )}
        </div>
        <div className="text-center mt-4">
          {localStorage.getItem("profile-info") && (
            <div>localStorage.getItem("profile-info")</div>
          )}
        </div>
        <br />
        <Tabs
          className="Tabs"
          defaultIndex={0}
          onSelect={(index) => console.log(index)}
        >
          <TabList id="left-tab">
            <Tab onClick={this.getUrlTimeline.bind(this, 1)}>Post</Tab>
            <Tab onClick={this.getUrlTimeline.bind(this, 2)}>Itinerary</Tab>
          </TabList>
        </Tabs>

        {/* Content */}
        <div className="container">
          {/*<a id="back-to-top" className="btn btn-light btn-sm back-to-top" onClick={this.scrollToTop}*/}
          {/*   style={{display: this.state.visible ? 'inline' : 'none'}} role="button">*/}
          {/*    <MdKeyboardArrowUp  />*/}
          {/*</a>*/}

          <Container className="right-content mb-5 mt-5 pt-3 pmx-sm-md-0">
            {error ? (
              <div className="row mb-5">
                <div className="col-12 col-md-6 offset-md-3">
                  <img src={NoContent} width="100%" />
                </div>
              </div>
            ) : isLoading ? (
              <div>
                <div className="row">
                  {/* <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                                        <Skeleton animation="wave" height={50} width="90%" sx={{display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' }}}/>
                                        <Skeleton animation="wave" height={50} width="35%" sx={{ marginBottom: 6,display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }} />

                                        <Skeleton animation="wave" height={120} width="90%" sx={{display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' }}}/>
                                        <Skeleton animation="wave" height={120} width="35%" sx={{ marginBottom: 6,display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }} />
                                    </div> */}
                  <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 pb-0 mb-0">
                    <div className="pt-2">
                      <div className="my-1">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <Skeleton
                              animation="wave"
                              variant="circular"
                              width={40}
                              height={40}
                            />
                            <span class="pt-2" style={{ marginLeft: ".5rem" }}>
                              <Skeleton
                                animation="wave"
                                height={15}
                                width={100}
                                style={{ marginBottom: 4, marginTop: 4 }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 pmx-sm-md-0 py-0 my-0">
                    <Skeleton
                      sx={{ height: 300, mb: 2 }}
                      animation="wave"
                      variant="rectangular"
                    />
                    <div className="d-flex justify-content-between">
                      <Skeleton
                        sx={{ mb: 1 }}
                        animation="wave"
                        height={15}
                        width="30%"
                      />
                      <Skeleton
                        sx={{ mb: 1 }}
                        animation="wave"
                        height={15}
                        width="20%"
                      />
                    </div>
                    <Skeleton
                      animation="wave"
                      height={15}
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton animation="wave" height={15} width="80%" />
                    <div className="row mb-2 mt-2">
                      <div className="col-4">
                        <Skeleton
                          sx={{ mb: 1 }}
                          animation="wave"
                          height={15}
                          width="30%"
                        />
                        <Skeleton
                          sx={{ mb: 1 }}
                          animation="wave"
                          height={15}
                          width="30%"
                        />
                      </div>
                      <div className="col-4">
                        <div className="row h-100">
                          <div className="col-6 offset-3 col-md-4 offset-md-4">
                            <Skeleton
                              sx={{ mb: 1 }}
                              animation="wave"
                              height={15}
                              width="100%"
                            />
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              width={60}
                              height={30}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-4 d-flex justify-content-center pt-3">
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          width={80}
                          height={40}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : this.state.data.length === 0 ? (
              <div className="row mb-5">
                <div className="col-12 col-md-6 offset-md-3">
                  <img src={NoContent} width="100%" />
                </div>
              </div>
            ) : (
              <div>
                {/* <div className='row' id='makeTheFirstMove'>
                                    <div className="col-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
                                        <h1 className="text-96">
                                            Make the first <br/>move!
                                        </h1>
                                    </div>
                                </div> */}
                {this.state.data?.map((row, rowIndex) => (
                  <div>
                    {rowIndex > 0 ? (
                      <div>
                        {
                          // (rowIndex % 20 === 0) && (
                          //     <div>
                          //         <div className="row pt-2">
                          //             <div className="col-12 my-1">
                          //                 <div className="d-flex justify-content-between">
                          //                     <div className="d-flex">
                          //                         <NavbarBrand role="button" onClick={this.toggle}><img src={ProfileImg} className="timeline-profile-img" alt="Profile" /></NavbarBrand>
                          //                         <p className="timeline-profile-text">Fridayis Ads</p>
                          //                     </div>
                          //                 </div>
                          //             </div>
                          //             <div className="col-12">
                          //                 <AdSenseComponent />
                          //             </div>
                          //         </div>
                          //     </div>
                          // )
                        }
                        {row.TripID !==
                          this.state.data[rowIndex - 1].TripID && (
                          <div className="row">
                            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 pb-0 mb-0">
                              <div className="pt-2">
                                <span
                                  className="anchor"
                                  id={"content-timeline" + row.TripID}
                                ></span>
                                <div className="my-1">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                      {row.ProfileURL ? (
                                        <NavbarBrand
                                          role="button"
                                          onClick={this.openProfile.bind(
                                            this,
                                            row.ProfileID
                                          )}
                                        >
                                          <img
                                            src={row.ProfileURL}
                                            className="timeline-profile-img"
                                            alt="Profile"
                                          />
                                        </NavbarBrand>
                                      ) : (
                                        <NavbarBrand
                                          role="button"
                                          onClick={this.openProfile.bind(
                                            this,
                                            row.ProfileID
                                          )}
                                        >
                                          <img
                                            src={ProfileImg}
                                            className="timeline-profile-img"
                                            alt="Profile"
                                          />
                                        </NavbarBrand>
                                      )}
                                      <p
                                        className="timeline-profile-text"
                                        onClick={this.openProfile.bind(
                                          this,
                                          row.ProfileID
                                        )}
                                      >
                                        {row.Username}
                                        {row.TripDestination &&
                                          " ; " + row.TripDestination}
                                      </p>
                                    </div>
                                    <p className="timeline-profile-date">
                                      {row.DateUpdate &&
                                        new Date(
                                          row.DateUpdate
                                        ).toLocaleDateString("en-GB", {
                                          day: "numeric",
                                          month: "long",
                                          year: "numeric",
                                        })}
                                    </p>
                                    {/* {(row.Joined === 0) ? <button type="button" className="btn btn-primary button-join" onClick={this.joinTrip}>Join</button>
                                                                        :(row.Status) && <p className="status-text">{row.Status}</p>} */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 pmx-sm-md-0 py-0 my-0">
                              {this.state.dataImagesList?.map(
                                (rowImg) =>
                                  row.TripID.toString() ===
                                    rowImg.id.toString() &&
                                  (rowImg.url ? (
                                    rowImg.url.length === 1 ? (
                                      <LazyLoadImage
                                        effect="blur"
                                        fetchpriority="high"
                                        src={rowImg.url[0]}
                                        className="travel-blog-list-400"
                                        width="100%"
                                        alt="Fridayis"
                                        onClick={this.openLightbox.bind(
                                          this,
                                          rowImg.src
                                        )}
                                      />
                                    ) : rowImg.url.length === 2 ? (
                                      <div className="row px-0 mx-0">
                                        <div className="col-6 padding-right-img-1">
                                          <LazyLoadImage
                                            fetchpriority="high"
                                            effect="blur"
                                            src={rowImg.url[0]}
                                            className="travel-blog-list-400"
                                            width="100%"
                                            alt="Fridayis"
                                            onClick={this.openLightbox.bind(
                                              this,
                                              rowImg.src
                                            )}
                                          />
                                        </div>
                                        <div className="col-6 padding-left-img-2">
                                          <LazyLoadImage
                                            fetchpriority="high"
                                            effect="blur"
                                            src={rowImg.url[1]}
                                            className="travel-blog-list-400"
                                            width="100%"
                                            alt="Fridayis"
                                            onClick={this.openLightbox.bind(
                                              this,
                                              rowImg.src
                                            )}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="row px-0 mx-0">
                                        {rowImg.url?.map(
                                          (rowImage, rowIndexImage) =>
                                            rowIndexImage === 0 ? (
                                              <div className="col-8 padding-right-2px">
                                                <LazyLoadImage
                                                  effect="blur"
                                                  fetchpriority="high"
                                                  src={rowImage}
                                                  className="travel-blog-list-400"
                                                  width="100%"
                                                  alt="Fridayis"
                                                  onClick={this.openLightbox.bind(
                                                    this,
                                                    rowImg.src
                                                  )}
                                                />
                                              </div>
                                            ) : rowIndexImage === 1 ? (
                                              <div className="col-4 padding-left-2px">
                                                <LazyLoadImage
                                                  effect="blur"
                                                  fetchpriority="high"
                                                  src={rowImage}
                                                  className="travel-blog-list-200 "
                                                  width="100%"
                                                  alt="Fridayis"
                                                  onClick={this.openLightbox.bind(
                                                    this,
                                                    rowImg.src
                                                  )}
                                                />
                                                <LazyLoadImage
                                                  effect="blur"
                                                  fetchpriority="high"
                                                  src={rowImg.url[2]}
                                                  className="travel-blog-list-200 pt-1"
                                                  width="100%"
                                                  alt="Fridayis"
                                                  onClick={this.openLightbox.bind(
                                                    this,
                                                    rowImg.src
                                                  )}
                                                />
                                              </div>
                                            ) : rowIndexImage === 2 ? (
                                              <div>
                                                <div className="overlay-travel-blog-list-200">
                                                  {rowImg.url.length - 3 > 0 &&
                                                    rowImg.url.length - 3 + "+"}
                                                </div>{" "}
                                                <img
                                                  fetchpriority="low"
                                                  src={rowImage}
                                                  className="d-none"
                                                  width="100%"
                                                  alt="Fridayis"
                                                  onClick={this.openLightbox.bind(
                                                    this,
                                                    rowImg.src
                                                  )}
                                                />
                                              </div>
                                            ) : (
                                              rowIndexImage > 2 && (
                                                <img
                                                  fetchpriority="low"
                                                  src={rowImage}
                                                  className="d-none"
                                                  width="100%"
                                                  alt="Fridayis"
                                                  onClick={this.openLightbox.bind(
                                                    this,
                                                    rowImg.src
                                                  )}
                                                />
                                              )
                                            )
                                        )}
                                      </div>
                                    )
                                  ) : (
                                    <LazyLoadImage
                                      effect="blur"
                                      fetchpriority="high"
                                      src={Fridayisimg}
                                      className="travel-blog-list-400"
                                      width="100%"
                                      alt="Fridayis"
                                      onClick={this.openLightbox.bind(this, [
                                        {
                                          src:
                                            window.location.protocol +
                                            "//" +
                                            window.location.host +
                                            "/fridayis-img.jpg",
                                        },
                                      ])}
                                    />
                                  ))
                              )}
                            </div>

                            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 pt-0 mt-0">
                              <div className="pt-2">
                                <div className="d-flex justify-content-end">
                                  <a href="#" className="link-default mx-2">
                                    <FaRegComment /> 0
                                  </a>
                                  <a href="#" className="link-default mx-2">
                                    <IoMdHeartEmpty /> 0
                                  </a>
                                  <a
                                    href="#"
                                    className="link-default"
                                    onClick={this.openModalShare.bind(
                                      this,
                                      "https://www.fridayis.com?id=" +
                                        row.TripID,
                                      row.TripID
                                    )}
                                    rel="noreferrer"
                                  >
                                    <FiArrowUpRight /> 0
                                  </a>
                                </div>

                                <p className="fw-bold pt-2">{row.TripName}</p>
                                <p
                                  className="trip-description"
                                  id={"tripDescription" + row.TripID}
                                  onClick={this.toggleTripdescriptionHide.bind(
                                    this,
                                    row.TripID
                                  )}
                                >
                                  {row.TripDescription}
                                </p>
                                <p
                                  className="trip-description-hide d-none"
                                  id={"tripDescriptionHide" + row.TripID}
                                  onClick={this.toggleTripdescriptionHide.bind(
                                    this,
                                    row.TripID
                                  )}
                                >
                                  {row.TripDescription}
                                </p>

                                {/*<p>{row.TripID}</p>*/}
                              </div>
                              {row.DayNumber ? (
                                <div>
                                  {/* Invite bar */}
                                  <div className="row mb-2">
                                    <div className="col-4">
                                      <small className="invite-bar-text-small">
                                        Save your date
                                      </small>
                                      <div className="pt-2">
                                        {this.state.dataCloneList?.map(
                                          (rowStartDate) =>
                                            row.TripID.toString() ===
                                              rowStartDate.id.toString() && (
                                              <div>
                                                <DatePicker
                                                  clearIcon={null}
                                                  className="border-0 input-no-outline"
                                                  name="startDate"
                                                  id={"startDate" + row.TripID}
                                                  value={
                                                    rowStartDate.startDate &&
                                                    rowStartDate.startDate.toString()
                                                  }
                                                  onChange={(e) => {
                                                    this.handleChangeDate(
                                                      e,
                                                      row.TripID
                                                    );
                                                  }}
                                                  calendarIcon=""
                                                />

                                                {rowStartDate.startDate ? (
                                                  <div className="date-input-box">
                                                    <FullDate
                                                      date={
                                                        rowStartDate.startDate
                                                      }
                                                      idOnclick={
                                                        "startDate" + row.TripID
                                                      }
                                                    />
                                                  </div>
                                                ) : (
                                                  <div className="date-input-box">
                                                    <FullDate
                                                      date={nextFriday.toDateString()}
                                                      idOnclick={
                                                        "startDate" + row.TripID
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            )
                                        )}
                                      </div>
                                    </div>
                                    {/*<div className="col-5 col-md-4">*/}
                                    {/*    <small>Invite your friend</small>*/}
                                    {/*    <div className="row h-100">*/}
                                    {/*        <div className="col-12 col-md-4">*/}
                                    {/*            {(localStorage.getItem("imageUrl")) ? <span onClick={this.toggleInvite.bind(this, row.TripID)}><img src={localStorage.getItem("imageUrl")} className="invite-profile-img" alt="Profile" /></span> : <span onClick={this.toggleInvite.bind(this, row.TripID)}><img src={ProfileImg} className="invite-profile-img" alt="Profile" /></span>}*/}
                                    {/*            <span className="invite-profile-img-icon" onClick={this.toggleInvite.bind(this, row.TripID)}></span>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="col-12 col-md-8 my-auto">*/}
                                    {/*            {this.state.dataCloneList?.map((rowJoinUser) => ((row.TripID.toString() === rowJoinUser.id.toString()) && ((rowJoinUser.joinUser) && <p className="mx-2">{rowJoinUser.joinUser}</p>)))}*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*/!*onclick add iterary*!/*/}
                                    {/* <div className="col-3 col-md-4 d-flex justify-content-center pt-3">*/}
                                    {/*     <button className="btn button-booking btn-sm">BOOK NOW</button>*/}
                                    {/* </div>*/}
                                    <div className="col-4">
                                      <div className="text-center">
                                        <small className="invite-bar-text-small">
                                          Invite your friend
                                        </small>
                                      </div>
                                      <div className="row h-100">
                                        <div className="col-6 offset-3 col-md-4 offset-md-4">
                                          {localStorage.getItem("imageUrl") ? (
                                            <span
                                              onClick={this.toggleInvite.bind(
                                                this,
                                                row.TripID
                                              )}
                                            >
                                              <img
                                                src={localStorage.getItem(
                                                  "imageUrl"
                                                )}
                                                className="invite-profile-img"
                                                alt="Profile"
                                              />
                                            </span>
                                          ) : (
                                            <span
                                              onClick={this.toggleInvite.bind(
                                                this,
                                                row.TripID
                                              )}
                                            >
                                              <img
                                                src={ProfileImg}
                                                className="invite-profile-img"
                                                alt="Profile"
                                              />
                                            </span>
                                          )}
                                          <span
                                            className="invite-profile-img-icon"
                                            onClick={this.toggleInvite.bind(
                                              this,
                                              row.TripID
                                            )}
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                    {/*onclick add iterary*/}
                                    <div className="col-4 d-flex justify-content-center pt-3">
                                      <button className="btn button-booking btn-sm">
                                        BOOK
                                      </button>
                                    </div>

                                    <div className="col-6 offset-3 mt-2 d-flex justify-content-center">
                                      {this.state.dataCloneList?.map(
                                        (rowJoinUser) =>
                                          row.TripID.toString() ===
                                            rowJoinUser.id.toString() &&
                                          rowJoinUser.joinUser && (
                                            <p className="mx-2">
                                              {rowJoinUser.joinUser}
                                            </p>
                                          )
                                      )}
                                    </div>
                                    {/*<div className="col-4 d-flex justify-content-center pt-3">*/}
                                    {/*    <div className="invite-box-best-deal" onClick={this.openModal}>*/}
                                    {/*        <div className="d-flex justify-content-between">*/}
                                    {/*            <div className="text-best-deal">*/}
                                    {/*                <small className="small">BEST DEAL</small>*/}
                                    {/*                <p className="book">BOOK</p>*/}
                                    {/*            </div>*/}
                                    {/*            <SlArrowRight className="text-black icon-best-deal"/>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div
                                      id={"invite-friend-box" + row.TripID}
                                      className="d-flex justify-content-center mt-3 d-none"
                                    >
                                      <Input
                                        type="email"
                                        className="friend-invite-box"
                                        name="friendInvited"
                                        id="friendInvited"
                                        value={this.state.friendInvited}
                                        onChange={this.handleChange}
                                        onKeyDown={(e) => {
                                          this.handleChangeFriendInvited(
                                            e,
                                            row.TripID
                                          );
                                        }}
                                        placeholder="Invite friend's email"
                                      />
                                    </div>
                                    <div className="text-center mt-3">
                                      <Label
                                        for={"checkFriendInvited" + row.TripID}
                                        style={{
                                          display: "none",
                                          color: "red",
                                        }}
                                        id={"checkFriendInvited" + row.TripID}
                                      >
                                        <BiCommentError /> Please Enter friend's
                                        email
                                      </Label>
                                      <Label
                                        for={
                                          "checkFriendInvitedNotFound" +
                                          row.TripID
                                        }
                                        style={{
                                          display: "none",
                                          color: "red",
                                        }}
                                        id={
                                          "checkFriendInvitedNotFound" +
                                          row.TripID
                                        }
                                      >
                                        <BiCommentError /> Email not found
                                      </Label>
                                      <Label
                                        for={
                                          "checkFriendInvitedSameEmail" +
                                          row.TripID
                                        }
                                        style={{
                                          display: "none",
                                          color: "red",
                                        }}
                                        id={
                                          "checkFriendInvitedSameEmail" +
                                          row.TripID
                                        }
                                      >
                                        <BiCommentError /> Already request
                                        friend's email
                                      </Label>
                                      <Label
                                        for={
                                          "checkFriendInvitedSuccess" +
                                          row.TripID
                                        }
                                        style={{
                                          display: "none",
                                          color: "green",
                                        }}
                                        id={
                                          "checkFriendInvitedSuccess" +
                                          row.TripID
                                        }
                                      >
                                        <TbMailFast /> An invitation email has
                                        been sent to your friend
                                      </Label>
                                      <Label
                                        for={"checkCopySuccess" + row.TripID}
                                        style={{
                                          display: "none",
                                          color: "green",
                                        }}
                                        id={"checkCopySuccess" + row.TripID}
                                      >
                                        <TbClipboardCheck /> Copied!
                                      </Label>
                                    </div>
                                  </div>
                                  {/* Arrow */}
                                  <div className="text-center my-3">
                                    {/*{(this.state.dayListAll) && <IoIosArrowDown style={{"cursor": "pointer"}} onClick={this.toggleTimeline.bind(this, row.TripID)}/>}*/}
                                    {/* <IoIosArrowDown id={"arrow-down"+row.TripID} style={{"cursor": "pointer"}} onClick={this.toggleTimeline.bind(this, row.TripID)}/> */}
                                    <IoIosArrowUp
                                      id={"arrow-up" + row.TripID}
                                      className="d-none"
                                      style={{ cursor: "pointer" }}
                                      onClick={this.toggleTimeline.bind(
                                        this,
                                        row.TripID
                                      )}
                                    />
                                  </div>
                                  {/* Timeeline */}

                                  <div className="mx-3">
                                    {this.state.dayListAll?.map((rowList) => (
                                      <div>
                                        {row.TripID.toString() ===
                                          rowList["id"].toString() && (
                                          <div style={{ overflowX: "auto" }}>
                                            <TabList>
                                              {rowList["data"]?.map(
                                                (rowDay, rowDayIndex) => (
                                                  <Tab>
                                                    <a
                                                      className="day-link"
                                                      href={
                                                        "#" +
                                                        rowList[
                                                          "id"
                                                        ].toString() +
                                                        "day" +
                                                        rowDayIndex
                                                      }
                                                    >
                                                      Day {rowDayIndex + 1}{" "}
                                                    </a>
                                                    {this.state.dataCloneList?.map(
                                                      (rowStartDate) =>
                                                        row.TripID.toString() ===
                                                          rowStartDate.id.toString() &&
                                                        (rowStartDate
                                                          .startDateDate[
                                                          rowDayIndex
                                                        ] ? (
                                                          <p className="date-link ml-minushalf1">
                                                            {rowStartDate
                                                              .startDateDate[
                                                              rowDayIndex
                                                            ].date +
                                                              " " +
                                                              months[
                                                                rowStartDate
                                                                  .startDateDate[
                                                                  rowDayIndex
                                                                ].month
                                                              ]}
                                                          </p>
                                                        ) : (
                                                          <p className="date-link ml-minushalf1">
                                                            {" "}
                                                            {this.getNextFridayFormatted(
                                                              rowDayIndex,
                                                              1
                                                            )}{" "}
                                                          </p>
                                                        ))
                                                    )}
                                                  </Tab>
                                                )
                                              )}
                                            </TabList>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>

                                  {/* Arrow */}
                                  <div className="text-center my-3">
                                    {/*{(this.state.dayListAll) && <IoIosArrowDown style={{"cursor": "pointer"}} onClick={this.toggleTimeline.bind(this, row.TripID)}/>}*/}
                                    <IoIosArrowDown
                                      id={"arrow-down" + row.TripID}
                                      style={{ cursor: "pointer" }}
                                      onClick={this.toggleTimeline.bind(
                                        this,
                                        row.TripID
                                      )}
                                    />
                                    {/* <IoIosArrowUp id={"arrow-up"+row.TripID} className="d-none" style={{"cursor": "pointer"}} onClick={this.toggleTimeline.bind(this, row.TripID)}/> */}
                                  </div>
                                  <div
                                    id={"timeline" + row.TripID}
                                    className="d-none"
                                  >
                                    {this.state.dayListAll?.map(
                                      (rowList) =>
                                        row.TripID.toString() ===
                                          rowList["id"].toString() &&
                                        rowList["data"]?.map(
                                          (rowDay, rowDayIndex) => (
                                            <div className="row">
                                              {rowDayIndex ===
                                              rowList["data"].length - 1 ? (
                                                <div className="line-col col-2">
                                                  <div className="line-timeline-last-child">
                                                    {" "}
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="line-col col-2">
                                                  <div className="line-timeline">
                                                    {" "}
                                                  </div>
                                                </div>
                                              )}

                                              <div className="col-10">
                                                <Row className="day-timeline my-5">
                                                  <Col xs="12" sm="12">
                                                    <span
                                                      className="anchor"
                                                      id={
                                                        rowList[
                                                          "id"
                                                        ].toString() +
                                                        "day" +
                                                        rowDayIndex
                                                      }
                                                    ></span>
                                                    <FormGroup className="mb-4">
                                                      <p className="fw-bold mb-0">
                                                        Day {rowDayIndex + 1}
                                                      </p>
                                                      {this.state.dataCloneList?.map(
                                                        (rowStartDate) =>
                                                          row.TripID.toString() ===
                                                            rowStartDate.id.toString() &&
                                                          (rowStartDate
                                                            .startDateDate[
                                                            rowDayIndex
                                                          ] ? (
                                                            <p className="date-link">
                                                              {rowStartDate
                                                                .startDateDate[
                                                                rowDayIndex
                                                              ].date +
                                                                " " +
                                                                months[
                                                                  rowStartDate
                                                                    .startDateDate[
                                                                    rowDayIndex
                                                                  ].month
                                                                ] +
                                                                " " +
                                                                rowStartDate
                                                                  .startDateDate[
                                                                  rowDayIndex
                                                                ].year}
                                                            </p>
                                                          ) : (
                                                            <p className="date-link">
                                                              {" "}
                                                              {this.getNextFridayFormatted(
                                                                rowDayIndex,
                                                                2
                                                              )}{" "}
                                                            </p>
                                                          ))
                                                      )}
                                                    </FormGroup>
                                                    {rowDay[9] && (
                                                      <p>{rowDay[9]}</p>
                                                    )}
                                                    {rowDay[7].length > 0 && (
                                                      <div className="show-day-img overflow-auto d-flex mb-3">
                                                        {rowDay[7]?.map(
                                                          (
                                                            fileImageDay,
                                                            indexImageDay
                                                          ) => (
                                                            <LazyLoadImage
                                                              effect="blur"
                                                              fetchpriority="low"
                                                              src={fileImageDay}
                                                              className="show-img-preview"
                                                              alt={
                                                                "Day " +
                                                                (rowDayIndex +
                                                                  1) +
                                                                " image " +
                                                                (indexImageDay +
                                                                  1)
                                                              }
                                                              onClick={this.openLightbox.bind(
                                                                this,
                                                                rowDay[8]
                                                              )}
                                                            />
                                                          )
                                                        )}
                                                      </div>
                                                    )}
                                                    <div className="overflow-auto">
                                                      {ReactHtmlParser(
                                                        rowDay[1]
                                                      )}
                                                    </div>

                                                    <div className="row mb-3">
                                                      {rowDay[2] && (
                                                        <div
                                                          id={
                                                            "flightIcon" +
                                                            row.TripID +
                                                            "" +
                                                            (rowDayIndex + 1) +
                                                            "1"
                                                          }
                                                          className="col-3 mb-3"
                                                        >
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <img
                                                                src={
                                                                  window
                                                                    .location
                                                                    .protocol +
                                                                  "//" +
                                                                  window
                                                                    .location
                                                                    .host +
                                                                  "/icon/airplane.png"
                                                                }
                                                                className="icon-input-before"
                                                                name="linkFlightButton"
                                                                id="linkFlightButton"
                                                                onClick={this.clickLinkFlight.bind(
                                                                  this,
                                                                  row.TripID +
                                                                    "" +
                                                                    (rowDayIndex +
                                                                      1) +
                                                                    "1"
                                                                )}
                                                                alt="Airplane"
                                                              />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkFlight"
                                                              id="linkFlight"
                                                              className="form-control input-link-before"
                                                              value={
                                                                row[10]
                                                                  ? row[10]
                                                                  : "Not specified"
                                                              }
                                                              aria-label="linkFlight"
                                                              aria-describedby="linkFlightButton"
                                                            />
                                                          </div>
                                                        </div>
                                                      )}
                                                      {rowDay[2] && (
                                                        <div
                                                          id={
                                                            "flightInputBox" +
                                                            row.TripID +
                                                            "" +
                                                            (rowDayIndex + 1) +
                                                            "1"
                                                          }
                                                          className="col-12 mb-3 d-none"
                                                        >
                                                          <div className="row">
                                                            <div className="col-8">
                                                              <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                  <img
                                                                    src={
                                                                      window
                                                                        .location
                                                                        .protocol +
                                                                      "//" +
                                                                      window
                                                                        .location
                                                                        .host +
                                                                      "/icon/airplane.png"
                                                                    }
                                                                    className="icon-input-update"
                                                                    name="linkFlightButton"
                                                                    id="linkFlightButton"
                                                                    onClick={this.clickLinkFlight.bind(
                                                                      this,
                                                                      row.TripID +
                                                                        "" +
                                                                        (rowDayIndex +
                                                                          1) +
                                                                        "1"
                                                                    )}
                                                                    alt="Motor"
                                                                  />
                                                                </div>
                                                                <Input
                                                                  type="text"
                                                                  name="linkFlight"
                                                                  id="linkFlight"
                                                                  className="form-control input-link-update"
                                                                  value={
                                                                    rowDay[2]
                                                                  }
                                                                  aria-label="linkFlight"
                                                                  aria-describedby="linkFlightButton"
                                                                />
                                                              </div>
                                                            </div>
                                                            <div className="col-4">
                                                              <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                  <FaMoneyBillWaveAlt className="icon-input-update-currency" />
                                                                </div>
                                                                <Input
                                                                  type="text"
                                                                  name="linkFlightPrice"
                                                                  id="linkFlightPrice"
                                                                  className="form-control input-link-update"
                                                                  value={
                                                                    row[10]
                                                                      ? row[10]
                                                                      : "Not specified"
                                                                  }
                                                                  aria-label="linkFlightPrice"
                                                                  aria-describedby="linkFlightPriceButton"
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                      {rowDay[3] && (
                                                        <div
                                                          id={
                                                            "hotelIcon" +
                                                            row.TripID +
                                                            "" +
                                                            (rowDayIndex + 1) +
                                                            "2"
                                                          }
                                                          className="col-3 mb-3"
                                                        >
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <img
                                                                src={
                                                                  window
                                                                    .location
                                                                    .protocol +
                                                                  "//" +
                                                                  window
                                                                    .location
                                                                    .host +
                                                                  "/icon/double-bed.png"
                                                                }
                                                                className="icon-input-before"
                                                                name="linkHotelButton"
                                                                id="linkHotelButton"
                                                                onClick={this.clickLinkHotel.bind(
                                                                  this,
                                                                  row.TripID +
                                                                    "" +
                                                                    (rowDayIndex +
                                                                      1) +
                                                                    "2"
                                                                )}
                                                                alt="Hotel"
                                                              />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkHotel"
                                                              id="linkHotel"
                                                              className="form-control input-link-before"
                                                              value={
                                                                row[11]
                                                                  ? row[11]
                                                                  : "Not specified"
                                                              }
                                                              aria-label="linkHotel"
                                                              aria-describedby="linkHotelButton"
                                                            />
                                                          </div>
                                                        </div>
                                                      )}
                                                      {rowDay[3] && (
                                                        <div
                                                          id={
                                                            "hotelInputBox" +
                                                            row.TripID +
                                                            "" +
                                                            (rowDayIndex + 1) +
                                                            "2"
                                                          }
                                                          className="col-12 mb-3 d-none"
                                                        >
                                                          <div className="row">
                                                            <div className="col-8">
                                                              <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                  <img
                                                                    src={
                                                                      window
                                                                        .location
                                                                        .protocol +
                                                                      "//" +
                                                                      window
                                                                        .location
                                                                        .host +
                                                                      "/icon/double-bed.png"
                                                                    }
                                                                    className="icon-input-update"
                                                                    name="linkHotelButton"
                                                                    id="linkHotelButton"
                                                                    onClick={this.clickLinkHotel.bind(
                                                                      this,
                                                                      row.TripID +
                                                                        "" +
                                                                        (rowDayIndex +
                                                                          1) +
                                                                        "2"
                                                                    )}
                                                                    alt="Motor"
                                                                  />
                                                                </div>
                                                                <Input
                                                                  type="text"
                                                                  name="linkHotel"
                                                                  id="linkHotel"
                                                                  className="form-control input-link-update"
                                                                  value={
                                                                    rowDay[3]
                                                                  }
                                                                  aria-label="linkHotel"
                                                                  aria-describedby="linkHotelButton"
                                                                />
                                                              </div>
                                                            </div>
                                                            <div className="col-4">
                                                              <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                  <FaMoneyBillWaveAlt className="icon-input-update-currency" />
                                                                </div>
                                                                <Input
                                                                  type="text"
                                                                  name="linkHotelPrice"
                                                                  id="linkHotelPrice"
                                                                  className="form-control input-link-update"
                                                                  value={
                                                                    row[11]
                                                                      ? row[11]
                                                                      : "Not specified"
                                                                  }
                                                                  aria-label="linkHotelPrice"
                                                                  aria-describedby="linkHotelPriceButton"
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                      {rowDay[4] && (
                                                        <div
                                                          id={
                                                            "carIcon" +
                                                            row.TripID +
                                                            "" +
                                                            (rowDayIndex + 1) +
                                                            "3"
                                                          }
                                                          className="col-3 mb-3"
                                                        >
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <img
                                                                src={
                                                                  window
                                                                    .location
                                                                    .protocol +
                                                                  "//" +
                                                                  window
                                                                    .location
                                                                    .host +
                                                                  "/icon/sedan.png"
                                                                }
                                                                className="icon-input-before"
                                                                name="linkCarButton"
                                                                id="linkCarButton"
                                                                onClick={this.clickLinkCar.bind(
                                                                  this,
                                                                  row.TripID +
                                                                    "" +
                                                                    (rowDayIndex +
                                                                      1) +
                                                                    "3"
                                                                )}
                                                                alt="Car"
                                                              />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkCar"
                                                              id="linkCar"
                                                              className="form-control input-link-before"
                                                              value={
                                                                row[12]
                                                                  ? row[12]
                                                                  : "Not specified"
                                                              }
                                                              aria-label="linkCar"
                                                              aria-describedby="linkCarButton"
                                                            />
                                                          </div>
                                                        </div>
                                                      )}
                                                      {rowDay[4] && (
                                                        <div
                                                          id={
                                                            "carInputBox" +
                                                            row.TripID +
                                                            "" +
                                                            (rowDayIndex + 1) +
                                                            "3"
                                                          }
                                                          className="col-12 mb-3 d-none"
                                                        >
                                                          <div className="row">
                                                            <div className="col-8">
                                                              <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                  <img
                                                                    src={
                                                                      window
                                                                        .location
                                                                        .protocol +
                                                                      "//" +
                                                                      window
                                                                        .location
                                                                        .host +
                                                                      "/icon/sedan.png"
                                                                    }
                                                                    className="icon-input-update"
                                                                    name="linkCarButton"
                                                                    id="linkCarButton"
                                                                    onClick={this.clickLinkCar.bind(
                                                                      this,
                                                                      row.TripID +
                                                                        "" +
                                                                        (rowDayIndex +
                                                                          1) +
                                                                        "3"
                                                                    )}
                                                                    alt="Motor"
                                                                  />
                                                                </div>
                                                                <Input
                                                                  type="text"
                                                                  name="linkCar"
                                                                  id="linkCar"
                                                                  className="form-control input-link-update"
                                                                  value={
                                                                    rowDay[4]
                                                                  }
                                                                  aria-label="linkCar"
                                                                  aria-describedby="linkCarButton"
                                                                />
                                                              </div>
                                                            </div>
                                                            <div className="col-4">
                                                              <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                  <FaMoneyBillWaveAlt className="icon-input-update-currency" />
                                                                </div>
                                                                <Input
                                                                  type="text"
                                                                  name="linkCarPrice"
                                                                  id="linkCarPrice"
                                                                  className="form-control input-link-update"
                                                                  value={
                                                                    row[12]
                                                                      ? row[12]
                                                                      : "Not specified"
                                                                  }
                                                                  aria-label="linkCarPrice"
                                                                  aria-describedby="linkCarPriceButton"
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                      {rowDay[6] && (
                                                        <div
                                                          id={
                                                            "motorIcon" +
                                                            row.TripID +
                                                            "" +
                                                            (rowDayIndex + 1) +
                                                            "5"
                                                          }
                                                          className="col-3 mb-3 "
                                                        >
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <img
                                                                src={
                                                                  window
                                                                    .location
                                                                    .protocol +
                                                                  "//" +
                                                                  window
                                                                    .location
                                                                    .host +
                                                                  "/icon/motorbike.png"
                                                                }
                                                                className="icon-input-before"
                                                                name="linkMotorButton"
                                                                id="linkMotorButton"
                                                                onClick={this.clickLinkMotor.bind(
                                                                  this,
                                                                  row.TripID +
                                                                    "" +
                                                                    (rowDayIndex +
                                                                      1) +
                                                                    "5"
                                                                )}
                                                                alt="Motor"
                                                              />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkMotor"
                                                              id="linkMotor"
                                                              className="form-control input-link-before"
                                                              value={
                                                                row[14]
                                                                  ? row[14]
                                                                  : "Not specified"
                                                              }
                                                              aria-label="linkMotor"
                                                              aria-describedby="linkMotorButton"
                                                            />
                                                          </div>
                                                        </div>
                                                      )}
                                                      {rowDay[6] && (
                                                        <div
                                                          id={
                                                            "motorInputBox" +
                                                            row.TripID +
                                                            "" +
                                                            (rowDayIndex + 1) +
                                                            "5"
                                                          }
                                                          className="col-12 mb-3 d-none"
                                                        >
                                                          <div className="row">
                                                            <div className="col-8">
                                                              <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                  <img
                                                                    src={
                                                                      window
                                                                        .location
                                                                        .protocol +
                                                                      "//" +
                                                                      window
                                                                        .location
                                                                        .host +
                                                                      "/icon/motorbike.png"
                                                                    }
                                                                    className="icon-input-update"
                                                                    name="linkMotorButton"
                                                                    id="linkMotorButton"
                                                                    onClick={this.clickLinkMotor.bind(
                                                                      this,
                                                                      row.TripID +
                                                                        "" +
                                                                        (rowDayIndex +
                                                                          1) +
                                                                        "5"
                                                                    )}
                                                                    alt="Motor"
                                                                  />
                                                                </div>
                                                                <Input
                                                                  type="text"
                                                                  name="linkMotor"
                                                                  id="linkMotor"
                                                                  className="form-control input-link-update"
                                                                  value={
                                                                    rowDay[6]
                                                                  }
                                                                  aria-label="linkMotor"
                                                                  aria-describedby="linkMotorButton"
                                                                />
                                                              </div>
                                                            </div>
                                                            <div className="col-4">
                                                              <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                  <FaMoneyBillWaveAlt className="icon-input-update-currency" />
                                                                </div>
                                                                <Input
                                                                  type="text"
                                                                  name="linkMotorPrice"
                                                                  id="linkMotorPrice"
                                                                  className="form-control input-link-update"
                                                                  value={
                                                                    row[14]
                                                                      ? row[14]
                                                                      : "Not specified"
                                                                  }
                                                                  aria-label="linkMotorPrice"
                                                                  aria-describedby="linkMotorPriceButton"
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </div>
                                          )
                                        )
                                    )}
                                    <div className="text-center my-3">
                                      <IoIosArrowUp
                                        style={{ cursor: "pointer" }}
                                        onClick={this.toggleTimelineUp.bind(
                                          this,
                                          row.TripID
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="my-5"></div>
                              )}
                              {/* <hr className="line-content mx-auto" /> */}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 pb-0 mb-0">
                          <div className="pt-2">
                            <span
                              className="anchor"
                              id={"content-timeline" + row.TripID}
                            ></span>
                            <div className="my-1">
                              <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                  {row.ProfileURL ? (
                                    <NavbarBrand
                                      role="button"
                                      onClick={this.openProfile.bind(
                                        this,
                                        row.ProfileID
                                      )}
                                    >
                                      <img
                                        src={row.ProfileURL}
                                        className="timeline-profile-img"
                                        alt="Profile"
                                      />
                                    </NavbarBrand>
                                  ) : (
                                    <NavbarBrand
                                      role="button"
                                      onClick={this.openProfile.bind(
                                        this,
                                        row.ProfileID
                                      )}
                                    >
                                      <img
                                        src={ProfileImg}
                                        className="timeline-profile-img"
                                        alt="Profile"
                                      />
                                    </NavbarBrand>
                                  )}
                                  <p
                                    className="timeline-profile-text"
                                    onClick={this.openProfile.bind(
                                      this,
                                      row.ProfileID
                                    )}
                                  >
                                    {row.Username}
                                    {row.TripDestination &&
                                      " ; " + row.TripDestination}
                                  </p>
                                </div>
                                <p className="timeline-profile-date">
                                  {row.DateUpdate &&
                                    new Date(row.DateUpdate).toLocaleDateString(
                                      "en-GB",
                                      {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                      }
                                    )}
                                </p>
                                {/* {(row.Joined === 0) ? <button type="button" className="btn btn-primary button-join" onClick={this.joinTrip}>Join</button>
                                                                :(row.Status) && <p className="status-text">{row.Status}</p>} */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 pmx-sm-md-0 py-0 my-0">
                          {this.state.dataImagesList?.map(
                            (rowImg) =>
                              row.TripID.toString() === rowImg.id.toString() &&
                              (rowImg.url ? (
                                rowImg.url.length === 1 ? (
                                  <LazyLoadImage
                                    effect="blur"
                                    fetchpriority="high"
                                    src={rowImg.url[0]}
                                    className="travel-blog-list-400"
                                    width="100%"
                                    alt="Fridayis"
                                    onClick={this.openLightbox.bind(
                                      this,
                                      rowImg.src
                                    )}
                                  />
                                ) : rowImg.url.length === 2 ? (
                                  <div className="row px-0 mx-0">
                                    <div className="col-6 padding-right-img-1">
                                      <LazyLoadImage
                                        fetchpriority="high"
                                        effect="blur"
                                        src={rowImg.url[0]}
                                        className="travel-blog-list-400"
                                        width="100%"
                                        alt="Fridayis"
                                        onClick={this.openLightbox.bind(
                                          this,
                                          rowImg.src
                                        )}
                                      />
                                    </div>
                                    <div className="col-6 padding-left-img-2">
                                      <LazyLoadImage
                                        fetchpriority="high"
                                        effect="blur"
                                        src={rowImg.url[1]}
                                        className="travel-blog-list-400"
                                        width="100%"
                                        alt="Fridayis"
                                        onClick={this.openLightbox.bind(
                                          this,
                                          rowImg.src
                                        )}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row px-0 mx-0">
                                    {rowImg.url?.map(
                                      (rowImage, rowIndexImage) =>
                                        rowIndexImage === 0 ? (
                                          <div className="col-8 padding-right-2px">
                                            <LazyLoadImage
                                              effect="blur"
                                              fetchpriority="high"
                                              src={rowImage}
                                              className="travel-blog-list-400"
                                              width="100%"
                                              alt="Fridayis"
                                              onClick={this.openLightbox.bind(
                                                this,
                                                rowImg.src
                                              )}
                                            />
                                          </div>
                                        ) : rowIndexImage === 1 ? (
                                          <div className="col-4 padding-left-2px">
                                            <LazyLoadImage
                                              effect="blur"
                                              fetchpriority="high"
                                              src={rowImage}
                                              className="travel-blog-list-200 "
                                              width="100%"
                                              alt="Fridayis"
                                              onClick={this.openLightbox.bind(
                                                this,
                                                rowImg.src
                                              )}
                                            />
                                            <LazyLoadImage
                                              effect="blur"
                                              fetchpriority="high"
                                              src={rowImg.url[2]}
                                              className="travel-blog-list-200 pt-1"
                                              width="100%"
                                              alt="Fridayis"
                                              onClick={this.openLightbox.bind(
                                                this,
                                                rowImg.src
                                              )}
                                            />
                                          </div>
                                        ) : rowIndexImage === 2 ? (
                                          <div>
                                            <div className="overlay-travel-blog-list-200">
                                              {rowImg.url.length - 3 > 0 &&
                                                rowImg.url.length - 3 + "+"}
                                            </div>{" "}
                                            <img
                                              fetchpriority="low"
                                              src={rowImage}
                                              className="d-none"
                                              width="100%"
                                              alt="Fridayis"
                                              onClick={this.openLightbox.bind(
                                                this,
                                                rowImg.src
                                              )}
                                            />
                                          </div>
                                        ) : (
                                          rowIndexImage > 2 && (
                                            <img
                                              fetchpriority="low"
                                              src={rowImage}
                                              className="d-none"
                                              width="100%"
                                              alt="Fridayis"
                                              onClick={this.openLightbox.bind(
                                                this,
                                                rowImg.src
                                              )}
                                            />
                                          )
                                        )
                                    )}
                                  </div>
                                )
                              ) : (
                                <LazyLoadImage
                                  effect="blur"
                                  fetchpriority="high"
                                  src={Fridayisimg}
                                  className="travel-blog-list-400"
                                  width="100%"
                                  alt="Fridayis"
                                  onClick={this.openLightbox.bind(this, [
                                    {
                                      src:
                                        window.location.protocol +
                                        "//" +
                                        window.location.host +
                                        "/fridayis-img.jpg",
                                    },
                                  ])}
                                />
                              ))
                          )}
                        </div>

                        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 pt-0 mt-0">
                          <div className="pt-2">
                            <div className="d-flex justify-content-end">
                              <a href="#" className="link-default mx-2">
                                <FaRegComment /> 0
                              </a>
                              <a href="#" className="link-default mx-2">
                                <IoMdHeartEmpty /> 0
                              </a>
                              <a
                                href="#"
                                className="link-default"
                                onClick={this.openModalShare.bind(
                                  this,
                                  "https://www.fridayis.com?id=" + row.TripID,
                                  row.TripID
                                )}
                                rel="noreferrer"
                              >
                                <FiArrowUpRight /> 0
                              </a>
                            </div>

                            <p className="fw-bold pt-2">{row.TripName}</p>
                            <p
                              className="trip-description"
                              id={"tripDescription" + row.TripID}
                              onClick={this.toggleTripdescriptionHide.bind(
                                this,
                                row.TripID
                              )}
                            >
                              {row.TripDescription}
                            </p>
                            <p
                              className="trip-description-hide d-none"
                              id={"tripDescriptionHide" + row.TripID}
                              onClick={this.toggleTripdescriptionHide.bind(
                                this,
                                row.TripID
                              )}
                            >
                              {row.TripDescription}
                            </p>
                            {/*<p>{row.TripID}</p>*/}
                          </div>

                          {row.DayNumber ? (
                            <div>
                              {/* Invite bar */}
                              <div className="row mb-2">
                                <div className="col-4">
                                  <small className="invite-bar-text-small">
                                    Save your date
                                  </small>
                                  <div className="pt-2">
                                    {this.state.dataCloneList?.map(
                                      (rowStartDate) =>
                                        row.TripID.toString() ===
                                          rowStartDate.id.toString() && (
                                          <div>
                                            <DatePicker
                                              clearIcon={null}
                                              className="border-0 input-no-outline"
                                              name="startDate"
                                              id={"startDate" + row.TripID}
                                              value={
                                                rowStartDate.startDate &&
                                                rowStartDate.startDate.toString()
                                              }
                                              onChange={(e) => {
                                                this.handleChangeDate(
                                                  e,
                                                  row.TripID
                                                );
                                              }}
                                              calendarIcon=""
                                            />

                                            {rowStartDate.startDate ? (
                                              <div className="date-input-box">
                                                <FullDate
                                                  date={rowStartDate.startDate}
                                                  idOnclick={
                                                    "startDate" + row.TripID
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              <div className="date-input-box">
                                                <FullDate
                                                  date={nextFriday.toDateString()}
                                                  idOnclick={
                                                    "startDate" + row.TripID
                                                  }
                                                />
                                              </div>
                                            )}
                                          </div>
                                        )
                                    )}
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="text-center">
                                    <small className="invite-bar-text-small">
                                      Invite your friend
                                    </small>
                                  </div>
                                  <div className="row h-100">
                                    <div className="col-6 offset-3 col-md-4 offset-md-4">
                                      {localStorage.getItem("imageUrl") ? (
                                        <span
                                          onClick={this.toggleInvite.bind(
                                            this,
                                            row.TripID
                                          )}
                                        >
                                          <img
                                            src={localStorage.getItem(
                                              "imageUrl"
                                            )}
                                            className="invite-profile-img"
                                            alt="Profile"
                                          />
                                        </span>
                                      ) : (
                                        <span
                                          onClick={this.toggleInvite.bind(
                                            this,
                                            row.TripID
                                          )}
                                        >
                                          <img
                                            src={ProfileImg}
                                            className="invite-profile-img"
                                            alt="Profile"
                                          />
                                        </span>
                                      )}
                                      <span
                                        className="invite-profile-img-icon"
                                        onClick={this.toggleInvite.bind(
                                          this,
                                          row.TripID
                                        )}
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                                {/*onclick add iterary*/}
                                <div className="col-4 d-flex justify-content-center pt-3">
                                  <button className="btn button-booking btn-sm">
                                    BOOK
                                  </button>
                                </div>

                                <div className="col-6 offset-3 mt-2 d-flex justify-content-center">
                                  {this.state.dataCloneList?.map(
                                    (rowJoinUser) =>
                                      row.TripID.toString() ===
                                        rowJoinUser.id.toString() &&
                                      rowJoinUser.joinUser && (
                                        <p className="mx-2">
                                          {rowJoinUser.joinUser}
                                        </p>
                                      )
                                  )}
                                </div>
                                {/*<div className="col-5 col-md-4">*/}
                                {/*    <small>Invite your friend</small>*/}
                                {/*    <div className="row h-100">*/}
                                {/*        <div className="col-12 col-md-4">*/}
                                {/*            {(localStorage.getItem("imageUrl")) ? <span onClick={this.toggleInvite.bind(this, row.TripID)}><img src={localStorage.getItem("imageUrl")} className="invite-profile-img" alt="Profile" /></span> : <span onClick={this.toggleInvite.bind(this, row.TripID)}><img src={ProfileImg} className="invite-profile-img" alt="Profile" /></span>}*/}
                                {/*            <span className="invite-profile-img-icon" onClick={this.toggleInvite.bind(this, row.TripID)}></span>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-12 col-md-8 my-auto">*/}
                                {/*            {this.state.dataCloneList?.map((rowJoinUser) => ((row.TripID.toString() === rowJoinUser.id.toString()) && ((rowJoinUser.joinUser) && <p className="mx-2">{rowJoinUser.joinUser}</p>)))}*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="col-3 col-md-4 d-flex justify-content-center pt-3">*/}
                                {/*    <button className="btn button-booking btn-sm">BOOK NOW</button>*/}
                                {/*</div>*/}
                                {/*<div className="col-4 d-flex justify-content-center pt-3">*/}
                                {/*    <div className="invite-box-best-deal" onClick={this.openModal}>*/}
                                {/*        <div className="d-flex justify-content-between">*/}
                                {/*            <div className="text-best-deal">*/}
                                {/*                <small className="small">BEST DEAL</small>*/}
                                {/*                <p className="book">BOOK</p>*/}
                                {/*            </div>*/}
                                {/*            <SlArrowRight className="text-black icon-best-deal"/>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div
                                  id={"invite-friend-box" + row.TripID}
                                  className="d-flex justify-content-center mt-3 d-none"
                                >
                                  <Input
                                    type="email"
                                    className="friend-invite-box"
                                    name="friendInvited"
                                    id="friendInvited"
                                    value={this.state.friendInvited}
                                    onChange={this.handleChange}
                                    onKeyDown={(e) => {
                                      this.handleChangeFriendInvited(
                                        e,
                                        row.TripID
                                      );
                                    }}
                                    placeholder="Invite friend's email"
                                  />
                                </div>
                                <div className="text-center mt-3">
                                  <Label
                                    for={"checkFriendInvited" + row.TripID}
                                    style={{ display: "none", color: "red" }}
                                    id={"checkFriendInvited" + row.TripID}
                                  >
                                    <BiCommentError /> Please Enter friend's
                                    email
                                  </Label>
                                  <Label
                                    for={
                                      "checkFriendInvitedNotFound" + row.TripID
                                    }
                                    style={{ display: "none", color: "red" }}
                                    id={
                                      "checkFriendInvitedNotFound" + row.TripID
                                    }
                                  >
                                    <BiCommentError /> Email not found
                                  </Label>
                                  <Label
                                    for={
                                      "checkFriendInvitedSameEmail" + row.TripID
                                    }
                                    style={{ display: "none", color: "red" }}
                                    id={
                                      "checkFriendInvitedSameEmail" + row.TripID
                                    }
                                  >
                                    <BiCommentError /> Already request friend's
                                    email
                                  </Label>
                                  <Label
                                    for={
                                      "checkFriendInvitedSuccess" + row.TripID
                                    }
                                    style={{ display: "none", color: "green" }}
                                    id={
                                      "checkFriendInvitedSuccess" + row.TripID
                                    }
                                  >
                                    <TbMailFast /> An invitation email has been
                                    sent to your friend
                                  </Label>
                                  <Label
                                    for={"checkCopySuccess" + row.TripID}
                                    style={{ display: "none", color: "green" }}
                                    id={"checkCopySuccess" + row.TripID}
                                  >
                                    <TbClipboardCheck /> Copied!
                                  </Label>
                                </div>
                              </div>
                              {/* Arrow*/}
                              <div className="text-center my-3">
                                {/*{(this.state.dayListAll) && <IoIosArrowDown style={{"cursor": "pointer"}} onClick={this.toggleTimeline.bind(this, row.TripID)}/>}*/}
                                {/* <IoIosArrowDown id={"arrow-down"+row.TripID} style={{"cursor": "pointer"}} onClick={this.toggleTimeline.bind(this, row.TripID)}/> */}
                                <IoIosArrowUp
                                  id={"arrow-up" + row.TripID}
                                  className="d-none"
                                  style={{ cursor: "pointer" }}
                                  onClick={this.toggleTimeline.bind(
                                    this,
                                    row.TripID
                                  )}
                                />
                              </div>

                              {/* Timeline*/}

                              <div className="mx-3">
                                {this.state.dayListAll?.map((rowList) => (
                                  <div>
                                    {row.TripID.toString() ===
                                      rowList["id"].toString() && (
                                      <div style={{ overflowX: "auto" }}>
                                        <TabList>
                                          {rowList["data"]?.map(
                                            (rowDay, rowDayIndex) => (
                                              <Tab>
                                                <a
                                                  className="day-link"
                                                  href={
                                                    "#" +
                                                    rowList["id"].toString() +
                                                    "day" +
                                                    rowDayIndex
                                                  }
                                                >
                                                  Day {rowDayIndex + 1}{" "}
                                                </a>
                                                {this.state.dataCloneList?.map(
                                                  (rowStartDate) =>
                                                    row.TripID.toString() ===
                                                      rowStartDate.id.toString() &&
                                                    (rowStartDate.startDateDate[
                                                      rowDayIndex
                                                    ] ? (
                                                      <p className="date-link ml-minushalf1">
                                                        {rowStartDate
                                                          .startDateDate[
                                                          rowDayIndex
                                                        ].date +
                                                          " " +
                                                          months[
                                                            rowStartDate
                                                              .startDateDate[
                                                              rowDayIndex
                                                            ].month
                                                          ]}
                                                      </p>
                                                    ) : (
                                                      <p className="date-link ml-minushalf1">
                                                        {" "}
                                                        {this.getNextFridayFormatted(
                                                          rowDayIndex,
                                                          1
                                                        )}{" "}
                                                      </p>
                                                    ))
                                                )}
                                              </Tab>
                                            )
                                          )}
                                        </TabList>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                              {/* Arrow */}
                              <div className="text-center my-3">
                                {/*{(this.state.dayListAll) && <IoIosArrowDown style={{"cursor": "pointer"}} onClick={this.toggleTimeline.bind(this, row.TripID)}/>}*/}
                                <IoIosArrowDown
                                  id={"arrow-down" + row.TripID}
                                  style={{ cursor: "pointer" }}
                                  onClick={this.toggleTimeline.bind(
                                    this,
                                    row.TripID
                                  )}
                                />
                                {/* <IoIosArrowUp id={"arrow-up"+row.TripID} className="d-none" style={{"cursor": "pointer"}} onClick={this.toggleTimeline.bind(this, row.TripID)}/> */}
                              </div>
                              <div
                                id={"timeline" + row.TripID}
                                className="d-none"
                              >
                                {this.state.dayListAll?.map(
                                  (rowList) =>
                                    row.TripID.toString() ===
                                      rowList["id"].toString() &&
                                    rowList["data"]?.map(
                                      (rowDay, rowDayIndex) => (
                                        <div className="row">
                                          {rowDayIndex ===
                                          rowList["data"].length - 1 ? (
                                            <div className="line-col col-2">
                                              <div className="line-timeline-last-child">
                                                {" "}
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="line-col col-2">
                                              <div className="line-timeline">
                                                {" "}
                                              </div>
                                            </div>
                                          )}
                                          <div className="col-10">
                                            <Row className="day-timeline my-5">
                                              <Col xs="12" sm="12">
                                                <span
                                                  className="anchor"
                                                  id={
                                                    rowList["id"].toString() +
                                                    "day" +
                                                    rowDayIndex
                                                  }
                                                ></span>
                                                <FormGroup className="mb-4">
                                                  <p className="fw-bold mb-0">
                                                    Day {rowDayIndex + 1}
                                                  </p>
                                                  {this.state.dataCloneList?.map(
                                                    (rowStartDate) =>
                                                      row.TripID.toString() ===
                                                        rowStartDate.id.toString() &&
                                                      (rowStartDate
                                                        .startDateDate[
                                                        rowDayIndex
                                                      ] ? (
                                                        <p className="date-link">
                                                          {rowStartDate
                                                            .startDateDate[
                                                            rowDayIndex
                                                          ].date +
                                                            " " +
                                                            months[
                                                              rowStartDate
                                                                .startDateDate[
                                                                rowDayIndex
                                                              ].month
                                                            ] +
                                                            " " +
                                                            rowStartDate
                                                              .startDateDate[
                                                              rowDayIndex
                                                            ].year}
                                                        </p>
                                                      ) : (
                                                        <p className="date-link">
                                                          {" "}
                                                          {this.getNextFridayFormatted(
                                                            rowDayIndex,
                                                            2
                                                          )}{" "}
                                                        </p>
                                                      ))
                                                  )}
                                                </FormGroup>
                                                {rowDay[9] && (
                                                  <p>{rowDay[9]}</p>
                                                )}
                                                {rowDay[7].length > 0 && (
                                                  <div className="show-day-img overflow-auto d-flex mb-3">
                                                    {rowDay[7]?.map(
                                                      (
                                                        fileImageDay,
                                                        indexImageDay
                                                      ) => (
                                                        <LazyLoadImage
                                                          effect="blur"
                                                          fetchpriority="low"
                                                          src={fileImageDay}
                                                          className="show-img-preview"
                                                          alt={
                                                            "Day " +
                                                            (rowDayIndex + 1) +
                                                            " image " +
                                                            (indexImageDay + 1)
                                                          }
                                                          onClick={this.openLightbox.bind(
                                                            this,
                                                            rowDay[8]
                                                          )}
                                                        />
                                                      )
                                                    )}
                                                  </div>
                                                )}
                                                {/*<div className="show-day-img overflow-auto d-flex mb-3">*/}

                                                {/*{(rowDay[7] && rowDay[8]) ? <LazyLoadImage effect="blur" fetchpriority="low" src={rowDay[7]} className="show-img-preview" alt={'Day '+(rowIndex+1)+' image 1'} onClick={this.openLightbox.bind(this, [{src: rowDay[7]},{src: rowDay[8]}])} />*/}
                                                {/*    : (rowDay[7]) && <LazyLoadImage effect="blur" fetchpriority="low" src={rowDay[7]} className="show-img-preview" alt={'Day '+(rowIndex+1)+' image 1'} onClick={this.openLightbox.bind(this, [{src: rowDay[7]}])} />}*/}

                                                {/*{(rowDay[7] && rowDay[8]) ? <LazyLoadImage effect="blur" fetchpriority="low" src={rowDay[8]} className="show-img-preview" alt={'Day '+(rowIndex+1)+' image 1'} onClick={this.openLightbox.bind(this, [{src: rowDay[8]},{src: rowDay[7]}])} />*/}
                                                {/*    : (rowDay[8]) && <LazyLoadImage effect="blur" fetchpriority="low" src={rowDay[8]} className="show-img-preview" alt={'Day '+(rowIndex+1)+' image 2'} onClick={this.openLightbox.bind(this, [{src: rowDay[8]}])} />}*/}
                                                {/*</div>*/}
                                                <div className="overflow-auto">
                                                  {ReactHtmlParser(rowDay[1])}
                                                </div>
                                                <div className="row mb-3">
                                                  {rowDay[2] && (
                                                    <div
                                                      id={
                                                        "flightIcon" +
                                                        row.TripID +
                                                        "" +
                                                        (rowDayIndex + 1) +
                                                        "1"
                                                      }
                                                      className="col-3 mb-3"
                                                    >
                                                      <div className="input-group">
                                                        <div className="input-group-prepend">
                                                          <img
                                                            src={
                                                              window.location
                                                                .protocol +
                                                              "//" +
                                                              window.location
                                                                .host +
                                                              "/icon/airplane.png"
                                                            }
                                                            className="icon-input-before"
                                                            name="linkFlightButton"
                                                            id="linkFlightButton"
                                                            onClick={this.clickLinkFlight.bind(
                                                              this,
                                                              row.TripID +
                                                                "" +
                                                                (rowDayIndex +
                                                                  1) +
                                                                "1"
                                                            )}
                                                            alt="Airplane"
                                                          />
                                                        </div>
                                                        <Input
                                                          type="text"
                                                          name="linkFlight"
                                                          id="linkFlight"
                                                          className="form-control input-link-before"
                                                          value={
                                                            row[10]
                                                              ? row[10]
                                                              : "Not specified"
                                                          }
                                                          aria-label="linkFlight"
                                                          aria-describedby="linkFlightButton"
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                  {rowDay[2] && (
                                                    <div
                                                      id={
                                                        "flightInputBox" +
                                                        row.TripID +
                                                        "" +
                                                        (rowDayIndex + 1) +
                                                        "1"
                                                      }
                                                      className="col-12 mb-3 d-none"
                                                    >
                                                      <div className="row">
                                                        <div className="col-8">
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <img
                                                                src={
                                                                  window
                                                                    .location
                                                                    .protocol +
                                                                  "//" +
                                                                  window
                                                                    .location
                                                                    .host +
                                                                  "/icon/airplane.png"
                                                                }
                                                                className="icon-input-update"
                                                                name="linkFlightButton"
                                                                id="linkFlightButton"
                                                                onClick={this.clickLinkFlight.bind(
                                                                  this,
                                                                  row.TripID +
                                                                    "" +
                                                                    (rowDayIndex +
                                                                      1) +
                                                                    "1"
                                                                )}
                                                                alt="Motor"
                                                              />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkFlight"
                                                              id="linkFlight"
                                                              className="form-control input-link-update"
                                                              value={rowDay[2]}
                                                              aria-label="linkFlight"
                                                              aria-describedby="linkFlightButton"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="col-4">
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <FaMoneyBillWaveAlt className="icon-input-update-currency" />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkFlightPrice"
                                                              id="linkFlightPrice"
                                                              className="form-control input-link-update"
                                                              value={
                                                                row[10]
                                                                  ? row[10]
                                                                  : "Not specified"
                                                              }
                                                              aria-label="linkFlightPrice"
                                                              aria-describedby="linkFlightPriceButton"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                  {rowDay[3] && (
                                                    <div
                                                      id={
                                                        "hotelIcon" +
                                                        row.TripID +
                                                        "" +
                                                        (rowDayIndex + 1) +
                                                        "2"
                                                      }
                                                      className="col-3 mb-3"
                                                    >
                                                      <div className="input-group">
                                                        <div className="input-group-prepend">
                                                          <img
                                                            src={
                                                              window.location
                                                                .protocol +
                                                              "//" +
                                                              window.location
                                                                .host +
                                                              "/icon/double-bed.png"
                                                            }
                                                            className="icon-input-before"
                                                            name="linkHotelButton"
                                                            id="linkHotelButton"
                                                            onClick={this.clickLinkHotel.bind(
                                                              this,
                                                              row.TripID +
                                                                "" +
                                                                (rowDayIndex +
                                                                  1) +
                                                                "2"
                                                            )}
                                                            alt="Hotel"
                                                          />
                                                        </div>
                                                        <Input
                                                          type="text"
                                                          name="linkHotel"
                                                          id="linkHotel"
                                                          className="form-control input-link-before"
                                                          value={
                                                            row[11]
                                                              ? row[11]
                                                              : "Not specified"
                                                          }
                                                          aria-label="linkHotel"
                                                          aria-describedby="linkHotelButton"
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                  {rowDay[3] && (
                                                    <div
                                                      id={
                                                        "hotelInputBox" +
                                                        row.TripID +
                                                        "" +
                                                        (rowDayIndex + 1) +
                                                        "2"
                                                      }
                                                      className="col-12 mb-3 d-none"
                                                    >
                                                      <div className="row">
                                                        <div className="col-8">
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <img
                                                                src={
                                                                  window
                                                                    .location
                                                                    .protocol +
                                                                  "//" +
                                                                  window
                                                                    .location
                                                                    .host +
                                                                  "/icon/double-bed.png"
                                                                }
                                                                className="icon-input-update"
                                                                name="linkHotelButton"
                                                                id="linkHotelButton"
                                                                onClick={this.clickLinkHotel.bind(
                                                                  this,
                                                                  row.TripID +
                                                                    "" +
                                                                    (rowDayIndex +
                                                                      1) +
                                                                    "2"
                                                                )}
                                                                alt="Motor"
                                                              />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkHotel"
                                                              id="linkHotel"
                                                              className="form-control input-link-update"
                                                              value={rowDay[3]}
                                                              aria-label="linkHotel"
                                                              aria-describedby="linkHotelButton"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="col-4">
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <FaMoneyBillWaveAlt className="icon-input-update-currency" />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkHotelPrice"
                                                              id="linkHotelPrice"
                                                              className="form-control input-link-update"
                                                              value={
                                                                row[11]
                                                                  ? row[11]
                                                                  : "Not specified"
                                                              }
                                                              aria-label="linkHotelPrice"
                                                              aria-describedby="linkHotelPriceButton"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                  {rowDay[4] && (
                                                    <div
                                                      id={
                                                        "carIcon" +
                                                        row.TripID +
                                                        "" +
                                                        (rowDayIndex + 1) +
                                                        "3"
                                                      }
                                                      className="col-3 mb-3"
                                                    >
                                                      <div className="input-group">
                                                        <div className="input-group-prepend">
                                                          <img
                                                            src={
                                                              window.location
                                                                .protocol +
                                                              "//" +
                                                              window.location
                                                                .host +
                                                              "/icon/sedan.png"
                                                            }
                                                            className="icon-input-before"
                                                            name="linkCarButton"
                                                            id="linkCarButton"
                                                            onClick={this.clickLinkCar.bind(
                                                              this,
                                                              row.TripID +
                                                                "" +
                                                                (rowDayIndex +
                                                                  1) +
                                                                "3"
                                                            )}
                                                            alt="Car"
                                                          />
                                                        </div>
                                                        <Input
                                                          type="text"
                                                          name="linkCar"
                                                          id="linkCar"
                                                          className="form-control input-link-before"
                                                          value={
                                                            row[12]
                                                              ? row[12]
                                                              : "Not specified"
                                                          }
                                                          aria-label="linkCar"
                                                          aria-describedby="linkCarButton"
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                  {rowDay[4] && (
                                                    <div
                                                      id={
                                                        "carInputBox" +
                                                        row.TripID +
                                                        "" +
                                                        (rowDayIndex + 1) +
                                                        "3"
                                                      }
                                                      className="col-12 mb-3 d-none"
                                                    >
                                                      <div className="row">
                                                        <div className="col-8">
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <img
                                                                src={
                                                                  window
                                                                    .location
                                                                    .protocol +
                                                                  "//" +
                                                                  window
                                                                    .location
                                                                    .host +
                                                                  "/icon/sedan.png"
                                                                }
                                                                className="icon-input-update"
                                                                name="linkCarButton"
                                                                id="linkCarButton"
                                                                onClick={this.clickLinkCar.bind(
                                                                  this,
                                                                  row.TripID +
                                                                    "" +
                                                                    (rowDayIndex +
                                                                      1) +
                                                                    "3"
                                                                )}
                                                                alt="Motor"
                                                              />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkCar"
                                                              id="linkCar"
                                                              className="form-control input-link-update"
                                                              value={rowDay[4]}
                                                              aria-label="linkCar"
                                                              aria-describedby="linkCarButton"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="col-4">
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <FaMoneyBillWaveAlt className="icon-input-update-currency" />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkCarPrice"
                                                              id="linkCarPrice"
                                                              className="form-control input-link-update"
                                                              value={
                                                                row[12]
                                                                  ? row[12]
                                                                  : "Not specified"
                                                              }
                                                              aria-label="linkCarPrice"
                                                              aria-describedby="linkCarPriceButton"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                  {rowDay[6] && (
                                                    <div
                                                      id={
                                                        "motorIcon" +
                                                        row.TripID +
                                                        "" +
                                                        (rowDayIndex + 1) +
                                                        "5"
                                                      }
                                                      className="col-3 mb-3 "
                                                    >
                                                      <div className="input-group">
                                                        <div className="input-group-prepend">
                                                          <img
                                                            src={
                                                              window.location
                                                                .protocol +
                                                              "//" +
                                                              window.location
                                                                .host +
                                                              "/icon/motorbike.png"
                                                            }
                                                            className="icon-input-before"
                                                            name="linkMotorButton"
                                                            id="linkMotorButton"
                                                            onClick={this.clickLinkMotor.bind(
                                                              this,
                                                              row.TripID +
                                                                "" +
                                                                (rowDayIndex +
                                                                  1) +
                                                                "5"
                                                            )}
                                                            alt="Motor"
                                                          />
                                                        </div>
                                                        <Input
                                                          type="text"
                                                          name="linkMotor"
                                                          id="linkMotor"
                                                          className="form-control input-link-before"
                                                          value={
                                                            row[14]
                                                              ? row[14]
                                                              : "Not specified"
                                                          }
                                                          aria-label="linkMotor"
                                                          aria-describedby="linkMotorButton"
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                  {rowDay[6] && (
                                                    <div
                                                      id={
                                                        "motorInputBox" +
                                                        row.TripID +
                                                        "" +
                                                        (rowDayIndex + 1) +
                                                        "5"
                                                      }
                                                      className="col-12 mb-3 d-none"
                                                    >
                                                      <div className="row">
                                                        <div className="col-8">
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <img
                                                                src={
                                                                  window
                                                                    .location
                                                                    .protocol +
                                                                  "//" +
                                                                  window
                                                                    .location
                                                                    .host +
                                                                  "/icon/motorbike.png"
                                                                }
                                                                className="icon-input-update"
                                                                name="linkMotorButton"
                                                                id="linkMotorButton"
                                                                onClick={this.clickLinkMotor.bind(
                                                                  this,
                                                                  row.TripID +
                                                                    "" +
                                                                    (rowDayIndex +
                                                                      1) +
                                                                    "5"
                                                                )}
                                                                alt="Motor"
                                                              />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkMotor"
                                                              id="linkMotor"
                                                              className="form-control input-link-update"
                                                              value={rowDay[6]}
                                                              aria-label="linkMotor"
                                                              aria-describedby="linkMotorButton"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="col-4">
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <FaMoneyBillWaveAlt className="icon-input-update-currency" />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkMotorPrice"
                                                              id="linkMotorPrice"
                                                              className="form-control input-link-update"
                                                              value={
                                                                row[14]
                                                                  ? row[14]
                                                                  : "Not specified"
                                                              }
                                                              aria-label="linkMotorPrice"
                                                              aria-describedby="linkMotorPriceButton"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </div>
                                      )
                                    )
                                )}
                                <div className="text-center my-3">
                                  <IoIosArrowUp
                                    style={{ cursor: "pointer" }}
                                    onClick={this.toggleTimelineUp.bind(
                                      this,
                                      row.TripID
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="my-5"></div>
                          )}
                          {/* <hr className="line-content mx-auto" /> */}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </Container>
        </div>
      </div>
    );
  }
}
export default Post;

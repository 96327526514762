import { useState } from "react";
import {
  format,
  startOfWeek,
  startOfMonth,
  endOfWeek,
  endOfMonth,
  addDays,
  isSameDay
} from "date-fns";

const Calendar = ({ showDetailsHandle }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [view, setView] = useState("week"); // Toggle between "week" and "month"

  const onDateClickHandle = (day, dayStr) => {
    setSelectedDate(day);
    showDetailsHandle(dayStr);
  };

  const switchView = (selectedView) => {
    setView(selectedView);
  };

  const renderCells = () => {
    const startDate = view === "week"
      ? startOfWeek(currentDate, { weekStartsOn: 1 })
      : startOfMonth(currentDate);

    const endDate = view === "week"
      ? endOfWeek(currentDate, { weekStartsOn: 1 })
      : endOfMonth(currentDate);

    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const cloneDay = day;
        days.push(
          <div
            className={`col cell inline-cell ${
              isSameDay(day, new Date())
                ? "today"
                : isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            key={day}
            // onClick={() => {
            //   const dayStr = format(cloneDay, "ccc dd MMM yy");
            //   onDateClickHandle(cloneDay, dayStr);
            // }}
          >
            <span className="number">{format(day, dateFormat)}</span>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  return (
    <div className="calendar">
      <div className="header d-flex flex-row mb-1">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            switchView("week");
          }}
          style={{
            fontWeight: view === "week" ? "bold" : "normal",
            marginRight: "10px",
            textDecoration: "none",
            color: "#000"
          }}
        >
          Week
        </a>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            switchView("month");
          }}
          style={{
            fontWeight: view === "month" ? "bold" : "normal",
            textDecoration: "none",
            color: "#000"
          }}
        >
          Month
        </a>
      </div>
      <div className="row mb-3">
        <div className="col-2"><p style={{fontWeight: "bold", margin: ".2rem 1rem 0 0"}}>Today</p></div>
        <div className="col-10">{renderCells()}</div>
        </div>
    </div>
  );
};

export default Calendar;

import React from "react";
import {
  Container,
  Navbar,
  NavbarBrand,
  Input,
  Card,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Collapse,
  Button,
} from "reactstrap";
import Slider from "react-slick";

import { FiArrowUpRight, FiSearch } from "react-icons/fi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import ProfileImg from "../img/profile.png";
import Fridayisimg from "../img/fridayis-img.jpg";
import Logo from "../img/logo_new.png";
import ThinkTravel from "../img/ThinkTravel.png";

import ReactHtmlParser from "react-html-parser";
import { MdTrain, MdKeyboardArrowUp } from "react-icons/md";
import { Tab, TabList } from "react-tabs";
import Lightbox from "yet-another-react-lightbox";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { GoogleLogin } from "react-google-login";

import { gapi } from "gapi-script";
import { FaPlus, FaSearch, FaMoneyBillWaveAlt } from "react-icons/fa";
import { HiOutlineTicket } from "react-icons/hi";

import Amsterdam from "../img/shortcut/Amsterdam.png";
import Athens from "../img/shortcut/Athens.png";
import Auckland from "../img/shortcut/Auckland.png";
import Bangkok from "../img/shortcut/Bangkok.png";
import Berlin from "../img/shortcut/Berlin.png";
import BuenosAires from "../img/shortcut/BuenosAires.png";
import Cairo from "../img/shortcut/Cairo.png";
import CapeTown from "../img/shortcut/CapeTown.png";
import Dubai from "../img/shortcut/Dubai.png";
import Hanoi from "../img/shortcut/Hanoi.png";
import Havana from "../img/shortcut/Havana.png";
import Istanbul from "../img/shortcut/Istanbul.png";
import Lisbon from "../img/shortcut/Lisbon.png";
import London from "../img/shortcut/London.png";
import Marrakech from "../img/shortcut/Marrakech.png";
import Mumbai from "../img/shortcut/Mumbai.png";
import NewYork from "../img/shortcut/NewYork.png";
import Paris from "../img/shortcut/Paris.png";
import Prague from "../img/shortcut/Prague.png";
import RioDeJaneiro from "../img/shortcut/RioDeJaneiro.png";
import Rome from "../img/shortcut/Rome.png";
import SanFrancisco from "../img/shortcut/SanFrancisco.png";
import Seoul from "../img/shortcut/Seoul.png";
import StPetersburg from "../img/shortcut/St.Petersburg.png";
import Stockholm from "../img/shortcut/Stockholm.png";
import Sydney from "../img/shortcut/Sydney.png";
import Tokyo from "../img/shortcut/Tokyo.png";
import Vancouver from "../img/shortcut/Vancouver.png";
import Vienna from "../img/shortcut/Vienna.png";

import IconSearch from "../img/icon/icon-search.png";

import { Helmet, HelmetProvider } from "react-helmet-async";
// import { Helmet } from 'react-helmet';

import Google from "../img/google.png";
import NoContent from "../img/no-content.png";
import HeadTags from "../components/HeadTags";
import AdSenseComponent from "../components/AdSenseComponent";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      searchShortcut: "",
      data: [],
      dayList: [],
      dayListAll: [],
      dataImages: [],
      dataImagesList: [],
      dataImagesDay: [],
      day: 0,
      c: 0,
      // printTimeline: []
      open: false,
      sourceImg: [],
      visible: false,
      profile: [],
      clientId:
        "537557522412-jf9lucvl7bsg8sbk9lisvv47fqf45nq0.apps.googleusercontent.com",
      ctrip: "",
      urlctrip: "",
      helmet: [],
      // apiUrl: "http://localhost:3001",
      apiUrl: "https://fridayis-api-ab9e07954977.herokuapp.com",
      urlHelmet: "",
    };
    this.logout = this.logout.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.clickSlideOut = this.clickSlideOut.bind(this);
    this.toggleTimeline = this.toggleTimeline.bind(this);
    this.clickLinkFlight = this.clickLinkFlight.bind(this);
    this.clickLinkHotel = this.clickLinkHotel.bind(this);
    this.clickLinkTrain = this.clickLinkTrain.bind(this);
    this.clickLinkCar = this.clickLinkCar.bind(this);
    this.clickLinkMotor = this.clickLinkMotor.bind(this);
    this.searchShortcut = this.searchShortcut.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.toggleVisible = this.toggleVisible.bind(this);
    this.toggleTripdescriptionHide = this.toggleTripdescriptionHide.bind(this);
    this.toggleTimelineUp = this.toggleTimelineUp.bind(this);
    this.toggleProfile = this.toggleProfile.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailure = this.onFailure.bind(this);
    this.getNextFriday = this.getNextFriday.bind(this);
  }

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let id = params.get("id");
    let ctrip = params.get("ctrip");
    if (id && ctrip) {
      this.setState({ urlctrip: "?id=" + id + "&ctrip=" + ctrip });
    } else if (id) {
      this.setState({ urlctrip: "?id=" + id });
    } else if (ctrip) {
      this.setState({ urlctrip: "?ctrip=" + ctrip });
    }

    if (!localStorage.getItem("userId")) {
      // window.location.href = '/login';
      this.getAllData();
      window.gapi.load("auth2", () => {
        window.gapi.auth2.init({
          client_id: this.state.clientId,
          scopr: "",
        });
      });
      window.addEventListener("scroll", this.toggleVisible);
    } else {
      // const search = window.location.search;
      // const params = new URLSearchParams(search);
      // let id = params.get('id');
      // let ctrip = params.get('ctrip');
      if (id && ctrip) {
        window.location.href = "/home?id=" + id + "&ctrip=" + ctrip;
      } else if (id) {
        window.location.href = "/home?id=" + id;
      } else if (ctrip) {
        window.location.href = "/home?ctrip=" + ctrip;
      } else {
        window.location.href = "/home";
      }

      // window.location.href = '/home'+this.state.urlctrip;
      // this.getAllData();
    }
    // this.getAllData();

    window.setTimeout(function () {
      var element = document.getElementById("makeTheFirstMove");
      if (element.classList.contains("fade-out") === false) {
        element.classList.add("fade-out");
      }
    }, 8000);

    window.setTimeout(function () {
      var element = document.getElementById("makeTheFirstMove");
      if (element.classList.contains("d-none") === false) {
        element.classList.add("d-none");
      }
    }, 9500);
  }

  onSuccess(response) {
    this.setState({ profile: response.profileObj }, () => {
      fetch(this.state.apiUrl + "/api/user/google", {
        body: JSON.stringify(this.state.profile),
        method: "POST",
        headers: { "Content-type": "application/json;charset=UTF-8" },
      })
        .then((res) => res.json())
        .then((data) => {
          localStorage.setItem("userId", data[0].UserID);
          localStorage.setItem("username", data[0].Username);
          localStorage.setItem("imageUrl", data[0].ProfileURL);

          const User = {
            userId: data[0].UserID,
            username: data[0].Username,
            imageUrl: data[0].ProfileURL,
            passportNo: "",
            passportExpire: "",
            dreamDestination: "",
            dreamEvent: "",
          };

          localStorage.setItem("userInfo", JSON.stringify(User));

          const search = window.location.search;
          const params = new URLSearchParams(search);
          let id = params.get("id");
          let ctrip = params.get("ctrip");

          if (this.state.urlctrip) {
            let dataAddInvited = {
              userId: data[0].UserID,
              ctrip: ctrip,
              status: "invited",
            };
            fetch(
              this.state.apiUrl + "/api/clone/invited/add",
              // fetch('http://localhost:3001/api/clone/invited/add',
              {
                body: JSON.stringify(dataAddInvited),
                method: "POST",
                headers: { "Content-type": "application/json;charset=UTF-8" },
              }
            )
              .then((res) => res.json())
              .then((data) => {
                // if(data.message === "Success"){

                window.setTimeout(function () {
                  if (id && ctrip) {
                    window.location.href = "/home?id=" + id + "&ctrip=" + ctrip;
                  } else if (id) {
                    window.location.href = "/home?id=" + id;
                  } else if (ctrip) {
                    window.location.href = "/home?ctrip=" + ctrip;
                  }
                }, 1000);
                // window.location.href = "/home"+this.state.urlctrip;
                // }, 1000);
                // }
              });
          } else {
            window.setTimeout(function () {
              window.location.href = "/home";
            }, 1000);
          }
        })
        .catch((error) => {
          // this.setState({visible : true});
        });
    });

    // console.log('Success', response);
  }

  onFailure(response) {
    console.log("Failure", response);
  }

  getAllData() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get("id");
    let ctrip = params.get("ctrip");
    this.setState({ ctrip: ctrip });
    if (id) {
      this.setState({ urlHelmet: id });
    }

    let url = this.state.apiUrl + "/api/trip/all";
    // let url = 'http://localhost:3001/api/trip/all';
    if (id) {
      url = this.state.apiUrl + "/api/trip/all?id=" + id;
      sessionStorage.setItem("id", id);
    }
    if (this.state.search) {
      url =
        this.state.apiUrl + "/api/trip/all?destination=" + this.state.search;
    }
    if (this.state.searchShortcut) {
      url =
        this.state.apiUrl +
        "/api/trip/all?destination=" +
        this.state.searchShortcut;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) =>
        this.setState({ data: data.all }, () => {
          // let j = 0;
          let flightFlag = 0;
          let carFlag = 0;
          let trainFlag = 0;
          let hotelFlag = 0;
          let motorFlag = 0;
          let newData = [];
          let arrayLists = [];
          for (let i = 0; i < this.state.data.length; i++) {
            if (i > 0) {
              // console.log(':r other');
              if (
                this.state.data[i].TripID.toString() !==
                this.state.data[i - 1].TripID.toString()
              ) {
                // not same id
                // console.log('::r other not same id');

                // console.log("Important:::>"+newData);
                // console.log("Important:::>"+this.state.data[i - 1].TripID);
                // console.log("Important:::>"+this.state.data[i].TripID);
                // console.log("Important:::>"+this.state.data[i].DayNumber);

                if (newData.length > 0) {
                  if (flightFlag === 0) {
                    newData[parseInt(newData.length) - 1][2] = "";
                  }
                  if (hotelFlag === 0) {
                    newData[parseInt(newData.length) - 1][3] = "";
                  }
                  if (carFlag === 0) {
                    newData[parseInt(newData.length) - 1][4] = "";
                  }
                  if (trainFlag === 0) {
                    newData[parseInt(newData.length) - 1][5] = "";
                  }
                  if (motorFlag === 0) {
                    newData[parseInt(newData.length) - 1][6] = "";
                  }
                }

                let arrayList = [];
                arrayList.id = this.state.data[i - 1].TripID;
                // arrayList.data = this.state.dayList;
                arrayList.data = newData;

                // let arrayLists = [...this.state.dayListAll];
                arrayLists.push(arrayList);
                this.setState({ dayListAll: arrayLists }, () => {
                  this.setState({ dayList: [] }, () => {
                    // console.log(this.state.dayListAll);
                  });
                });

                // reset round
                flightFlag = 0;
                carFlag = 0;
                trainFlag = 0;
                hotelFlag = 0;
                motorFlag = 0;
                // newData = [];
                newData = [...this.state.dayList];
                newData[parseInt(this.state.data[i].DayNumber) - 1] = [];
                newData[parseInt(this.state.data[i].DayNumber) - 1][0] = ""; //contentTitle
                newData[parseInt(this.state.data[i].DayNumber) - 1][1] =
                  this.state.data[i].Description;
                if (this.state.data[i].PlanLinkTypeName === "flight") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                    this.state.data[i].Url;
                  flightFlag = 1;
                } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                    this.state.data[i].Url;
                  hotelFlag = 1;
                } else if (this.state.data[i].PlanLinkTypeName === "car") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                    this.state.data[i].Url;
                  carFlag = 1;
                } else if (this.state.data[i].PlanLinkTypeName === "train") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                    this.state.data[i].Url;
                  trainFlag = 1;
                } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                    this.state.data[i].Url;
                  motorFlag = 1;
                }
                newData[parseInt(this.state.data[i].DayNumber) - 1][7] = [];
                newData[parseInt(this.state.data[i].DayNumber) - 1][8] = [];
                newData[parseInt(this.state.data[i].DayNumber) - 1][9] =
                  this.state.data[i].ContentDestination; //contentDestination
                // newData[parseInt(this.state.data[i].DayNumber) - 1][7] = this.state.data[i].ImageURL;
                // newData[parseInt(this.state.data[i].DayNumber) - 1][8] = this.state.data[i].ImageURL2;

                if (i + 1 === parseInt(this.state.data.length)) {
                  // console.log(':::r other not same id last r');
                  if (flightFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][2] = "";
                  }
                  if (hotelFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][3] = "";
                  }
                  if (carFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][4] = "";
                  }
                  if (trainFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][5] = "";
                  }
                  if (motorFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][6] = "";
                  }

                  let arrayList = [];
                  arrayList.id = this.state.data[i].TripID;
                  // arrayList.data = this.state.dayList;
                  arrayList.data = newData;

                  // let arrayLists = [...this.state.dayListAll];
                  arrayLists.push(arrayList);
                  this.setState({ dayListAll: arrayLists }, () => {
                    this.setState({ dayList: [] }, () => {
                      // console.log(this.state.dayListAll);
                    });
                  });
                }
              } else {
                //same id
                // console.log('::r other same id');

                if (
                  this.state.data[i].DayNumber !==
                  this.state.data[i - 1].DayNumber
                ) {
                  // console.log(':::r other same id not same day');
                  // not same day
                  // j++;
                  if (flightFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 2][2] = "";
                  }
                  if (hotelFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 2][3] = "";
                  }
                  if (carFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 2][4] = "";
                  }
                  if (trainFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 2][5] = "";
                  }
                  if (motorFlag === 0) {
                    newData[parseInt(this.state.data[i].DayNumber) - 2][6] = "";
                  }

                  newData[parseInt(this.state.data[i].DayNumber) - 1] = [];
                  newData[parseInt(this.state.data[i].DayNumber) - 1][0] = ""; //contentTitle
                  newData[parseInt(this.state.data[i].DayNumber) - 1][1] =
                    this.state.data[i].Description;
                  if (this.state.data[i].PlanLinkTypeName === "flight") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                      this.state.data[i].Url;
                    flightFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                      this.state.data[i].Url;
                    hotelFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "car") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                      this.state.data[i].Url;
                    carFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "train") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                      this.state.data[i].Url;
                    trainFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                      this.state.data[i].Url;
                    motorFlag = 1;
                  }
                  newData[parseInt(this.state.data[i].DayNumber) - 1][7] = [];
                  newData[parseInt(this.state.data[i].DayNumber) - 1][8] = [];
                  newData[parseInt(this.state.data[i].DayNumber) - 1][9] =
                    this.state.data[i].ContentDestination; //contentDestination
                  // newData[parseInt(this.state.data[i].DayNumber) - 1][7] = this.state.data[i].ImageURL;
                  // newData[parseInt(this.state.data[i].DayNumber) - 1][8] = this.state.data[i].ImageURL2;

                  // console.log("r other/id/not day:" + this.state.dayList);

                  if (i + 1 === parseInt(this.state.data.length)) {
                    // console.log('::::r other same id not same day last r');
                    if (flightFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                        "";
                    }
                    if (hotelFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                        "";
                    }
                    if (carFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                        "";
                    }
                    if (trainFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                        "";
                    }
                    if (motorFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                        "";
                    }

                    let arrayList = [];
                    arrayList.id = this.state.data[i].TripID;
                    // arrayList.data = this.state.dayList;
                    arrayList.data = newData;

                    // let arrayLists = [...this.state.dayListAll];
                    arrayLists.push(arrayList);
                    this.setState({ dayListAll: arrayLists }, () => {
                      this.setState({ dayList: [] }, () => {
                        // console.log(this.state.dayListAll);
                      });
                    });

                    // this.setState({ dayList: newData },()=>{
                    //     let arrayList = [];
                    //     arrayList.id = this.state.data[i].TripID;
                    //     arrayList.data = this.state.dayList;
                    //
                    //     // let arrayLists = [...this.state.dayListAll];
                    //     let arrayLists = [];
                    //     arrayLists.push(arrayList);
                    //     this.setState({ dayListAll: arrayLists },()=>{
                    //         console.log(this.state.dayListAll);
                    //     });
                    // });
                  }
                } else {
                  //same day
                  // console.log(':::r other same id same day');
                  if (this.state.data[i].PlanLinkTypeName === "flight") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                      this.state.data[i].Url;
                    flightFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                      this.state.data[i].Url;
                    hotelFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "car") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                      this.state.data[i].Url;
                    carFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "train") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                      this.state.data[i].Url;
                    trainFlag = 1;
                  } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                    newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                      this.state.data[i].Url;
                    motorFlag = 1;
                  }

                  if (i + 1 === parseInt(this.state.data.length)) {
                    // console.log('::::r other not same id same day last r');
                    if (flightFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                        "";
                    }
                    if (hotelFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                        "";
                    }
                    if (carFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                        "";
                    }
                    if (trainFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                        "";
                    }
                    if (motorFlag === 0) {
                      newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                        "";
                    }

                    let arrayList = [];
                    arrayList.id = this.state.data[i].TripID;
                    // arrayList.data = this.state.dayList;
                    arrayList.data = newData;

                    // let arrayLists = [...this.state.dayListAll];
                    arrayLists.push(arrayList);
                    this.setState({ dayListAll: arrayLists }, () => {
                      this.setState({ dayList: [] }, () => {
                        // console.log(this.state.dayListAll);
                      });
                    });
                  }
                }
              }
            } else {
              // console.log(':r1');
              // if(this.state.data[i].DayNumber){
              newData = [...newData];
              newData[parseInt(this.state.data[i].DayNumber) - 1] = [];
              newData[parseInt(this.state.data[i].DayNumber) - 1][0] = ""; //contentTitle
              newData[parseInt(this.state.data[i].DayNumber) - 1][1] =
                this.state.data[i].Description;
              if (this.state.data[i].PlanLinkTypeName === "flight") {
                newData[parseInt(this.state.data[i].DayNumber) - 1][2] =
                  this.state.data[i].Url;
                flightFlag = 1;
              } else if (this.state.data[i].PlanLinkTypeName === "hotel") {
                newData[parseInt(this.state.data[i].DayNumber) - 1][3] =
                  this.state.data[i].Url;
                hotelFlag = 1;
              } else if (this.state.data[i].PlanLinkTypeName === "car") {
                newData[parseInt(this.state.data[i].DayNumber) - 1][4] =
                  this.state.data[i].Url;
                carFlag = 1;
              } else if (this.state.data[i].PlanLinkTypeName === "train") {
                newData[parseInt(this.state.data[i].DayNumber) - 1][5] =
                  this.state.data[i].Url;
                trainFlag = 1;
              } else if (this.state.data[i].PlanLinkTypeName === "motor") {
                newData[parseInt(this.state.data[i].DayNumber) - 1][6] =
                  this.state.data[i].Url;
                motorFlag = 1;
              }

              newData[parseInt(this.state.data[i].DayNumber) - 1][7] = [];
              newData[parseInt(this.state.data[i].DayNumber) - 1][8] = [];
              newData[parseInt(this.state.data[i].DayNumber) - 1][9] =
                this.state.data[i].ContentDestination; //contentDestination
              // newData[parseInt(this.state.data[i].DayNumber) - 1][7] = this.state.data[i].ImageURL;
              // newData[parseInt(this.state.data[i].DayNumber) - 1][8] = this.state.data[i].ImageURL2;

              // console.log("r1:" + this.state.dayList);

              if (i + 1 === parseInt(this.state.data.length)) {
                // console.log('::r1 last round');
                if (flightFlag === 0) {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][2] = "";
                }
                if (hotelFlag === 0) {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][3] = "";
                }
                if (carFlag === 0) {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][4] = "";
                }
                if (trainFlag === 0) {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][5] = "";
                }
                if (motorFlag === 0) {
                  newData[parseInt(this.state.data[i].DayNumber) - 1][6] = "";
                }

                let arrayList = [];
                arrayList.id = this.state.data[i].TripID;
                // arrayList.data = this.state.dayList;
                arrayList.data = newData;

                // let arrayLists = [...this.state.dayListAll];
                arrayLists.push(arrayList);
                this.setState({ dayListAll: arrayLists }, () => {
                  this.setState({ dayList: [] }, () => {
                    // console.log(this.state.dayListAll);
                  });
                });
              }
            }
          }

          this.setState({ dataImages: data.tripImage }, () => {
            let image = [];
            let imageSrc = [];
            let imageList = [];
            let imageLists = [];
            // let k = 0
            for (let j = 0; j < this.state.dataImages.length; j++) {
              if (j > 0) {
                if (
                  this.state.dataImages[j].TripID.toString() !==
                  this.state.dataImages[j - 1].TripID.toString()
                ) {
                  imageList.id = this.state.dataImages[j - 1].TripID;
                  if (image[0]) {
                    imageList.url = image;
                    imageList.src = imageSrc;
                    imageList.startDate = "";
                    imageList.startDateDate = "";
                    // this.state.data[k].TripImageURL = image;
                  } else {
                    imageList.url = null;
                    imageList.src = null;
                    imageList.startDate = "";
                    imageList.startDateDate = "";
                    // this.state.data[k].TripImageURL = null;
                  }
                  // k++;

                  imageLists.push(imageList);

                  image = [];
                  imageSrc = [];
                  imageList = [];

                  image.push(this.state.dataImages[j].ImageURL);
                  imageSrc.push({ src: this.state.dataImages[j].ImageURL });

                  if (j + 1 === parseInt(this.state.dataImages.length)) {
                    imageList.id = this.state.dataImages[j].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                      imageList.startDate = "";
                      imageList.startDateDate = "";
                      // this.state.data[k].TripImageURL = image;
                    } else {
                      imageList.url = null;
                      imageList.src = null;
                      imageList.startDate = "";
                      imageList.startDateDate = "";
                      // this.state.data[k].TripImageURL = null;
                    }
                    // k++;

                    imageLists.push(imageList);
                  }
                } else {
                  image = [...image];
                  imageSrc = [...imageSrc];
                  image.push(this.state.dataImages[j].ImageURL);
                  imageSrc.push({ src: this.state.dataImages[j].ImageURL });

                  if (j + 1 === parseInt(this.state.dataImages.length)) {
                    imageList.id = this.state.dataImages[j].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                      imageList.startDate = "";
                      imageList.startDateDate = "";
                      // this.state.data[k].TripImageURL = image;
                    } else {
                      imageList.url = null;
                      imageList.src = null;
                      imageList.startDate = "";
                      imageList.startDateDate = "";
                      // this.state.data[k].TripImageURL = null;
                    }
                    // k++;

                    imageLists.push(imageList);
                  }
                }
              } else {
                image.push(this.state.dataImages[j].ImageURL);
                imageSrc.push({ src: this.state.dataImages[j].ImageURL });

                if (j + 1 === parseInt(this.state.dataImages.length)) {
                  imageList.id = this.state.dataImages[j].TripID;
                  if (image[0]) {
                    imageList.url = image;
                    imageList.src = imageSrc;
                    imageList.startDate = "";
                    imageList.startDateDate = "";
                    // this.state.data[k].TripImageURL = image;
                  } else {
                    imageList.url = null;
                    imageList.src = null;
                    imageList.startDate = "";
                    imageList.startDateDate = "";
                    // this.state.data[k].TripImageURL = null;
                  }
                  // k++;

                  imageLists.push(imageList);
                }
              }
            }

            this.setState({ dataImagesList: imageLists }, () => {
              image = [];
              imageSrc = [];
            });
          });

          this.setState({ dataImagesDay: data.contentImage }, () => {
            let image = [];
            let imageSrc = [];
            let imageList = [];
            // let imageLists = []
            // let k = 0
            let items = [...this.state.dayListAll];
            for (let p = 0; p < this.state.dataImagesDay.length; p++) {
              let indDay = this.state.dayListAll.findIndex(
                (obj) => obj.id === this.state.dataImagesDay[p].TripID
              );

              if (p > 0) {
                if (
                  this.state.dataImagesDay[p].TripID.toString() !==
                  this.state.dataImagesDay[p - 1].TripID.toString()
                ) {
                  // imageList.id = this.state.dataImagesDay[p-1].TripID;
                  if (image[0]) {
                    imageList.url = image;
                    imageList.src = imageSrc;
                    // this.state.data[k].TripImageURL = image;
                  } else {
                    imageList.url = [];
                    imageList.src = [];
                  }

                  // imageLists.push(imageList);
                  indDay = this.state.dayListAll.findIndex(
                    (obj) => obj.id === this.state.dataImagesDay[p - 1].TripID
                  );
                  items[indDay].data[
                    parseInt(this.state.dataImagesDay[p - 1].DayNumber) - 1
                  ][7] = imageList.url;
                  items[indDay].data[
                    parseInt(this.state.dataImagesDay[p - 1].DayNumber) - 1
                  ][8] = imageList.src;

                  image = [];
                  imageSrc = [];
                  imageList = [];

                  image.push(this.state.dataImagesDay[p].ImageURL);
                  imageSrc.push({ src: this.state.dataImagesDay[p].ImageURL });

                  if (p + 1 === parseInt(this.state.dataImagesDay.length)) {
                    // imageList.id = this.state.dataImagesDay[p].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                      // this.state.data[k].TripImageURL = image;
                    } else {
                      imageList.url = [];
                      imageList.src = [];
                    }

                    // imageLists.push(imageList);
                    indDay = this.state.dayListAll.findIndex(
                      (obj) => obj.id === this.state.dataImagesDay[p].TripID
                    );
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                    ][7] = imageList.url;
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                    ][8] = imageList.src;
                  }
                } else if (
                  this.state.dataImagesDay[p].TripID.toString() ===
                    this.state.dataImagesDay[p - 1].TripID.toString() &&
                  this.state.dataImagesDay[p].DayNumber.toString() !==
                    this.state.dataImagesDay[p - 1].DayNumber.toString()
                ) {
                  // imageList.id = this.state.dataImagesDay[p-1].TripID;
                  if (image[0]) {
                    imageList.url = image;
                    imageList.src = imageSrc;
                    // this.state.data[k].TripImageURL = image;
                  } else {
                    imageList.url = [];
                    imageList.src = [];
                  }

                  // imageLists.push(imageList);
                  indDay = this.state.dayListAll.findIndex(
                    (obj) => obj.id === this.state.dataImagesDay[p - 1].TripID
                  );
                  items[indDay].data[
                    parseInt(this.state.dataImagesDay[p - 1].DayNumber) - 1
                  ][7] = imageList.url;
                  items[indDay].data[
                    parseInt(this.state.dataImagesDay[p - 1].DayNumber) - 1
                  ][8] = imageList.src;

                  image = [];
                  imageSrc = [];
                  imageList = [];

                  image.push(this.state.dataImagesDay[p].ImageURL);
                  imageSrc.push({ src: this.state.dataImagesDay[p].ImageURL });

                  if (p + 1 === parseInt(this.state.dataImagesDay.length)) {
                    // imageList.id = this.state.dataImagesDay[p].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                    } else {
                      imageList.url = [];
                      imageList.src = [];
                    }

                    // imageLists.push(imageList);
                    indDay = this.state.dayListAll.findIndex(
                      (obj) => obj.id === this.state.dataImagesDay[p].TripID
                    );
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                    ][7] = imageList.url;
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                    ][8] = imageList.src;
                  }
                } else {
                  image = [...image];
                  imageSrc = [...imageSrc];
                  image.push(this.state.dataImagesDay[p].ImageURL);
                  imageSrc.push({ src: this.state.dataImagesDay[p].ImageURL });

                  if (p + 1 === parseInt(this.state.dataImagesDay.length)) {
                    // imageList.id = this.state.dataImagesDay[p].TripID;
                    if (image[0]) {
                      imageList.url = image;
                      imageList.src = imageSrc;
                    } else {
                      imageList.url = [];
                      imageList.src = [];
                    }

                    // imageLists.push(imageList);
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                    ][7] = imageList.url;
                    items[indDay].data[
                      parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                    ][8] = imageList.src;
                  }
                }
              } else {
                image.push(this.state.dataImagesDay[p].ImageURL);
                imageSrc.push({ src: this.state.dataImagesDay[p].ImageURL });

                if (p + 1 === parseInt(this.state.dataImagesDay.length)) {
                  // imageList.id = this.state.dataImagesDay[p].TripID;
                  if (image[0]) {
                    imageList.url = image;
                    imageList.src = imageSrc;
                  } else {
                    imageList.url = [];
                    imageList.src = [];
                  }

                  // imageLists.push(imageList);
                  items[indDay].data[
                    parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                  ][7] = imageList.url;
                  items[indDay].data[
                    parseInt(this.state.dataImagesDay[p].DayNumber) - 1
                  ][8] = imageList.src;
                }
              }
              // console.log(imageLists.url);
            }

            // console.log(imageLists);
            // console.log(items);

            this.setState({ dayListAll: items }, () => {
              image = [];
              imageSrc = [];
              imageList = [];

              // console.log(this.state.dayListAll);
            });
          });

          let helmet = [];
          helmet[0] = "Fridayis | All-in-one travel community";
          helmet[1] =
            "Explore the world with our travel guides, tips, and recommendations. Find the best deals on flights, hotels, and activities, and plan your dream vacation today. Whether you're looking for adventure or relaxation, we've got you covered. Start your journey now.";
          helmet[2] = "https://www.fridayis.com/logo_shared.png";
          helmet[3] = "https://www.fridayis.com";
          if (id) {
            if (data.all.length > 0 && data.tripImage.length > 0) {
              helmet[0] = "Fridayis | " + data.all[0].TripName;
              helmet[1] = data.all[0].TripDescription;
              helmet[2] = data.tripImage[0].ImageURL;
              helmet[3] = "https://www.fridayis.com?id=" + id;
            }
          }

          this.setState({ helmet: helmet });
        })
      );
  }

  getNextFriday() {
    const today = new Date();
    const currentDay = today.getDay();
    const daysUntilFriday =
      currentDay <= 5 ? 5 - currentDay : 5 + (7 - currentDay);
    const nextFriday = new Date(today);
    nextFriday.setDate(today.getDate() + daysUntilFriday + 7);
    return nextFriday;
  }

  clickLinkFlight(num) {
    let element = document.getElementById("flightInputBox" + num);
    let element2 = document.getElementById("flightIcon" + num);

    if (element.classList.contains("d-none") === false) {
      element.classList.add("d-none");
      element2.classList.remove("d-none");
    } else {
      element.classList.remove("d-none");
      element2.classList.add("d-none");
    }
  }

  clickLinkCar(num) {
    let elementCar = document.getElementById("carInputBox" + num);
    let element2 = document.getElementById("carIcon" + num);
    if (elementCar.classList.contains("d-none") === false) {
      elementCar.classList.add("d-none");
      element2.classList.remove("d-none");
    } else {
      elementCar.classList.remove("d-none");
      element2.classList.add("d-none");
    }
  }

  clickLinkTrain(num) {
    let elementTrain = document.getElementById("trainInputBox" + num);
    let element2 = document.getElementById("trainIcon" + num);
    if (elementTrain.classList.contains("d-none") === false) {
      elementTrain.classList.add("d-none");
      element2.classList.remove("d-none");
    } else {
      elementTrain.classList.remove("d-none");
      element2.classList.add("d-none");
    }
  }

  clickLinkMotor(num) {
    let elementMotor = document.getElementById("motorInputBox" + num);
    let element2 = document.getElementById("motorIcon" + num);
    if (elementMotor.classList.contains("d-none") === false) {
      elementMotor.classList.add("d-none");
      element2.classList.remove("d-none");
    } else {
      elementMotor.classList.remove("d-none");
      element2.classList.add("d-none");
    }
  }

  clickLinkHotel(num) {
    let elementHotel = document.getElementById("hotelInputBox" + num);
    let element2 = document.getElementById("hotelIcon" + num);
    if (elementHotel.classList.contains("d-none") === false) {
      elementHotel.classList.add("d-none");
      element2.classList.remove("d-none");
    } else {
      elementHotel.classList.remove("d-none");
      element2.classList.add("d-none");
    }
  }

  handleChange(e) {
    switch (e.target.name) {
      case "search":
        this.setState({ search: e.target.value }, () => {
          if (e.target.value === "") {
            this.setState({ searchShortcut: "" }, () => {
              // window.setTimeout(function(){
              //     var element = document.getElementById("search-icon");
              //     var element2 = document.getElementById("search-bar");
              //     if(element2.classList.contains("d-none") === false){
              //         element2.classList.add("d-none");
              //     }
              //     else{
              //         element2.classList.remove("d-none");
              //     }
              //     if(element.classList.contains("d-none") === false){
              //         element.classList.add("d-none");
              //     }
              //     else{
              //         element.classList.remove("d-none");
              //     }
              // }, 10000);

              this.getAllData();
            });
            // this.getAllData();
          }
        });
        break;
      default:
      // code block
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  logout() {
    // this.setState({memberId: ""});
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/login";
  }

  clickSlideOut() {
    var element = document.getElementById("slideout_inner");
    if (element.classList.contains("show") === false) {
      element.classList.add("show");
    } else {
      element.classList.remove("show");
    }
  }

  checkEnter(e) {
    if (e.keyCode === 13) {
      let searchBar = document.getElementById("search-bar");
      if (searchBar.classList.contains("d-none") === false) {
        searchBar.classList.add("d-none");
      }

      this.getAllData();

      // setTimeout( this.getAllData(), 1000);

      // console.log("Enter key is pressed:: "+ this.state.search);
    }
  }

  searchShortcut(search) {
    this.setState({ searchShortcut: search }, () => {
      this.setState({ search: "" }, () => {
        this.getAllData();
      });
    });
  }

  openLightbox(sourceImg) {
    this.setState({ sourceImg: sourceImg }, () => {
      this.setState({ open: true });
    });
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  toggleVisible() {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      this.setState({ visible: true });
    } else if (scrolled <= 300) {
      this.setState({ visible: false });
    }

    if (scrolled > 50) {
      document
        .getElementById("navMobile")
        .classList.add("bg-transparent-white");
      document.getElementById("navMobile").classList.remove("bg-white");
    } else if (scrolled <= 50) {
      document.getElementById("navMobile").classList.add("bg-white");
      document
        .getElementById("navMobile")
        .classList.remove("bg-transparent-white");
    }
  }

  toggleTripdescriptionHide(id) {
    var element = document.getElementById("tripDescriptionHide" + id);
    var element2 = document.getElementById("tripDescription" + id);
    if (element2.classList.contains("d-none") === false) {
      element2.classList.add("d-none");
    } else {
      element2.classList.remove("d-none");
    }
    if (element.classList.contains("d-none") === false) {
      element.classList.add("d-none");
    } else {
      element.classList.remove("d-none");
    }
  }

  toggleTimeline(id) {
    var element = document.getElementById("arrow-down" + id);
    var element2 = document.getElementById("arrow-up" + id);
    if (element2.classList.contains("d-none") === false) {
      element2.classList.add("d-none");
    } else {
      element2.classList.remove("d-none");
    }
    if (element.classList.contains("d-none") === false) {
      element.classList.add("d-none");
    } else {
      element.classList.remove("d-none");
    }

    let elementDescriptionHide = document.getElementById(
      "tripDescriptionHide" + id
    );
    let elementDescription = document.getElementById("tripDescription" + id);

    let elementTimeline = document.getElementById("timeline" + id);
    if (elementTimeline.classList.contains("d-none") === false) {
      elementTimeline.classList.add("d-none");

      if (elementDescription.classList.contains("d-none") === true) {
        elementDescription.classList.remove("d-none");
      }

      if (elementDescriptionHide.classList.contains("d-none") === false) {
        elementDescriptionHide.classList.add("d-none");
      }
    } else {
      elementTimeline.classList.remove("d-none");

      if (elementDescription.classList.contains("d-none") === false) {
        elementDescription.classList.add("d-none");
      }

      if (elementDescriptionHide.classList.contains("d-none") === true) {
        elementDescriptionHide.classList.remove("d-none");
      }
    }
  }

  toggleTimelineUp(id) {
    var element = document.getElementById("arrow-down" + id);
    var element2 = document.getElementById("arrow-up" + id);
    if (element2.classList.contains("d-none") === false) {
      element2.classList.add("d-none");
    } else {
      element2.classList.remove("d-none");
    }
    if (element.classList.contains("d-none") === false) {
      element.classList.add("d-none");
    } else {
      element.classList.remove("d-none");
    }

    let elementDescriptionHide = document.getElementById(
      "tripDescriptionHide" + id
    );
    let elementDescription = document.getElementById("tripDescription" + id);

    let elementTimeline = document.getElementById("timeline" + id);
    // if(elementTimeline.classList.contains("d-none") === false){
    //     elementTimeline.classList.add("d-none");
    // }
    // else{
    //     elementTimeline.classList.remove("d-none");
    // }

    if (elementTimeline.classList.contains("d-none") === false) {
      elementTimeline.classList.add("d-none");

      if (elementDescription.classList.contains("d-none") === true) {
        elementDescription.classList.remove("d-none");
      }

      if (elementDescriptionHide.classList.contains("d-none") === false) {
        elementDescriptionHide.classList.add("d-none");
      }
    } else {
      elementTimeline.classList.remove("d-none");

      if (elementDescription.classList.contains("d-none") === false) {
        elementDescription.classList.add("d-none");
      }

      if (elementDescriptionHide.classList.contains("d-none") === true) {
        elementDescriptionHide.classList.remove("d-none");
      }
    }

    window.location.href = "#content-timeline" + id;
  }

  toggleProfile(id) {
    var element = document.getElementById("arrow-down-profile");
    var element2 = document.getElementById("arrow-up-profile");
    if (element2.classList.contains("d-none") === false) {
      element2.classList.add("d-none");
    } else {
      element2.classList.remove("d-none");
    }
    if (element.classList.contains("d-none") === false) {
      element.classList.add("d-none");
    } else {
      element.classList.remove("d-none");
    }

    let elementProfile = document.getElementById(id);
    if (elementProfile.classList.contains("d-none") === false) {
      elementProfile.classList.add("d-none");
    } else {
      elementProfile.classList.remove("d-none");
    }
  }

  openSearch() {
    // var element = document.getElementById("search-icon");
    var element2 = document.getElementById("search-bar");
    if (element2.classList.contains("d-none") === false) {
      element2.classList.add("d-none");
    } else {
      element2.classList.remove("d-none");
    }
    // if(element.classList.contains("d-none") === false){
    //     element.classList.add("d-none");
    // }
    // else{
    //     element.classList.remove("d-none");
    // }
  }

  // renderArrows = () => {
  //     return (
  //         <div className="slider-arrow">
  //             <Button
  //                 className="arrow-btn prev"
  //                 onClick={() => this.slider.slickPrev()}
  //             >
  //                 Left
  //             </Button>
  //             <Button
  //                 className="arrow-btn next"
  //                 onClick={() => this.slider.slickNext()}
  //             >
  //                 Right
  //             </Button>
  //         </div>
  //     );
  // };

  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 4,
      swipeToSlide: true,
      // dots: false,
      // infinite: true,
      speed: 300,
      // slidesToShow: 5,
      // slidesToScroll: 5,
      responsive: [
        {
          breakpoint: 1181,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 3,
            // slidesToShow: 3,
            // slidesToScroll: 3,
            // infinite: true,
            // dots: false
          },
        },
        {
          breakpoint: 1040,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 2,
            // slidesToShow: 3,
            // slidesToScroll: 3,
            // infinite: true,
            // dots: false
          },
        },
        {
          breakpoint: 820,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "140px",
            slidesToShow: 2,
            // slidesToShow: 2,
            // slidesToScroll: 2
          },
        },
        {
          breakpoint: 799,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "120px",
            slidesToShow: 2,
            // slidesToShow: 2,
            // slidesToScroll: 2
          },
        },
        {
          breakpoint: 670,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "100px",
            slidesToShow: 2,
            // slidesToShow: 2,
            // slidesToScroll: 2
          },
        },
        {
          breakpoint: 530,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "80px",
            slidesToShow: 2,
            // slidesToShow: 2,
            // slidesToScroll: 2
          },
        },
        {
          breakpoint: 500,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "130px",
            slidesToShow: 1,
            // slidesToShow: 2,
            // slidesToScroll: 2
          },
        },
        {
          breakpoint: 480,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "120px",
            slidesToShow: 1,
            // slidesToShow: 2,
            // slidesToScroll: 2
          },
        },
        {
          breakpoint: 430,
          settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "100px",
            slidesToShow: 1,
            // slidesToShow: 2,
            // slidesToScroll: 2
          },
        },
      ],
    };
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const nextFriday = this.getNextFriday();
    return (
      // <HelmetProvider>
      <div className="bg">
        {this.state.urlHelmet && (
          <HeadTags
            title={this.state.data[0]?.TripName}
            metaDescription={this.state.data[0]?.TripDescription}
            metaImage={this.state.dataImagesList[0]?.url[0]}
            metaUrl={
              "https://www.fridayis.com?id=" + this.state.data[0]?.TripID
            }
          />
        )}

        {/*Desktop show nav*/}
        <Container className="d-none d-lg-block">
          <Navbar color="white" light sticky="top">
            <div>
              <NavbarBrand role="button" href="/">
                <img src={Logo} className="logo-img" alt="Profile" />
                <p
                  className="d-none d-md-inline-flex fw-bold mx-3"
                  style={{ fontSize: "16px", paddingTop: "1rem" }}
                >
                  Fridayis
                </p>
              </NavbarBrand>
            </div>

            <div className="d-flex">
              <button className="sarch-btn" onClick={this.openSearch}>
                <img src={IconSearch} className="nav-icon" alt="icon search" />
              </button>
            </div>
          </Navbar>
        </Container>

        {/*Mobile show nav*/}
        <div className="d-block d-lg-none">
          <Navbar
            id="navMobile"
            className="bg-white d-block d-lg-none"
            sticky="top"
          >
            <NavbarBrand role="button" href="/">
              <img src={Logo} className="logo-img" alt="Profile" />
            </NavbarBrand>
            <Nav className="ml-auto">
              <h2 className="mt-1">Think Travel</h2>
              {/*<NavItem>*/}
              {/*    <NavLink href="/register" className="link-default">Register</NavLink>*/}
              {/*</NavItem>*/}
              {/*<NavItem>*/}
              {/*    <NavLink href="/login" className="link-default">Login</NavLink>*/}
              {/*</NavItem>*/}
            </Nav>
          </Navbar>
        </div>

        <Lightbox
          open={this.state.open}
          close={() => this.setState({ open: false })}
          slides={this.state.sourceImg}
        />

        {/* Search bar */}
        <Container>
          <div
            id="search-bar"
            className="search-bar-absolute input-group mx-3 d-none"
          >
            <Input
              className="right-padding-input circle-border-search-bar form-control-sm"
              type="text"
              name="search"
              id="search"
              value={this.state.search}
              onChange={this.handleChange}
              onKeyDown={(e) => this.checkEnter(e)}
            />
            <span className="position-absolute right-margin pr-1 pt-half1 bg-transparent border-0 border">
              <FaSearch />
            </span>
          </div>
        </Container>

        {/*Google login mobile*/}
        <div className="d-flex justify-content-center d-block d-lg-none">
          <Card body className="border-0 circle-border-small-login-mobile">
            <div className="row text-center">
              <div className="col-4">
                <GoogleLogin
                  className="circle-border-google"
                  clientId={this.state.clientId}
                  buttonText=" "
                  onSuccess={this.onSuccess}
                  onFailure={this.onFailure}
                  cookiePolicy={"single_host_origin"}
                  isSignin={true}
                />
              </div>
              <div className="col-8 text-login-mobile pt-3">
                <a
                  className="link-default"
                  href={"/login" + this.state.urlctrip}
                >
                  <p className="fw-bold">Sign in</p>
                </a>
                <a className="link-default" href="/register">
                  <p className="fw-bold">Create account</p>
                </a>
                <a href={"/login" + this.state.urlctrip}>
                  <p className="fw-bold">Log in</p>
                </a>
              </div>
            </div>
          </Card>
        </div>

        {/*Desktop show*/}
        <Container className="row">
          <div className="col-12 col-lg-4 left-section d-none d-lg-flex">
            <Container className="py-5">
              <img src={ThinkTravel} width="100%" alt="Fridayis Think Travel" />
              <br />
              <br />
              <br />
              <div className="mx-3">
                <a
                  className="link-default"
                  href={"/login" + this.state.urlctrip}
                >
                  <p className="fw-bold">Sign in</p>
                </a>
                <a className="link-default" href="/register">
                  <p className="fw-bold">Create account</p>
                </a>
                <p className="text-gray">or Login With</p>
                <GoogleLogin
                  className="form-control my-2 button-google-login btn-sm"
                  clientId={this.state.clientId}
                  buttonText="Continue with google"
                  onSuccess={this.onSuccess}
                  onFailure={this.onFailure}
                  cookiePolicy={"single_host_origin"}
                  isSignin={true}
                />
                <p className="text-center mt-2">
                  Already have an account? <a href="/login">Log in</a>
                </p>
                <p className="text-center mt-2">Fridayis 2023 ©</p>
              </div>
            </Container>
          </div>
          <div className="col-12 col-lg-8 right-section">
            {/*<div id="top"></div>*/}
            {/*<a id="back-to-top" href="#top" className="btn btn-light btn-sm back-to-top" role="button"><MdKeyboardArrowUp /></a>*/}
            {/*<Container className="pt-5">*/}
            {/*    <h1 className="text-96">*/}
            {/*        Make the first move!*/}
            {/*    </h1>*/}
            {/*    <br className="d-none d-md-flex"/>*/}

            {/*    <div className="row my-5 d-block d-lg-none">*/}
            {/*        <div className="col-md-5 mx-auto">*/}
            {/*            <div className="input-group">*/}
            {/*                <Input className="right-padding-input circle-border form-control-lg border" type="text" name="search" id="search" value={this.state.search} onChange={this.handleChange} onKeyDown={(e)=>this.checkEnter(e)}/>*/}
            {/*                <span className="position-absolute right-margin pt-2 pr-1 bg-transparent border-0 border">*/}
            {/*              <FiSearch />*/}
            {/*            </span>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <h4>Best Deal</h4>*/}
            {/*</Container>*/}

            <Container id="makeTheFirstMove" className="row pt-lg-5 mt-4">
              <div className="col-12 col-md-10 offset-md-1 my-1">
                <h1 className="text-96-index">
                  Make the first <br />
                  move!
                </h1>
              </div>

              {/*<br className="d-none d-md-flex"/>*/}
              {/*<h4>Best Deal</h4>*/}
            </Container>

            <Container className="d-block d-lg-none">
              <div className="row my-3">
                <div className="col-md-5 mx-auto">
                  <div className="input-group">
                    <Input
                      className="right-padding-input circle-border form-control-lg border"
                      type="text"
                      name="search"
                      id="search"
                      value={this.state.search}
                      onChange={this.handleChange}
                      onKeyDown={(e) => this.checkEnter(e)}
                    />
                    <span className="position-absolute right-margin pt-2 pr-1 bg-transparent border-0 border">
                      <FiSearch />
                    </span>
                  </div>
                </div>
              </div>
            </Container>

            <br className="d-none d-md-flex" />
            <Container className="right-content">
              {this.state.data.length === 0 && (
                <div className="row mb-5">
                  <div className="col-12 col-md-6 offset-md-3">
                    <img src={NoContent} width="100%" />
                  </div>
                </div>
              )}
              {this.state.data?.map((row, rowIndex) => (
                <div>
                  {rowIndex > 0 ? (
                    <div>
                      {row.TripID !== this.state.data[rowIndex - 1].TripID && (
                        <div>
                          <div className="row pt-2">
                            <span
                              className="anchor"
                              id={"content-timeline" + row.TripID}
                            ></span>
                            <div className="col-12 col-md-10 offset-md-1 my-1">
                              <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                  {row.ProfileURL ? (
                                    <NavbarBrand role="button">
                                      <img
                                        src={row.ProfileURL}
                                        className="timeline-profile-img"
                                        alt="Profile"
                                      />
                                    </NavbarBrand>
                                  ) : (
                                    <NavbarBrand role="button">
                                      <img
                                        src={ProfileImg}
                                        className="timeline-profile-img"
                                        alt="Profile"
                                      />
                                    </NavbarBrand>
                                  )}

                                  <p className="timeline-profile-text">
                                    {row.Username}
                                    {row.TripDestination &&
                                      " ; " + row.TripDestination}
                                  </p>
                                </div>
                                <p className="timeline-profile-date">
                                  {row.DateUpdate &&
                                    new Date(row.DateUpdate).toLocaleDateString(
                                      "en-GB",
                                      {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                      }
                                    )}
                                </p>
                              </div>
                            </div>
                            <div className="col-12 col-md-10 offset-md-1 pmx-sm-md-0 py-0 my-0">
                              {this.state.dataImagesList?.map(
                                (rowImg) =>
                                  row.TripID.toString() ===
                                    rowImg.id.toString() &&
                                  (rowImg.url ? (
                                    rowImg.url.length === 1 ? (
                                      <LazyLoadImage
                                        effect="blur"
                                        fetchpriority="high"
                                        src={rowImg.url[0]}
                                        className="travel-blog-list-400"
                                        width="100%"
                                        alt="Fridayis"
                                        onClick={this.openLightbox.bind(
                                          this,
                                          rowImg.src
                                        )}
                                      />
                                    ) : rowImg.url.length === 2 ? (
                                      <div className="row px-0 mx-0">
                                        <div className="col-6 padding-right-img-1">
                                          <LazyLoadImage
                                            fetchpriority="high"
                                            effect="blur"
                                            src={rowImg.url[0]}
                                            className="travel-blog-list-400"
                                            width="100%"
                                            alt="Fridayis"
                                            onClick={this.openLightbox.bind(
                                              this,
                                              rowImg.src
                                            )}
                                          />
                                        </div>
                                        <div className="col-6 padding-left-img-2">
                                          <LazyLoadImage
                                            fetchpriority="high"
                                            effect="blur"
                                            src={rowImg.url[1]}
                                            className="travel-blog-list-400"
                                            width="100%"
                                            alt="Fridayis"
                                            onClick={this.openLightbox.bind(
                                              this,
                                              rowImg.src
                                            )}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="row px-0 mx-0">
                                        {rowImg.url?.map(
                                          (rowImage, rowIndexImage) =>
                                            rowIndexImage === 0 ? (
                                              <div className="col-8 padding-right-2px">
                                                <LazyLoadImage
                                                  effect="blur"
                                                  fetchpriority="high"
                                                  src={rowImage}
                                                  className="travel-blog-list-400"
                                                  width="100%"
                                                  alt="Fridayis"
                                                  onClick={this.openLightbox.bind(
                                                    this,
                                                    rowImg.src
                                                  )}
                                                />
                                              </div>
                                            ) : rowIndexImage === 1 ? (
                                              <div className="col-4 padding-left-2px">
                                                <LazyLoadImage
                                                  effect="blur"
                                                  fetchpriority="high"
                                                  src={rowImage}
                                                  className="travel-blog-list-200 "
                                                  width="100%"
                                                  alt="Fridayis"
                                                  onClick={this.openLightbox.bind(
                                                    this,
                                                    rowImg.src
                                                  )}
                                                />
                                                <LazyLoadImage
                                                  effect="blur"
                                                  fetchpriority="high"
                                                  src={rowImg.url[2]}
                                                  className="travel-blog-list-200 pt-1"
                                                  width="100%"
                                                  alt="Fridayis"
                                                  onClick={this.openLightbox.bind(
                                                    this,
                                                    rowImg.src
                                                  )}
                                                />
                                              </div>
                                            ) : rowIndexImage === 2 ? (
                                              <div>
                                                <div className="overlay-travel-blog-list-200-index">
                                                  {rowImg.url.length - 3 > 0 &&
                                                    rowImg.url.length - 3 + "+"}
                                                </div>{" "}
                                                <img
                                                  fetchpriority="low"
                                                  src={rowImage}
                                                  className="d-none"
                                                  width="100%"
                                                  alt="Fridayis"
                                                  onClick={this.openLightbox.bind(
                                                    this,
                                                    rowImg.src
                                                  )}
                                                />
                                              </div>
                                            ) : (
                                              rowIndexImage > 2 && (
                                                <img
                                                  fetchpriority="low"
                                                  src={rowImage}
                                                  className="d-none"
                                                  width="100%"
                                                  alt="Fridayis"
                                                  onClick={this.openLightbox.bind(
                                                    this,
                                                    rowImg.src
                                                  )}
                                                />
                                              )
                                            )
                                        )}
                                      </div>
                                    )
                                  ) : (
                                    <LazyLoadImage
                                      effect="blur"
                                      fetchpriority="high"
                                      src={Fridayisimg}
                                      className="travel-blog-list-400"
                                      width="100%"
                                      alt="Fridayis"
                                      onClick={this.openLightbox.bind(this, [
                                        {
                                          src:
                                            window.location.protocol +
                                            "//" +
                                            window.location.host +
                                            "/fridayis-img.jpg",
                                        },
                                      ])}
                                    />
                                  ))
                              )}
                            </div>

                            <div className="col-12 col-md-10 offset-md-1 pt-2 padding-right-1">
                              <div className="row">
                                <div className="col-9">
                                  {/*<a href="/timeline" className="link-default"><h2>8 Day in Sydney</h2></a>*/}
                                  <p className="fw-bold pt-2">{row.TripName}</p>
                                </div>
                                <div className="col-3 text-right">
                                  {/*<h4><AiOutlineHeart/> 120k</h4>*/}
                                </div>
                              </div>
                              {/*<h3 className="red-highlight">Now 899$ / Person</h3>*/}
                              <p
                                className="trip-description"
                                id={"tripDescription" + row.TripID}
                                onClick={this.toggleTripdescriptionHide.bind(
                                  this,
                                  row.TripID
                                )}
                              >
                                {row.TripDescription}
                              </p>
                              <p
                                className="trip-description-hide d-none"
                                id={"tripDescriptionHide" + row.TripID}
                                onClick={this.toggleTripdescriptionHide.bind(
                                  this,
                                  row.TripID
                                )}
                              >
                                {row.TripDescription}
                              </p>
                              {/*<p>{row.TripID}</p>*/}
                            </div>
                          </div>
                          {row.DayNumber ? (
                            <div>
                              {/* Arrow */}
                              <div className="text-center my-3">
                                {/*{(this.state.dayListAll) && <IoIosArrowDown style={{"cursor": "pointer"}} onClick={this.toggleTimeline.bind(this, row.TripID)}/>}*/}
                                {/* <IoIosArrowDown id={"arrow-down"+row.TripID} style={{"cursor": "pointer"}} onClick={this.toggleTimeline.bind(this, row.TripID)}/> */}
                                <IoIosArrowUp
                                  id={"arrow-up" + row.TripID}
                                  className="d-none"
                                  style={{ cursor: "pointer" }}
                                  onClick={this.toggleTimeline.bind(
                                    this,
                                    row.TripID
                                  )}
                                />
                              </div>
                              {/* Timeeline */}
                              <div className="mx-3">
                                {this.state.dayListAll?.map((rowList) => (
                                  <div>
                                    {row.TripID.toString() ===
                                      rowList["id"].toString() && (
                                      <div style={{ overflowX: "auto" }}>
                                        <TabList>
                                          {rowList["data"]?.map(
                                            (rowDay, rowDayIndex) => (
                                              <Tab>
                                                <a
                                                  className="day-link"
                                                  href={
                                                    "#" +
                                                    rowList["id"].toString() +
                                                    "day" +
                                                    rowDayIndex
                                                  }
                                                >
                                                  Day {rowDayIndex + 1}{" "}
                                                </a>
                                                {this.state.dataCloneList?.map(
                                                  (rowStartDate) =>
                                                    row.TripID.toString() ===
                                                      rowStartDate.id.toString() &&
                                                    (rowStartDate.startDateDate[
                                                      rowDayIndex
                                                    ] ? (
                                                      <p className="date-link ml-minushalf1">
                                                        {rowStartDate
                                                          .startDateDate[
                                                          rowDayIndex
                                                        ].date +
                                                          " " +
                                                          months[
                                                            rowStartDate
                                                              .startDateDate[
                                                              rowDayIndex
                                                            ].month
                                                          ]}
                                                      </p>
                                                    ) : (
                                                      <p className="date-link ml-minushalf1">
                                                        {" "}
                                                        {this.getNextFridayFormatted(
                                                          rowDayIndex,
                                                          1
                                                        )}{" "}
                                                      </p>
                                                    ))
                                                )}
                                              </Tab>
                                            )
                                          )}
                                        </TabList>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                              {/* Arrow */}
                              <div className="text-center my-3">
                                {/*{(this.state.dayListAll) && <IoIosArrowDown style={{"cursor": "pointer"}} onClick={this.toggleTimeline.bind(this, row.TripID)}/>}*/}
                                <IoIosArrowDown
                                  id={"arrow-down" + row.TripID}
                                  style={{ cursor: "pointer" }}
                                  onClick={this.toggleTimeline.bind(
                                    this,
                                    row.TripID
                                  )}
                                />
                                {/* <IoIosArrowUp id={"arrow-up"+row.TripID} className="d-none" style={{"cursor": "pointer"}} onClick={this.toggleTimeline.bind(this, row.TripID)}/> */}
                              </div>
                              <div
                                id={"timeline" + row.TripID}
                                className="d-none"
                              >
                                {this.state.dayListAll?.map(
                                  (rowList) =>
                                    row.TripID.toString() ===
                                      rowList["id"].toString() &&
                                    rowList["data"]?.map(
                                      (rowDay, rowDayIndex) => (
                                        <div className="row">
                                          {rowDayIndex ===
                                          rowList["data"].length - 1 ? (
                                            <div className="line-col col-2">
                                              <div className="line-timeline-last-child">
                                                {" "}
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="line-col col-2">
                                              <div className="line-timeline">
                                                {" "}
                                              </div>
                                            </div>
                                          )}

                                          <div className="col-10">
                                            <Row className="day-timeline my-5">
                                              <Col xs="12" sm="12">
                                                <span
                                                  className="anchor"
                                                  id={
                                                    rowList["id"].toString() +
                                                    "day" +
                                                    rowDayIndex
                                                  }
                                                ></span>
                                                <FormGroup className="mb-4">
                                                  <p className="fw-bold mb-0">
                                                    Day {rowDayIndex + 1}
                                                  </p>
                                                  {this.state.dataCloneList?.map(
                                                    (rowStartDate) =>
                                                      row.TripID.toString() ===
                                                        rowStartDate.id.toString() &&
                                                      (rowStartDate
                                                        .startDateDate[
                                                        rowDayIndex
                                                      ] ? (
                                                        <p className="date-link">
                                                          {rowStartDate
                                                            .startDateDate[
                                                            rowDayIndex
                                                          ].date +
                                                            " " +
                                                            months[
                                                              rowStartDate
                                                                .startDateDate[
                                                                rowDayIndex
                                                              ].month
                                                            ] +
                                                            " " +
                                                            rowStartDate
                                                              .startDateDate[
                                                              rowDayIndex
                                                            ].year}
                                                        </p>
                                                      ) : (
                                                        <p className="date-link">
                                                          {" "}
                                                          {this.getNextFridayFormatted(
                                                            rowDayIndex,
                                                            2
                                                          )}{" "}
                                                        </p>
                                                      ))
                                                  )}
                                                  {/* {this.state.dataCloneList?.map((rowStartDate) => (
                                                                                                                                (row.TripID.toString() === rowStartDate.id.toString()) &&
                                                                                                                                (rowStartDate.startDateDate[rowDayIndex]) &&
                                                                                                                                <p className="date-link">{rowStartDate.startDateDate[rowDayIndex].date+" "+months[rowStartDate.startDateDate[rowDayIndex].month]}</p>
                                                                                                                            ))} */}
                                                </FormGroup>
                                                {rowDay[9] && (
                                                  <p>{rowDay[9]}</p>
                                                )}
                                                {rowDay[7].length > 0 && (
                                                  <div className="show-day-img overflow-auto d-flex mb-3">
                                                    {rowDay[7]?.map(
                                                      (
                                                        fileImageDay,
                                                        indexImageDay
                                                      ) => (
                                                        <LazyLoadImage
                                                          effect="blur"
                                                          fetchpriority="low"
                                                          src={fileImageDay}
                                                          className="show-img-preview"
                                                          alt={
                                                            "Day " +
                                                            (rowDayIndex + 1) +
                                                            " image " +
                                                            (indexImageDay + 1)
                                                          }
                                                          onClick={this.openLightbox.bind(
                                                            this,
                                                            rowDay[8]
                                                          )}
                                                        />
                                                      )
                                                    )}
                                                  </div>
                                                )}
                                                <div className="overflow-auto">
                                                  {ReactHtmlParser(rowDay[1])}
                                                </div>

                                                <div className="row mb-3">
                                                  {rowDay[2] && (
                                                    <div
                                                      id={
                                                        "flightIcon" +
                                                        row.TripID +
                                                        "" +
                                                        (rowDayIndex + 1) +
                                                        "1"
                                                      }
                                                      className="col-3 mb-3"
                                                    >
                                                      <div className="input-group">
                                                        <div className="input-group-prepend">
                                                          <img
                                                            src={
                                                              window.location
                                                                .protocol +
                                                              "//" +
                                                              window.location
                                                                .host +
                                                              "/icon/airplane.png"
                                                            }
                                                            className="icon-input-before"
                                                            name="linkFlightButton"
                                                            id="linkFlightButton"
                                                            onClick={this.clickLinkFlight.bind(
                                                              this,
                                                              row.TripID +
                                                                "" +
                                                                (rowDayIndex +
                                                                  1) +
                                                                "1"
                                                            )}
                                                            alt="Airplane"
                                                          />
                                                        </div>
                                                        <Input
                                                          type="text"
                                                          name="linkFlight"
                                                          id="linkFlight"
                                                          className="form-control input-link-before"
                                                          value={
                                                            row[10]
                                                              ? row[10]
                                                              : "Not specified"
                                                          }
                                                          aria-label="linkFlight"
                                                          aria-describedby="linkFlightButton"
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                  {rowDay[2] && (
                                                    <div
                                                      id={
                                                        "flightInputBox" +
                                                        row.TripID +
                                                        "" +
                                                        (rowDayIndex + 1) +
                                                        "1"
                                                      }
                                                      className="col-12 mb-3 d-none"
                                                    >
                                                      <div className="row">
                                                        <div className="col-8">
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <img
                                                                src={
                                                                  window
                                                                    .location
                                                                    .protocol +
                                                                  "//" +
                                                                  window
                                                                    .location
                                                                    .host +
                                                                  "/icon/airplane.png"
                                                                }
                                                                className="icon-input-update"
                                                                name="linkFlightButton"
                                                                id="linkFlightButton"
                                                                onClick={this.clickLinkFlight.bind(
                                                                  this,
                                                                  row.TripID +
                                                                    "" +
                                                                    (rowDayIndex +
                                                                      1) +
                                                                    "1"
                                                                )}
                                                                alt="Motor"
                                                              />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkFlight"
                                                              id="linkFlight"
                                                              className="form-control input-link-update"
                                                              value={rowDay[2]}
                                                              aria-label="linkFlight"
                                                              aria-describedby="linkFlightButton"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="col-4">
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <FaMoneyBillWaveAlt className="icon-input-update-currency" />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkFlightPrice"
                                                              id="linkFlightPrice"
                                                              className="form-control input-link-update"
                                                              value={
                                                                row[10]
                                                                  ? row[10]
                                                                  : "Not specified"
                                                              }
                                                              aria-label="linkFlightPrice"
                                                              aria-describedby="linkFlightPriceButton"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                  {rowDay[3] && (
                                                    <div
                                                      id={
                                                        "hotelIcon" +
                                                        row.TripID +
                                                        "" +
                                                        (rowDayIndex + 1) +
                                                        "2"
                                                      }
                                                      className="col-3 mb-3"
                                                    >
                                                      <div className="input-group">
                                                        <div className="input-group-prepend">
                                                          <img
                                                            src={
                                                              window.location
                                                                .protocol +
                                                              "//" +
                                                              window.location
                                                                .host +
                                                              "/icon/double-bed.png"
                                                            }
                                                            className="icon-input-before"
                                                            name="linkHotelButton"
                                                            id="linkHotelButton"
                                                            onClick={this.clickLinkHotel.bind(
                                                              this,
                                                              row.TripID +
                                                                "" +
                                                                (rowDayIndex +
                                                                  1) +
                                                                "2"
                                                            )}
                                                            alt="Hotel"
                                                          />
                                                        </div>
                                                        <Input
                                                          type="text"
                                                          name="linkHotel"
                                                          id="linkHotel"
                                                          className="form-control input-link-before"
                                                          value={
                                                            row[11]
                                                              ? row[11]
                                                              : "Not specified"
                                                          }
                                                          aria-label="linkHotel"
                                                          aria-describedby="linkHotelButton"
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                  {rowDay[3] && (
                                                    <div
                                                      id={
                                                        "hotelInputBox" +
                                                        row.TripID +
                                                        "" +
                                                        (rowDayIndex + 1) +
                                                        "2"
                                                      }
                                                      className="col-12 mb-3 d-none"
                                                    >
                                                      <div className="row">
                                                        <div className="col-8">
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <img
                                                                src={
                                                                  window
                                                                    .location
                                                                    .protocol +
                                                                  "//" +
                                                                  window
                                                                    .location
                                                                    .host +
                                                                  "/icon/double-bed.png"
                                                                }
                                                                className="icon-input-update"
                                                                name="linkHotelButton"
                                                                id="linkHotelButton"
                                                                onClick={this.clickLinkHotel.bind(
                                                                  this,
                                                                  row.TripID +
                                                                    "" +
                                                                    (rowDayIndex +
                                                                      1) +
                                                                    "2"
                                                                )}
                                                                alt="Motor"
                                                              />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkHotel"
                                                              id="linkHotel"
                                                              className="form-control input-link-update"
                                                              value={rowDay[3]}
                                                              aria-label="linkHotel"
                                                              aria-describedby="linkHotelButton"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="col-4">
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <FaMoneyBillWaveAlt className="icon-input-update-currency" />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkHotelPrice"
                                                              id="linkHotelPrice"
                                                              className="form-control input-link-update"
                                                              value={
                                                                row[11]
                                                                  ? row[11]
                                                                  : "Not specified"
                                                              }
                                                              aria-label="linkHotelPrice"
                                                              aria-describedby="linkHotelPriceButton"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                  {rowDay[4] && (
                                                    <div
                                                      id={
                                                        "carIcon" +
                                                        row.TripID +
                                                        "" +
                                                        (rowDayIndex + 1) +
                                                        "3"
                                                      }
                                                      className="col-3 mb-3"
                                                    >
                                                      <div className="input-group">
                                                        <div className="input-group-prepend">
                                                          <img
                                                            src={
                                                              window.location
                                                                .protocol +
                                                              "//" +
                                                              window.location
                                                                .host +
                                                              "/icon/sedan.png"
                                                            }
                                                            className="icon-input-before"
                                                            name="linkCarButton"
                                                            id="linkCarButton"
                                                            onClick={this.clickLinkCar.bind(
                                                              this,
                                                              row.TripID +
                                                                "" +
                                                                (rowDayIndex +
                                                                  1) +
                                                                "3"
                                                            )}
                                                            alt="Car"
                                                          />
                                                        </div>
                                                        <Input
                                                          type="text"
                                                          name="linkCar"
                                                          id="linkCar"
                                                          className="form-control input-link-before"
                                                          value={
                                                            row[12]
                                                              ? row[12]
                                                              : "Not specified"
                                                          }
                                                          aria-label="linkCar"
                                                          aria-describedby="linkCarButton"
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                  {rowDay[4] && (
                                                    <div
                                                      id={
                                                        "carInputBox" +
                                                        row.TripID +
                                                        "" +
                                                        (rowDayIndex + 1) +
                                                        "3"
                                                      }
                                                      className="col-12 mb-3 d-none"
                                                    >
                                                      <div className="row">
                                                        <div className="col-8">
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <img
                                                                src={
                                                                  window
                                                                    .location
                                                                    .protocol +
                                                                  "//" +
                                                                  window
                                                                    .location
                                                                    .host +
                                                                  "/icon/sedan.png"
                                                                }
                                                                className="icon-input-update"
                                                                name="linkCarButton"
                                                                id="linkCarButton"
                                                                onClick={this.clickLinkCar.bind(
                                                                  this,
                                                                  row.TripID +
                                                                    "" +
                                                                    (rowDayIndex +
                                                                      1) +
                                                                    "3"
                                                                )}
                                                                alt="Motor"
                                                              />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkCar"
                                                              id="linkCar"
                                                              className="form-control input-link-update"
                                                              value={rowDay[4]}
                                                              aria-label="linkCar"
                                                              aria-describedby="linkCarButton"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="col-4">
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <FaMoneyBillWaveAlt className="icon-input-update-currency" />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkCarPrice"
                                                              id="linkCarPrice"
                                                              className="form-control input-link-update"
                                                              value={
                                                                row[12]
                                                                  ? row[12]
                                                                  : "Not specified"
                                                              }
                                                              aria-label="linkCarPrice"
                                                              aria-describedby="linkCarPriceButton"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                  {rowDay[6] && (
                                                    <div
                                                      id={
                                                        "motorIcon" +
                                                        row.TripID +
                                                        "" +
                                                        (rowDayIndex + 1) +
                                                        "5"
                                                      }
                                                      className="col-3 mb-3 "
                                                    >
                                                      <div className="input-group">
                                                        <div className="input-group-prepend">
                                                          <img
                                                            src={
                                                              window.location
                                                                .protocol +
                                                              "//" +
                                                              window.location
                                                                .host +
                                                              "/icon/motorbike.png"
                                                            }
                                                            className="icon-input-before"
                                                            name="linkMotorButton"
                                                            id="linkMotorButton"
                                                            onClick={this.clickLinkMotor.bind(
                                                              this,
                                                              row.TripID +
                                                                "" +
                                                                (rowDayIndex +
                                                                  1) +
                                                                "5"
                                                            )}
                                                            alt="Motor"
                                                          />
                                                        </div>
                                                        <Input
                                                          type="text"
                                                          name="linkMotor"
                                                          id="linkMotor"
                                                          className="form-control input-link-before"
                                                          value={
                                                            row[14]
                                                              ? row[14]
                                                              : "Not specified"
                                                          }
                                                          aria-label="linkMotor"
                                                          aria-describedby="linkMotorButton"
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                  {rowDay[6] && (
                                                    <div
                                                      id={
                                                        "motorInputBox" +
                                                        row.TripID +
                                                        "" +
                                                        (rowDayIndex + 1) +
                                                        "5"
                                                      }
                                                      className="col-12 mb-3 d-none"
                                                    >
                                                      <div className="row">
                                                        <div className="col-8">
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <img
                                                                src={
                                                                  window
                                                                    .location
                                                                    .protocol +
                                                                  "//" +
                                                                  window
                                                                    .location
                                                                    .host +
                                                                  "/icon/motorbike.png"
                                                                }
                                                                className="icon-input-update"
                                                                name="linkMotorButton"
                                                                id="linkMotorButton"
                                                                onClick={this.clickLinkMotor.bind(
                                                                  this,
                                                                  row.TripID +
                                                                    "" +
                                                                    (rowDayIndex +
                                                                      1) +
                                                                    "5"
                                                                )}
                                                                alt="Motor"
                                                              />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkMotor"
                                                              id="linkMotor"
                                                              className="form-control input-link-update"
                                                              value={rowDay[6]}
                                                              aria-label="linkMotor"
                                                              aria-describedby="linkMotorButton"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="col-4">
                                                          <div className="input-group">
                                                            <div className="input-group-prepend">
                                                              <FaMoneyBillWaveAlt className="icon-input-update-currency" />
                                                            </div>
                                                            <Input
                                                              type="text"
                                                              name="linkMotorPrice"
                                                              id="linkMotorPrice"
                                                              className="form-control input-link-update"
                                                              value={
                                                                row[14]
                                                                  ? row[14]
                                                                  : "Not specified"
                                                              }
                                                              aria-label="linkMotorPrice"
                                                              aria-describedby="linkMotorPriceButton"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </div>
                                      )
                                    )
                                )}
                                <div className="text-center my-3">
                                  <IoIosArrowUp
                                    style={{ cursor: "pointer" }}
                                    onClick={this.toggleTimelineUp.bind(
                                      this,
                                      row.TripID
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="my-5"></div>
                          )}
                          <hr className="line-content mx-auto" />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="row pt-2">
                        <span
                          className="anchor"
                          id={"content-timeline" + row.TripID}
                        ></span>
                        <div className="col-12 col-md-10 offset-md-1 my-1">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              {row.ProfileURL ? (
                                <NavbarBrand role="button">
                                  <img
                                    src={row.ProfileURL}
                                    className="timeline-profile-img"
                                    alt="Profile"
                                  />
                                </NavbarBrand>
                              ) : (
                                <NavbarBrand role="button">
                                  <img
                                    src={ProfileImg}
                                    className="timeline-profile-img"
                                    alt="Profile"
                                  />
                                </NavbarBrand>
                              )}

                              <p className="timeline-profile-text">
                                {row.Username}
                                {row.TripDestination &&
                                  " ; " + row.TripDestination}
                              </p>
                            </div>
                            <p className="timeline-profile-date">
                              {row.DateUpdate &&
                                new Date(row.DateUpdate).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "numeric",
                                    month: "long",
                                    year: "numeric",
                                  }
                                )}
                            </p>
                          </div>
                        </div>

                        <div className="col-12 col-md-10 offset-md-1 pmx-sm-md-0 py-0 my-0">
                          {this.state.dataImagesList?.map(
                            (rowImg) =>
                              row.TripID.toString() === rowImg.id.toString() &&
                              (rowImg.url ? (
                                rowImg.url.length === 1 ? (
                                  <LazyLoadImage
                                    effect="blur"
                                    fetchpriority="high"
                                    src={rowImg.url[0]}
                                    className="travel-blog-list-400"
                                    width="100%"
                                    alt="Fridayis"
                                    onClick={this.openLightbox.bind(
                                      this,
                                      rowImg.src
                                    )}
                                  />
                                ) : rowImg.url.length === 2 ? (
                                  <div className="row px-0 mx-0">
                                    <div className="col-6 padding-right-img-1">
                                      <LazyLoadImage
                                        fetchpriority="high"
                                        effect="blur"
                                        src={rowImg.url[0]}
                                        className="travel-blog-list-400"
                                        width="100%"
                                        alt="Fridayis"
                                        onClick={this.openLightbox.bind(
                                          this,
                                          rowImg.src
                                        )}
                                      />
                                    </div>
                                    <div className="col-6 padding-left-img-2">
                                      <LazyLoadImage
                                        fetchpriority="high"
                                        effect="blur"
                                        src={rowImg.url[1]}
                                        className="travel-blog-list-400"
                                        width="100%"
                                        alt="Fridayis"
                                        onClick={this.openLightbox.bind(
                                          this,
                                          rowImg.src
                                        )}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row px-0 mx-0">
                                    {rowImg.url?.map(
                                      (rowImage, rowIndexImage) =>
                                        rowIndexImage === 0 ? (
                                          <div className="col-8 padding-right-2px">
                                            <LazyLoadImage
                                              effect="blur"
                                              fetchpriority="high"
                                              src={rowImage}
                                              className="travel-blog-list-400"
                                              width="100%"
                                              alt="Fridayis"
                                              onClick={this.openLightbox.bind(
                                                this,
                                                rowImg.src
                                              )}
                                            />
                                          </div>
                                        ) : rowIndexImage === 1 ? (
                                          <div className="col-4 padding-left-2px">
                                            <LazyLoadImage
                                              effect="blur"
                                              fetchpriority="high"
                                              src={rowImage}
                                              className="travel-blog-list-200 "
                                              width="100%"
                                              alt="Fridayis"
                                              onClick={this.openLightbox.bind(
                                                this,
                                                rowImg.src
                                              )}
                                            />
                                            <LazyLoadImage
                                              effect="blur"
                                              fetchpriority="high"
                                              src={rowImg.url[2]}
                                              className="travel-blog-list-200 pt-1"
                                              width="100%"
                                              alt="Fridayis"
                                              onClick={this.openLightbox.bind(
                                                this,
                                                rowImg.src
                                              )}
                                            />
                                          </div>
                                        ) : rowIndexImage === 2 ? (
                                          <div>
                                            <div className="overlay-travel-blog-list-200-index">
                                              {rowImg.url.length - 3 > 0 &&
                                                rowImg.url.length - 3 + "+"}
                                            </div>{" "}
                                            <img
                                              fetchpriority="low"
                                              src={rowImage}
                                              className="d-none"
                                              width="100%"
                                              alt="Fridayis"
                                              onClick={this.openLightbox.bind(
                                                this,
                                                rowImg.src
                                              )}
                                            />
                                          </div>
                                        ) : (
                                          rowIndexImage > 2 && (
                                            <img
                                              fetchpriority="low"
                                              src={rowImage}
                                              className="d-none"
                                              width="100%"
                                              alt="Fridayis"
                                              onClick={this.openLightbox.bind(
                                                this,
                                                rowImg.src
                                              )}
                                            />
                                          )
                                        )
                                    )}
                                  </div>
                                )
                              ) : (
                                <LazyLoadImage
                                  effect="blur"
                                  fetchpriority="high"
                                  src={Fridayisimg}
                                  className="travel-blog-list-400"
                                  width="100%"
                                  alt="Fridayis"
                                  onClick={this.openLightbox.bind(this, [
                                    {
                                      src:
                                        window.location.protocol +
                                        "//" +
                                        window.location.host +
                                        "/fridayis-img.jpg",
                                    },
                                  ])}
                                />
                              ))
                          )}
                        </div>

                        <div className="col-12 col-md-10 offset-md-1 pt-2 padding-right-1">
                          <div className="row">
                            <div className="col-9">
                              {/*<a href="/timeline" className="link-default"><h2>{}</h2></a>*/}
                              <p className="fw-bold pt-2">{row.TripName}</p>
                            </div>
                            <div className="col-3 text-right">
                              {/*<h4><AiOutlineHeart/> 120k</h4>*/}
                            </div>
                          </div>
                          {/*<h3 className="red-highlight">Now 899$ / Person</h3>*/}
                          <p
                            className="trip-description"
                            id={"tripDescription" + row.TripID}
                            onClick={this.toggleTripdescriptionHide.bind(
                              this,
                              row.TripID
                            )}
                          >
                            {row.TripDescription}
                          </p>
                          <p
                            className="trip-description-hide d-none"
                            id={"tripDescriptionHide" + row.TripID}
                            onClick={this.toggleTripdescriptionHide.bind(
                              this,
                              row.TripID
                            )}
                          >
                            {row.TripDescription}
                          </p>
                          {/*<p>{row.TripID}</p>*/}
                        </div>
                      </div>
                      {row.DayNumber ? (
                        <div>
                          {/* Arrow */}
                          <div className="text-center my-3">
                            {/*{(this.state.dayListAll) && <IoIosArrowDown style={{"cursor": "pointer"}} onClick={this.toggleTimeline.bind(this, row.TripID)}/>}*/}
                            {/* <IoIosArrowDown id={"arrow-down"+row.TripID} style={{"cursor": "pointer"}} onClick={this.toggleTimeline.bind(this, row.TripID)}/> */}
                            <IoIosArrowUp
                              id={"arrow-up" + row.TripID}
                              className="d-none"
                              style={{ cursor: "pointer" }}
                              onClick={this.toggleTimeline.bind(
                                this,
                                row.TripID
                              )}
                            />
                          </div>
                          {/* Timeeline */}
                          <div className="mx-3">
                            {this.state.dayListAll?.map((rowList) => (
                              <div>
                                {row.TripID.toString() ===
                                  rowList["id"].toString() && (
                                  <div style={{ overflowX: "auto" }}>
                                    <TabList>
                                      {rowList["data"]?.map(
                                        (rowDay, rowDayIndex) => (
                                          <Tab>
                                            <a
                                              className="day-link"
                                              href={
                                                "#" +
                                                rowList["id"].toString() +
                                                "day" +
                                                rowDayIndex
                                              }
                                            >
                                              Day {rowDayIndex + 1}{" "}
                                            </a>
                                            {this.state.dataCloneList?.map(
                                              (rowStartDate) =>
                                                row.TripID.toString() ===
                                                  rowStartDate.id.toString() &&
                                                (rowStartDate.startDateDate[
                                                  rowDayIndex
                                                ] ? (
                                                  <p className="date-link ml-minushalf1">
                                                    {rowStartDate.startDateDate[
                                                      rowDayIndex
                                                    ].date +
                                                      " " +
                                                      months[
                                                        rowStartDate
                                                          .startDateDate[
                                                          rowDayIndex
                                                        ].month
                                                      ]}
                                                  </p>
                                                ) : (
                                                  <p className="date-link ml-minushalf1">
                                                    {" "}
                                                    {this.getNextFridayFormatted(
                                                      rowDayIndex,
                                                      1
                                                    )}{" "}
                                                  </p>
                                                ))
                                            )}
                                          </Tab>
                                        )
                                      )}
                                    </TabList>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                          {/* Arrow */}
                          <div className="text-center my-3">
                            {/*{(this.state.dayListAll) && <IoIosArrowDown style={{"cursor": "pointer"}} onClick={this.toggleTimeline.bind(this, row.TripID)}/>}*/}
                            <IoIosArrowDown
                              id={"arrow-down" + row.TripID}
                              style={{ cursor: "pointer" }}
                              onClick={this.toggleTimeline.bind(
                                this,
                                row.TripID
                              )}
                            />
                            {/* <IoIosArrowUp id={"arrow-up"+row.TripID} className="d-none" style={{"cursor": "pointer"}} onClick={this.toggleTimeline.bind(this, row.TripID)}/> */}
                          </div>
                          <div id={"timeline" + row.TripID} className="d-none">
                            {this.state.dayListAll?.map(
                              (rowList) =>
                                row.TripID.toString() ===
                                  rowList["id"].toString() &&
                                rowList["data"]?.map((rowDay, rowDayIndex) => (
                                  <div className="row">
                                    {rowDayIndex ===
                                    rowList["data"].length - 1 ? (
                                      <div className="line-col col-2">
                                        <div className="line-timeline-last-child">
                                          {" "}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="line-col col-2">
                                        <div className="line-timeline"> </div>
                                      </div>
                                    )}

                                    <div className="col-10">
                                      <Row className="day-timeline my-5">
                                        <Col xs="12" sm="12">
                                          <span
                                            className="anchor"
                                            id={
                                              rowList["id"].toString() +
                                              "day" +
                                              rowDayIndex
                                            }
                                          ></span>
                                          <FormGroup className="mb-4">
                                            <p className="fw-bold mb-0">
                                              Day {rowDayIndex + 1}
                                            </p>
                                            {this.state.dataCloneList?.map(
                                              (rowStartDate) =>
                                                row.TripID.toString() ===
                                                  rowStartDate.id.toString() &&
                                                (rowStartDate.startDateDate[
                                                  rowDayIndex
                                                ] ? (
                                                  <p className="date-link">
                                                    {rowStartDate.startDateDate[
                                                      rowDayIndex
                                                    ].date +
                                                      " " +
                                                      months[
                                                        rowStartDate
                                                          .startDateDate[
                                                          rowDayIndex
                                                        ].month
                                                      ] +
                                                      " " +
                                                      rowStartDate
                                                        .startDateDate[
                                                        rowDayIndex
                                                      ].year}
                                                  </p>
                                                ) : (
                                                  <p className="date-link">
                                                    {" "}
                                                    {this.getNextFridayFormatted(
                                                      rowDayIndex,
                                                      2
                                                    )}{" "}
                                                  </p>
                                                ))
                                            )}
                                          </FormGroup>
                                          {rowDay[9] && <p>{rowDay[9]}</p>}
                                          {rowDay[7].length > 0 && (
                                            <div className="show-day-img overflow-auto d-flex mb-3">
                                              {rowDay[7]?.map(
                                                (
                                                  fileImageDay,
                                                  indexImageDay
                                                ) => (
                                                  <LazyLoadImage
                                                    effect="blur"
                                                    fetchpriority="low"
                                                    src={fileImageDay}
                                                    className="show-img-preview"
                                                    alt={
                                                      "Day " +
                                                      (rowDayIndex + 1) +
                                                      " image " +
                                                      (indexImageDay + 1)
                                                    }
                                                    onClick={this.openLightbox.bind(
                                                      this,
                                                      rowDay[8]
                                                    )}
                                                  />
                                                )
                                              )}
                                            </div>
                                          )}
                                          <div className="overflow-auto">
                                            {ReactHtmlParser(rowDay[1])}
                                          </div>

                                          <div className="row mb-3">
                                            {rowDay[2] && (
                                              <div
                                                id={
                                                  "flightIcon" +
                                                  row.TripID +
                                                  "" +
                                                  (rowDayIndex + 1) +
                                                  "1"
                                                }
                                                className="col-3 mb-3"
                                              >
                                                <div className="input-group">
                                                  <div className="input-group-prepend">
                                                    <img
                                                      src={
                                                        window.location
                                                          .protocol +
                                                        "//" +
                                                        window.location.host +
                                                        "/icon/airplane.png"
                                                      }
                                                      className="icon-input-before"
                                                      name="linkFlightButton"
                                                      id="linkFlightButton"
                                                      onClick={this.clickLinkFlight.bind(
                                                        this,
                                                        row.TripID +
                                                          "" +
                                                          (rowDayIndex + 1) +
                                                          "1"
                                                      )}
                                                      alt="Airplane"
                                                    />
                                                  </div>
                                                  <Input
                                                    type="text"
                                                    name="linkFlight"
                                                    id="linkFlight"
                                                    className="form-control input-link-before"
                                                    value={
                                                      row[10]
                                                        ? row[10]
                                                        : "Not specified"
                                                    }
                                                    aria-label="linkFlight"
                                                    aria-describedby="linkFlightButton"
                                                  />
                                                </div>
                                              </div>
                                            )}
                                            {rowDay[2] && (
                                              <div
                                                id={
                                                  "flightInputBox" +
                                                  row.TripID +
                                                  "" +
                                                  (rowDayIndex + 1) +
                                                  "1"
                                                }
                                                className="col-12 mb-3 d-none"
                                              >
                                                <div className="row">
                                                  <div className="col-8">
                                                    <div className="input-group">
                                                      <div className="input-group-prepend">
                                                        <img
                                                          src={
                                                            window.location
                                                              .protocol +
                                                            "//" +
                                                            window.location
                                                              .host +
                                                            "/icon/airplane.png"
                                                          }
                                                          className="icon-input-update"
                                                          name="linkFlightButton"
                                                          id="linkFlightButton"
                                                          onClick={this.clickLinkFlight.bind(
                                                            this,
                                                            row.TripID +
                                                              "" +
                                                              (rowDayIndex +
                                                                1) +
                                                              "1"
                                                          )}
                                                          alt="Motor"
                                                        />
                                                      </div>
                                                      <Input
                                                        type="text"
                                                        name="linkFlight"
                                                        id="linkFlight"
                                                        className="form-control input-link-update"
                                                        value={rowDay[2]}
                                                        aria-label="linkFlight"
                                                        aria-describedby="linkFlightButton"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-4">
                                                    <div className="input-group">
                                                      <div className="input-group-prepend">
                                                        <FaMoneyBillWaveAlt className="icon-input-update-currency" />
                                                      </div>
                                                      <Input
                                                        type="text"
                                                        name="linkFlightPrice"
                                                        id="linkFlightPrice"
                                                        className="form-control input-link-update"
                                                        value={
                                                          row[10]
                                                            ? row[10]
                                                            : "Not specified"
                                                        }
                                                        aria-label="linkFlightPrice"
                                                        aria-describedby="linkFlightPriceButton"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                            {rowDay[3] && (
                                              <div
                                                id={
                                                  "hotelIcon" +
                                                  row.TripID +
                                                  "" +
                                                  (rowDayIndex + 1) +
                                                  "2"
                                                }
                                                className="col-3 mb-3"
                                              >
                                                <div className="input-group">
                                                  <div className="input-group-prepend">
                                                    <img
                                                      src={
                                                        window.location
                                                          .protocol +
                                                        "//" +
                                                        window.location.host +
                                                        "/icon/double-bed.png"
                                                      }
                                                      className="icon-input-before"
                                                      name="linkHotelButton"
                                                      id="linkHotelButton"
                                                      onClick={this.clickLinkHotel.bind(
                                                        this,
                                                        row.TripID +
                                                          "" +
                                                          (rowDayIndex + 1) +
                                                          "2"
                                                      )}
                                                      alt="Hotel"
                                                    />
                                                  </div>
                                                  <Input
                                                    type="text"
                                                    name="linkHotel"
                                                    id="linkHotel"
                                                    className="form-control input-link-before"
                                                    value={
                                                      row[11]
                                                        ? row[11]
                                                        : "Not specified"
                                                    }
                                                    aria-label="linkHotel"
                                                    aria-describedby="linkHotelButton"
                                                  />
                                                </div>
                                              </div>
                                            )}
                                            {rowDay[3] && (
                                              <div
                                                id={
                                                  "hotelInputBox" +
                                                  row.TripID +
                                                  "" +
                                                  (rowDayIndex + 1) +
                                                  "2"
                                                }
                                                className="col-12 mb-3 d-none"
                                              >
                                                <div className="row">
                                                  <div className="col-8">
                                                    <div className="input-group">
                                                      <div className="input-group-prepend">
                                                        <img
                                                          src={
                                                            window.location
                                                              .protocol +
                                                            "//" +
                                                            window.location
                                                              .host +
                                                            "/icon/double-bed.png"
                                                          }
                                                          className="icon-input-update"
                                                          name="linkHotelButton"
                                                          id="linkHotelButton"
                                                          onClick={this.clickLinkHotel.bind(
                                                            this,
                                                            row.TripID +
                                                              "" +
                                                              (rowDayIndex +
                                                                1) +
                                                              "2"
                                                          )}
                                                          alt="Motor"
                                                        />
                                                      </div>
                                                      <Input
                                                        type="text"
                                                        name="linkHotel"
                                                        id="linkHotel"
                                                        className="form-control input-link-update"
                                                        value={rowDay[3]}
                                                        aria-label="linkHotel"
                                                        aria-describedby="linkHotelButton"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-4">
                                                    <div className="input-group">
                                                      <div className="input-group-prepend">
                                                        <FaMoneyBillWaveAlt className="icon-input-update-currency" />
                                                      </div>
                                                      <Input
                                                        type="text"
                                                        name="linkHotelPrice"
                                                        id="linkHotelPrice"
                                                        className="form-control input-link-update"
                                                        value={
                                                          row[11]
                                                            ? row[11]
                                                            : "Not specified"
                                                        }
                                                        aria-label="linkHotelPrice"
                                                        aria-describedby="linkHotelPriceButton"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                            {rowDay[4] && (
                                              <div
                                                id={
                                                  "carIcon" +
                                                  row.TripID +
                                                  "" +
                                                  (rowDayIndex + 1) +
                                                  "3"
                                                }
                                                className="col-3 mb-3"
                                              >
                                                <div className="input-group">
                                                  <div className="input-group-prepend">
                                                    <img
                                                      src={
                                                        window.location
                                                          .protocol +
                                                        "//" +
                                                        window.location.host +
                                                        "/icon/sedan.png"
                                                      }
                                                      className="icon-input-before"
                                                      name="linkCarButton"
                                                      id="linkCarButton"
                                                      onClick={this.clickLinkCar.bind(
                                                        this,
                                                        row.TripID +
                                                          "" +
                                                          (rowDayIndex + 1) +
                                                          "3"
                                                      )}
                                                      alt="Car"
                                                    />
                                                  </div>
                                                  <Input
                                                    type="text"
                                                    name="linkCar"
                                                    id="linkCar"
                                                    className="form-control input-link-before"
                                                    value={
                                                      row[12]
                                                        ? row[12]
                                                        : "Not specified"
                                                    }
                                                    aria-label="linkCar"
                                                    aria-describedby="linkCarButton"
                                                  />
                                                </div>
                                              </div>
                                            )}
                                            {rowDay[4] && (
                                              <div
                                                id={
                                                  "carInputBox" +
                                                  row.TripID +
                                                  "" +
                                                  (rowDayIndex + 1) +
                                                  "3"
                                                }
                                                className="col-12 mb-3 d-none"
                                              >
                                                <div className="row">
                                                  <div className="col-8">
                                                    <div className="input-group">
                                                      <div className="input-group-prepend">
                                                        <img
                                                          src={
                                                            window.location
                                                              .protocol +
                                                            "//" +
                                                            window.location
                                                              .host +
                                                            "/icon/sedan.png"
                                                          }
                                                          className="icon-input-update"
                                                          name="linkCarButton"
                                                          id="linkCarButton"
                                                          onClick={this.clickLinkCar.bind(
                                                            this,
                                                            row.TripID +
                                                              "" +
                                                              (rowDayIndex +
                                                                1) +
                                                              "3"
                                                          )}
                                                          alt="Motor"
                                                        />
                                                      </div>
                                                      <Input
                                                        type="text"
                                                        name="linkCar"
                                                        id="linkCar"
                                                        className="form-control input-link-update"
                                                        value={rowDay[4]}
                                                        aria-label="linkCar"
                                                        aria-describedby="linkCarButton"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-4">
                                                    <div className="input-group">
                                                      <div className="input-group-prepend">
                                                        <FaMoneyBillWaveAlt className="icon-input-update-currency" />
                                                      </div>
                                                      <Input
                                                        type="text"
                                                        name="linkCarPrice"
                                                        id="linkCarPrice"
                                                        className="form-control input-link-update"
                                                        value={
                                                          row[12]
                                                            ? row[12]
                                                            : "Not specified"
                                                        }
                                                        aria-label="linkCarPrice"
                                                        aria-describedby="linkCarPriceButton"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                            {rowDay[6] && (
                                              <div
                                                id={
                                                  "motorIcon" +
                                                  row.TripID +
                                                  "" +
                                                  (rowDayIndex + 1) +
                                                  "5"
                                                }
                                                className="col-3 mb-3 "
                                              >
                                                <div className="input-group">
                                                  <div className="input-group-prepend">
                                                    <img
                                                      src={
                                                        window.location
                                                          .protocol +
                                                        "//" +
                                                        window.location.host +
                                                        "/icon/motorbike.png"
                                                      }
                                                      className="icon-input-before"
                                                      name="linkMotorButton"
                                                      id="linkMotorButton"
                                                      onClick={this.clickLinkMotor.bind(
                                                        this,
                                                        row.TripID +
                                                          "" +
                                                          (rowDayIndex + 1) +
                                                          "5"
                                                      )}
                                                      alt="Motor"
                                                    />
                                                  </div>
                                                  <Input
                                                    type="text"
                                                    name="linkMotor"
                                                    id="linkMotor"
                                                    className="form-control input-link-before"
                                                    value={
                                                      row[14]
                                                        ? row[14]
                                                        : "Not specified"
                                                    }
                                                    aria-label="linkMotor"
                                                    aria-describedby="linkMotorButton"
                                                  />
                                                </div>
                                              </div>
                                            )}
                                            {rowDay[6] && (
                                              <div
                                                id={
                                                  "motorInputBox" +
                                                  row.TripID +
                                                  "" +
                                                  (rowDayIndex + 1) +
                                                  "5"
                                                }
                                                className="col-12 mb-3 d-none"
                                              >
                                                <div className="row">
                                                  <div className="col-8">
                                                    <div className="input-group">
                                                      <div className="input-group-prepend">
                                                        <img
                                                          src={
                                                            window.location
                                                              .protocol +
                                                            "//" +
                                                            window.location
                                                              .host +
                                                            "/icon/motorbike.png"
                                                          }
                                                          className="icon-input-update"
                                                          name="linkMotorButton"
                                                          id="linkMotorButton"
                                                          onClick={this.clickLinkMotor.bind(
                                                            this,
                                                            row.TripID +
                                                              "" +
                                                              (rowDayIndex +
                                                                1) +
                                                              "5"
                                                          )}
                                                          alt="Motor"
                                                        />
                                                      </div>
                                                      <Input
                                                        type="text"
                                                        name="linkMotor"
                                                        id="linkMotor"
                                                        className="form-control input-link-update"
                                                        value={rowDay[6]}
                                                        aria-label="linkMotor"
                                                        aria-describedby="linkMotorButton"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-4">
                                                    <div className="input-group">
                                                      <div className="input-group-prepend">
                                                        <FaMoneyBillWaveAlt className="icon-input-update-currency" />
                                                      </div>
                                                      <Input
                                                        type="text"
                                                        name="linkMotorPrice"
                                                        id="linkMotorPrice"
                                                        className="form-control input-link-update"
                                                        value={
                                                          row[14]
                                                            ? row[14]
                                                            : "Not specified"
                                                        }
                                                        aria-label="linkMotorPrice"
                                                        aria-describedby="linkMotorPriceButton"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                ))
                            )}
                            <div className="text-center my-3">
                              <IoIosArrowUp
                                style={{ cursor: "pointer" }}
                                onClick={this.toggleTimelineUp.bind(
                                  this,
                                  row.TripID
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="my-5"></div>
                      )}
                      <hr className="line-content mx-auto" />
                    </div>
                  )}
                </div>
              ))}
              <br className="d-block d-md-none" />
              <br className="d-block d-md-none" />
              <br className="d-block d-md-none" />
              <br className="d-block d-md-none" />
            </Container>
          </div>
        </Container>
      </div>
      // </HelmetProvider>
    );
  }
}
export default Home;

import React from "react";
import {
  Container,
  Navbar,
  NavbarBrand,
  Input,
  Card,
  Row,
  Col,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Button,
  Label,
} from "reactstrap";

import { TbFilePlus } from "react-icons/tb";
import ProfileImg from "../img/profile.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Logo from "../img/logo_new.png";
import IconSearch from "../img/icon/icon-search.png";
import { IoNotificationsOutline, IoCalendarOutline } from "react-icons/io5";
import IconNoti from "../img/icon/Notification.png";
import IconPaper from "../img/icon/Paper.png";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.openSearch = this.openSearch.bind(this);
    this.openPostPage = this.openPostPage.bind(this);
    this.openTimeline = this.openTimeline.bind(this);
  }

  componentDidMount() {}

  openSearch() {
    var element2 = document.getElementById("search-bar");
    if (element2.classList.contains("d-none") === false) {
      element2.classList.add("d-none");
    } else {
      element2.classList.remove("d-none");
    }
  }

  openPostPage() {
    window.location.href = "/post?profileId=" + localStorage.getItem("userId");
  }

  openTimeline() {
    window.location.href = "/home";
  }

  render() {
    return (
      <div className="bg">
        <div className="d-none d-lg-flex d-md-flex">
          <Navbar
            id="navFridayis"
            className="container bg-transparent fixed-top"
          >
            <div className="w-50 ">
              <NavbarBrand role="button" href="/">
                <img src={Logo} className="logo-img" alt="Profile" />
                <p
                  className="d-none d-md-inline-flex fw-bold mx-3"
                  style={{ fontSize: "16px", paddingTop: "1rem" }}
                >
                  Fridayis
                </p>
              </NavbarBrand>
              <button className="btn circle-button">
                <img
                  src={IconNoti}
                  alt="Notification icon"
                  className="icon-circle-button"
                />
              </button>
              <button className="btn circle-button" onClick={this.openTimeline}>
                <img
                  src={IconPaper}
                  alt="paper icon"
                  className="icon-circle-button"
                />
              </button>
              {/* <IoNotificationsOutline className='circle-button' />
                                    <IoCalendarOutline className='circle-button' /> */}
              {/*<div className="d-none d-md-inline-flex fw-bold pt-point75">Fridayis</div>*/}
              {/* <p className="fw-bold title-nav pt-point75">{this.state.titleNav}</p> */}
            </div>
            <div className="d-flex mx-4 ">
              <button className="sarch-btn" onClick={this.openSearch}>
                <img src={IconSearch} className="nav-icon" alt="icon search" />
              </button>
              <div className="mt-1">
                {localStorage.getItem("imageUrl") ? (
                  <NavbarBrand role="button" onClick={this.openPostPage}>
                    <img
                      src={localStorage.getItem("imageUrl")}
                      className="profile-img"
                      alt="Profile"
                    />
                  </NavbarBrand>
                ) : (
                  <NavbarBrand role="button" onClick={this.openPostPage}>
                    <img
                      src={ProfileImg}
                      className="profile-img"
                      alt="Profile"
                    />
                  </NavbarBrand>
                )}
              </div>
            </div>

            {/* <div className='d-flex d-lg-none justify-content-end'>
                 
                                    <button className="sarch-btn" onClick={this.openSearch}><img src={IconSearch} className="nav-icon" alt="icon search"/></button>
                                    <button className='btn circle-button'><img src={IconNoti} alt="Notification icon" className='icon-circle-button' /></button>
                        </div> */}
          </Navbar>
        </div>

        <div className="d-flex d-lg-none d-md-none">
          <Navbar
            id="navFridayis"
            className="container bg-transparent fixed-top"
          >
            <div className="w-50 ">
              {/* <NavbarBrand role="button" href="/">
                                        <img src={Logo} className="logo-img" alt="Profile" />
                                        <p className="d-none d-md-inline-flex fw-bold mx-3" style={{"fontSize": "16px","paddingTop":"1rem"}}>Fridayis</p>
                                    </NavbarBrand>
                                    <button className='btn circle-button'><img src={IconNoti} alt="Notification icon" className='icon-circle-button' /></button>
                                    <button className='btn circle-button'><img src={IconPaper} alt="paper icon" className='icon-circle-button' /></button> */}
            </div>
            <div className="d-flex">
              <button className="sarch-btn" onClick={this.openSearch}>
                <img src={IconSearch} className="nav-icon" alt="icon search" />
              </button>
              <button className="btn circle-button">
                <img
                  src={IconNoti}
                  alt="Notification icon"
                  className="icon-circle-button"
                />
              </button>
            </div>
          </Navbar>
        </div>
      </div>
    );
  }
}
export default Home;
